import {
  Loading,
  Tag,
  Tooltip,
  mapRoutineNames,
  useOutsideMouseDown,
} from "@domin-frontend/domin-ui";
import { useRoutineData } from "queries/PASQueries";
import { PASListRoutineRespType } from "types/responseTypes";
import { SingleLineChart } from "./PASCharts/SinglePASChart";
import { PASRoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types";
import { useRef, useState } from "react";
import RoutineSpecs from "../PASRoutineItems/RoutineSpecs";
import PASRoutineInfoTable from "../PASRoutineItems/PASRoutineInfoTable";
import { Link, useParams } from "react-router-dom";
import { tagStatusMap } from "helpers/functions/shared/statusMap";

interface Props {
  routines?: PASListRoutineRespType;
  filteredRoutines: PASRoutineNamesType[];
}

function OverviewChartWrapper({
  id,
  type,
  isInfoOpen,
  setInfoOpen,
}: {
  id: string;
  type: PASRoutineNamesType;
  isInfoOpen: boolean;
  setInfoOpen: (value: string | undefined) => void;
}) {
  const { data: routine, isLoading } = useRoutineData(id, type);
  const ref = useRef<HTMLDivElement>(null);

  useOutsideMouseDown(ref, () => setInfoOpen(undefined));

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-96">
        <Loading className="items-center" />
      </div>
    );
  }
  if (!routine || !routine.data) {
    return null;
  }
  return (
    <div ref={ref} className="relative">
      <span
        className={`pl-4 text-xs cursor-pointer ${
          isInfoOpen ? "text-primary-600" : "text-neutral-400"
        } `}
        onMouseEnter={() => setInfoOpen(isInfoOpen ? undefined : id)}
        onMouseLeave={() => setInfoOpen(undefined)}
      >
        Specs
      </span>
      <SingleLineChart
        data={routine.data}
        xzData={routine?.xz_data}
        type={type}
        height={18}
        guides={routine.guides}
        valveVariant={routine.valve_type}
      />
      {isInfoOpen && (routine.specs || routine.info) && (
        <div className="absolute left-8 top-8 z-20 bg-neutral-0 shadow p-2">
          {routine.info && <PASRoutineInfoTable info={routine.info} />}
          {routine.specs && (
            <RoutineSpecs specs={routine.specs} routineType={type} />
          )}
        </div>
      )}
    </div>
  );
}

export default function PASOverview({ routines, filteredRoutines }: Props) {
  const [isInfoOpen, setInfoOpen] = useState<string | undefined>();
  const { serialNumber, test } = useParams();

  if (!routines) return <div>No routines</div>;
  //   create an array of the last existing routine for each routine type in the routines object
  const lastRoutines = filteredRoutines
    .map((r) => {
      const routineType = routines[r];
      if (routineType) {
        return routineType[routineType.length - 1];
      }
      return undefined;
    })
    .filter((r) => r);

  if (!lastRoutines) return <div>No routines</div>;

  return (
    <div className="w-full grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
      {lastRoutines.map((r) => {
        return (
          <div key={r?.routine_id} className="w-full">
            <div className="grid grid-cols-3 w-full  py-1 items-center justify-items-center">
              <div />
              <div className="flex flex-col whitespace-nowrap">
                <Tooltip content="Click to view routine details">
                  <Link
                    to={`/pas/${serialNumber}/${test}/${r?.routine_type}?view=detail`}
                  >
                    {r?.routine_type ? (
                      mapRoutineNames[r?.routine_type]
                    ) : (
                      <p>No Type</p>
                    )}
                  </Link>
                </Tooltip>
              </div>
              {r?.status && (
                <div className="justify-self-end">
                  <Tag status={tagStatusMap[r.status]} dominColours>
                    {r.status}
                  </Tag>
                </div>
              )}
            </div>
            {r?.routine_id && r.routine_type && (
              <OverviewChartWrapper
                id={r?.routine_id}
                type={r?.routine_type}
                isInfoOpen={isInfoOpen === r?.routine_id}
                setInfoOpen={setInfoOpen}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
