import { ReactNode } from "react";

export interface InfoTableCardProps {
  children: ReactNode;
}

export default function InfoTableCard({ children }: InfoTableCardProps) {
  return (
    <table className="min-w-max lg:w-full text-sm overflow-visible z-50">
      <tbody>{children}</tbody>
    </table>
  );
}
