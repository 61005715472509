import { Tag } from "@domin-frontend/domin-ui";
import { BasicStatusType } from "@domin-frontend/domin-ui/dist/esm/types";
import { TestStatusType, ValveStageType } from "../../../types/responseTypes";

export type StyleType = {
  [key in TestStatusType | ValveStageType]: BasicStatusType;
};

export const tagStatus: StyleType = {
  production: "idle",
  "assembly stock": "idle",
  assembly: "idle",
  "motor calibration": "pending",
  "accelerated life test": "pending",
  pas: "pending",
  concess: "danger",
  "final assembly": "success",
  "ready for shipping": "success",
  shipped: "success",
  investigation: "danger",
  "on loan": "idle",
  scrap: "danger",
  "customer returned": "danger",
  missing: "danger",
  complete: "success",
  incomplete: "pending",
  "laser marking": "pending",
  rework: "pending",
  quarantine: "pending",
};

function formatTagValue(value: string) {
  if (value === "pas") {
    return "PAS";
  } else {
    // Capitalize first letter
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}

export default function StatusCell({
  value,
}: {
  value: TestStatusType | ValveStageType;
}) {
  return (
    <Tag status={tagStatus[value]} dominColours>
      {formatTagValue(value)}
    </Tag>
  );
}
