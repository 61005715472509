import { RoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types";
import { useIsFetching, useQuery } from "@tanstack/react-query";
import { client } from "components/api";
import { queryClient } from "components/App";
import { FilterFieldsPASTypes } from "types/requestTypes";
import {
  CustomerReportDataRespType,
  PasRespType,
  UniqueFieldsRespType,
  PASValveInfoType,
  RoutineDataRespType,
} from "types/responseTypes";

async function getAllUniqueFieldValues(filter?: FilterFieldsPASTypes[]) {
  const response = await client.post<UniqueFieldsRespType[]>(
    "/pas/get-unique-field-values",
    { filter: filter }
  );
  return response.data;
}

async function getListPas(
  page: number,
  nOfItems: number = 10,
  search?: string,
  filter?: FilterFieldsPASTypes[]
) {
  const response = await client.post<PasRespType>("/pas/get-tests-pas", {
    page: page,
    page_size: nOfItems,
    search: search,
    filter: filter,
    sort_order: "desc",
    sort_by: "date",
  });
  return response.data;
}

async function getValveInfo(sn: string) {
  const response = await client.get<PASValveInfoType>("/pas/get-valve-info", {
    params: { serial_number: sn },
  });
  return response.data;
}

export async function getRoutineData(
  routineId: string,
  type: RoutineNamesType,
  customer: boolean = false
) {
  const response = await client.get<RoutineDataRespType>(
    "/pas/get-routine-data",
    {
      params: { routine_id: routineId, routine_type: type, customer: customer },
    }
  );

  return {
    ...response.data,
    guides: response.data.guides ?? undefined,
  };
}

export async function getCustomerReportData(serialNumber: string) {
  const response = await client.get<CustomerReportDataRespType>(
    "/pas/customer-report-data",
    {
      params: { serial_number: serialNumber },
    }
  );

  return response.data;
}

export function useAllUniqueFieldValues(filter?: FilterFieldsPASTypes[]) {
  const isFetching = useIsFetching();
  return useQuery({
    queryKey: ["pas-unique-field-values", filter],
    queryFn: () => getAllUniqueFieldValues(filter),
    enabled: !isFetching,
  });
}

export async function prefetchPasList({
  page,
  nOfItems = 10,
  search,
  filter,
}: {
  page: number;
  nOfItems?: number;
  search?: string;
  filter?: FilterFieldsPASTypes[];
}) {
  // The results of this query will be cached like a normal query
  await queryClient.prefetchQuery({
    queryKey: ["pas-list", page, nOfItems, search, filter],
    queryFn: () => getListPas(page, nOfItems ?? 10, search, filter),
  });
}

export function usePasList({
  page,
  nOfItems = 10,
  search,
  filter,
}: {
  page: number;
  nOfItems?: number;
  search?: string;
  filter?: FilterFieldsPASTypes[];
}) {
  return useQuery({
    queryKey: ["pas-list", page, nOfItems, search, filter],
    queryFn: () => getListPas(page, nOfItems ?? 10, search, filter),
    staleTime: 1000 * 60 * 5,
  });
}

export function usePASValveInfo(serial_number?: string) {
  return useQuery({
    queryKey: ["valve-info", serial_number],
    queryFn: () => getValveInfo(serial_number!),
    enabled: !!serial_number,
  });
}

export function useRoutineData(
  routineId: string,
  routineType: RoutineNamesType,
  customer?: boolean
) {
  return useQuery({
    queryKey: ["routine-data", routineId, routineType, customer],
    queryFn: () => getRoutineData(routineId, routineType, customer),
    enabled: !!routineId && !!routineType,
  });
}

export function useRoutinesData(
  routines: {
    routineId: string;
    routineType: RoutineNamesType;
  }[],
  customer?: boolean
) {
  return useQuery({
    queryKey: ["routines-data", routines, customer],
    queryFn: () =>
      Promise.all(
        routines.map((routine) =>
          getRoutineData(routine.routineId, routine.routineType, customer)
        )
      ),
    enabled: !!routines,
  });
}

export function useCustomerReportData(serialNumber?: string) {
  return useQuery({
    queryKey: ["customer-report-data", serialNumber],
    queryFn: () => getCustomerReportData(serialNumber!),
    enabled: !!serialNumber,
  });
}
