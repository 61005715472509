import { EMRoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types";
import { memo } from "react";
import { EMItemModel, EMRoutineModel } from "types/EMTypes";
import EMPlotWrapper from "./EMPlot";
import EMRoutineInfo from "./EMRoutineInfo";

const EMPlotMemo = memo(EMPlotWrapper);

interface EMPlotAndInfoProps {
  item: EMRoutineModel;
  routine: EMRoutineNamesType;
  manifoldData: EMItemModel;
  linear: boolean;
}

export default function EMPlotAndInfo({
  item,
  routine,
  manifoldData,
  linear,
}: EMPlotAndInfoProps) {
  return (
    <div className="flex">
      <EMPlotMemo
        routineId={item.id}
        type={routine}
        lastModified={item.last_modified}
        valveType={manifoldData.variant ?? undefined}
        isLinear={linear}
      />
      <EMRoutineInfo
        routineId={item.id}
        type={routine}
        lastModified={item.last_modified}
        isLinear={linear}
      />
    </div>
  );
}
