import { Icon, Tooltip } from "@domin-frontend/domin-ui";
import { Link } from "react-router-dom";
import {
  PasListItemType,
  RoutineTableItemType,
} from "../../../types/responseTypes";
import { CellContext } from "@tanstack/react-table";

interface Props {
  cell: CellContext<PasListItemType, RoutineTableItemType | undefined>;
}

function LinkIcon({ value, link }: { value: string; link: string }) {
  return (
    <Tooltip content={value} minLength={5}>
      <Link to={link}>
        <div className="w-min 3xl:w-full flex 3xl:justify-center">
          {value === "pass" ? (
            <div className="flex items-center justify-center bg-primary-100 w-8 h-8 p-[0.4rem] rounded-full">
              <Icon name="approved" className="stroke-primary-600" />
            </div>
          ) : value === "pass by default" ? (
            <div className="flex items-center justify-center bg-neutral-200 w-8 h-8 p-[0.6rem] rounded-full">
              <Icon name="minus" className="stroke-neutral-700" />
            </div>
          ) : (
            <div className="flex items-center justify-center bg-pink-100 w-8 h-8 p-[0.6rem] rounded-full">
              <Icon name="failed" className="stroke-pink-600" />
            </div>
          )}
        </div>
      </Link>
    </Tooltip>
  );
}

export default function RoutineStatusCell({ cell }: Props) {
  const value = cell.getValue();
  if (!value) {
    return null;
  }
  const sn = cell.row.original.serial_number;
  const routineType = cell.column.id;
  const testId = value.test_id;

  const link = `/pas/${sn}/${testId}/${routineType}?view=detail`;
  const localStoredHiddenColumns = JSON.parse(
    localStorage.getItem("PASTableHiddenColumns") ?? "[]",
  );
  // if "latest_rig" is in the hidden columns, don't show the link
  const isLatestRigHidden = localStoredHiddenColumns.includes("latest_rig");

  return (
    <Tooltip
      content={isLatestRigHidden ? "" : `Rig: ${value.rig}`}
      minLength={5}
    >
      <LinkIcon link={link} value={value.status} />
    </Tooltip>
  );
}
