import { ReactComponent as DominDots } from "components/svg/domin_dots.svg";
import { Logo as LogoMark } from "@domin-frontend/domin-ui";

const A4 = {
  height: 297,
  width: 210,
};

const currentYear = new Date().getFullYear();

export default function PageLayout({
  children,
  footer,
  page,
}: {
  children: React.ReactNode;
  footer?: boolean;
  page?: number;
}) {
  return (
    <div
      className={`flex bg-neutral-0 relative pr-[2cm] ${
        footer ? "pl-[2cm] pb-[2cm]" : ""
      } bg-pr`}
      style={{
        minHeight: `${A4.height}mm`,
        maxHeight: `${A4.height}mm`,
        width: `${A4.width}mm`,
        height: `${A4.height}mm`,
      }}
    >
      <div className="absolute top-4 right-4">
        <DominDots />
      </div>
      {children}
      {footer && (
        <div className="absolute bottom-4 inset-x-0 w-full h-[2cm] bg-neutral-0 pr-[0.5cm] pl-[2cm] flex items-center justify-between">
          <div className="flex gap-4">
            {page && (
              <p className="text-neutral-500 whitespace-nowrap">{page}</p>
            )}
            <p className="text-neutral-500 whitespace-nowrap">
              © Domin {currentYear}
            </p>
          </div>
          <div className="w-20 pt-2 flex items-center h-full">
            <LogoMark logoMark link={false} />
          </div>
        </div>
      )}
    </div>
  );
}
