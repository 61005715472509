import {
  CardLayout,
  ErrorMessage,
  Label,
  Loading,
  PASRoutineNames,
  TabBar,
  capitalise,
} from "@domin-frontend/domin-ui";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { usePASValveInfo } from "queries/PASQueries";
import { useEffect, useState } from "react";
import { tagStatus } from "components/PAS/PASDashboard/StatusCell";
import useSetDocumentName from "helpers/hooks/shared/useSetDocumentName";
import PASValveInfoTable from "components/PAS/PASValveView/PASValveInfoTable";
import { useMediaQuery } from "react-responsive";
import ShareModal from "components/valveIntelligence/valveComparison/Components/ShareModal";
import TableInfoHeader from "components/SharedComponents/TableInfoHeader";
import InfoWrapper from "tempComponents/infoTable/InfoWrapper";
import { withTransaction } from "@elastic/apm-rum-react";
import ReactMarkdown from "react-markdown";

export function formatDay(dateString: string) {
  const date = new Date(dateString);
  return `${date.toLocaleString(undefined, {
    year: "2-digit",
    month: "short",
    day: "numeric",
  })}`;
}

function PASValveView() {
  const isDesktop = useMediaQuery({ minWidth: 1280 });
  const [infoOpen, setInfoOpen] = useState(isDesktop);
  const [isNotesOpen, setIsNotesOpen] = useState(true);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const { serialNumber, test } = useParams<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    data: valve,
    isLoading: isValveLoading,
    isError: isValveError,
    error: valveError,
  } = usePASValveInfo(serialNumber);

  // update document name
  useSetDocumentName(`PAS SN: ${serialNumber}`);

  useEffect(() => {
    if (!test && valve?.tests && valve.tests.length > 0) {
      // view can be "overview", "detail" or "customer-report", any other value will default to "detail"
      const view = searchParams.get("view") ?? "detail";

      const lastTest = valve.tests[0];
      // find first routines with data
      const lastTestRoutine = PASRoutineNames.find((r) => lastTest.routines[r]);
      navigate(
        `/pas/${serialNumber}/${lastTest.test_id}/${lastTestRoutine}?view=${view}`,
        {
          state: { redirected: true },
        },
      );
    }
  }, [test, navigate, valve, serialNumber, searchParams]);

  useEffect(() => {
    const view = searchParams.get("view") ?? "detail";
    if (!["overview", "detail", "customer-report"].includes(view)) {
      setSearchParams({ view: "detail" });
    }
  }, [setSearchParams, searchParams]);

  // check if valve.days_late is an array and get the first element, if it is not an array, get the value

  return (
    <>
      <CardLayout
        title={`Valve SN: ${serialNumber}`}
        tag={
          valve?.status
            ? {
                status: tagStatus[valve.status],
                children: capitalise(valve.status),
                dominColours: true,
              }
            : undefined
        }
        buttons={[
          {
            children: "Share",
            variant: "secondary",
            onClick: () => setShareModalOpen(true),
          },
        ]}
      >
        <TabBar
          options={[
            {
              value: "overview",
              selected: searchParams.get("view") === "overview",
              contextMenu: [
                {
                  text: "Set as default view",
                  onClick: () => {
                    window.localStorage.setItem("view", "overview");
                  },
                },
              ],
            },
            {
              label: "Routine Detail",
              value: "detail",
              selected: searchParams.get("view") === "detail",
              contextMenu: [
                {
                  text: "Set as default view",
                  onClick: () => {
                    window.localStorage.setItem("view", "detail");
                  },
                },
              ],
            },
            {
              label: "Customer Report",
              value: "customer-report",
              selected: searchParams.get("view") === "customer-report",
            },
          ]}
          onClick={(value) => setSearchParams({ view: value })}
        />
        <div
          className={`relative ${
            searchParams.get("view") !== "customer-report"
              ? "grid grid-cols-[24px,_1fr]  xl:grid-cols-[minmax(2rem,auto),_1fr] gap-4"
              : ""
          } pt-2`}
        >
          {searchParams.get("view") !== "customer-report" &&
            (isValveLoading || !valve ? (
              <div className="col-span-full">
                <Loading />
              </div>
            ) : isValveError ? (
              <ErrorMessage>{`${valveError}`}</ErrorMessage>
            ) : (
              // valve info
              <InfoWrapper
                infoOpen={infoOpen}
                setInfoOpen={setInfoOpen}
                title="Valve Info"
              >
                {valve && <PASValveInfoTable data={valve} />}
                {valve.notes && (
                  <Label htmlFor="Notes" className="!min-w-0 max-w-full">
                    <TableInfoHeader
                      onClick={() => setIsNotesOpen(!isNotesOpen)}
                      isOpen={isNotesOpen}
                    >
                      Notes
                    </TableInfoHeader>
                    <div
                      className={`max-h-[60vh] max-w-full overflow-x-hidden overflow-y-scroll rounded-sm py-2 px- ${
                        isNotesOpen ? "block" : "hidden"
                      }`}
                    >
                      <ReactMarkdown
                        className="font-light whitespace-normal cursor-text max-w-[95%] break-words"
                        components={{
                          em: ({ children }) => (
                            <em className="">{children}</em>
                          ),
                          li: ({ children }) => (
                            <li className="list-disc list-inside">
                              {children}
                            </li>
                          ),
                          ul: ({ children }) => (
                            <ul className="list-disc list-inside">
                              {children}
                            </ul>
                          ),
                          h1: ({ children }) => (
                            <h1 className="text-xl">{children}</h1>
                          ),
                          h2: ({ children }) => (
                            <h2 className="text-base">{children}</h2>
                          ),
                          h3: ({ children }) => (
                            <h3 className="text-base">{children}</h3>
                          ),
                          h4: ({ children }) => (
                            <h4 className="text-sm">{children}</h4>
                          ),
                          strong: ({ children }) => (
                            <strong className="font-medium">{children}</strong>
                          ),
                          a: ({ children, href }) => (
                            <a
                              className="text-blue-500 hover:underline"
                              href={href}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {children}
                            </a>
                          ),
                        }}
                      >
                        {valve.notes}
                      </ReactMarkdown>
                    </div>
                  </Label>
                )}
              </InfoWrapper>
            ))}

          <Outlet />
        </div>
      </CardLayout>
      {shareModalOpen && <ShareModal setShowPopupModal={setShareModalOpen} />}
    </>
  );
}

export default withTransaction("PASValveView", "page_view")(PASValveView);
