import {
  Checkbox,
  Icon,
  LineChart,
  useOutsideMouseDown,
} from "@domin-frontend/domin-ui";
import { MultilineType } from "@domin-frontend/domin-ui/dist/esm/types";
import { memo, useEffect, useRef, useState } from "react";
import { xLabelMap, yLabelMap } from "./labelFormatMap";
// import { useOutsideAlerter } from "@domin-frontend/domin-ui/dist/esm/components/hooks/outsideAlerter";
import { getPercentages } from "helpers/functions/getSRPercentageLines";

const MemoLineChart = memo(LineChart);

interface props {
  data: MultilineType;
  guides?: MultilineType;
  fullScreenSettings?: {
    margins?: { top?: number; bottom?: number; left?: number; right?: number };
  };
  hideSettings?: boolean;
  initialLines?: { from?: number; to?: number; selected: boolean }[];
  limits?: {
    xMin?: number;
    xMax?: number;
    yMin?: number;
    yMax?: number;
  };
  legend?: {
    show: boolean;
    initialPosition?: {
      x?: number;
      y?: number;
    };
  };
  borders?: {
    top?: boolean;
    bottom?: boolean;
    left?: boolean;
    right?: boolean;
  };
}

function formatDataLines(
  data: MultilineType,
  selectedLines: { from: number; to: number; selected: boolean }[],
): MultilineType {
  return Object.keys(data).reduce((acc, key) => {
    const from = Number(key.split("_")[0]);
    const to = Number(key.split("_")[2]);
    if (selectedLines.find((t) => t.from === from && t.to === to)?.selected) {
      acc[key] = data[key];
    }
    return acc;
  }, {} as MultilineType);
}

export default function StepResponseChart({
  data,
  guides,
  fullScreenSettings,
  hideSettings = false,
  initialLines,
  limits,
  legend,
  borders,
}: props) {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  // set all data keys to setected test names and all values to true
  const [selectedLines, setSelectedLines] = useState<
    { from: number; to: number; selected: boolean }[]
  >(getPercentages(data));
  const ref = useRef<HTMLDivElement>(null);

  useOutsideMouseDown(ref, () => setIsOptionsOpen(false), { ids: ["options"] });

  // if initialLines is passed, use setSelectedLines to set the initialLines true and all others false
  useEffect(() => {
    if (initialLines) {
      // set all lines to false
      setSelectedLines((prevSelectedLines) =>
        prevSelectedLines.map((t) => ({ ...t, selected: false })),
      );
      // check if the initialLines are in the selectedLines and set them to the set value
      setSelectedLines((prevSelectedLines) =>
        prevSelectedLines.map((t) =>
          initialLines.some((i) => i.to)
            ? {
                ...t,
                selected:
                  t.to !== 0
                    ? initialLines.some(
                        (i) => i.from === t.from && i.to === t.to,
                      )
                    : initialLines.some((i) => i.from === t.from),
              }
            : t,
        ),
      );
    }
  }, [initialLines]);

  return (
    <>
      {!hideSettings && (
        <div
          ref={ref}
          className="absolute top-20 right-2 flex justify-between items-center z-40 cursor-pointer bg-neutral-0 "
          onClick={() => setIsOptionsOpen(true)}
          id="options"
        >
          <Icon name="options" />
          {isOptionsOpen && (
            <div className="absolute top-1 right-0 flex flex-col gap-2 p-2 bg-neutral-0 border border-neutral-200 shadow-lg">
              {selectedLines.map((line) => (
                <div
                  className="flex items-center whitespace-nowrap gap-2 cursor-pointer"
                  onClick={() =>
                    setSelectedLines(
                      selectedLines.map((t) => {
                        if (t.to !== 0) {
                          return t.to === line.to
                            ? { ...t, selected: !t.selected }
                            : t;
                        } else {
                          return t.from === line.from
                            ? { ...t, selected: !t.selected }
                            : t;
                        }
                      }),
                    )
                  }
                >
                  <Checkbox checked={line.selected} />
                  <p>
                    Demand/Feedback: {line.from}% - {line.to}%
                  </p>
                </div>
              ))}
              {selectedLines.some((t) => !t.selected) && (
                <div
                  className="flex items-center whitespace-nowrap gap-2 cursor-pointer"
                  onClick={() =>
                    setSelectedLines(
                      selectedLines.map((t) => ({ ...t, selected: true })),
                    )
                  }
                >
                  <Checkbox checked={selectedLines.every((t) => t.selected)} />
                  <p>All</p>
                </div>
              )}
              {selectedLines.some((t) => t.selected) && (
                <div
                  className="flex items-center whitespace-nowrap gap-2 cursor-pointer"
                  onClick={() =>
                    setSelectedLines(
                      selectedLines.map((t) => ({ ...t, selected: false })),
                    )
                  }
                >
                  <Checkbox checked={selectedLines.every((t) => !t.selected)} />
                  <p>None</p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <MemoLineChart
        data={formatDataLines(data, selectedLines)}
        routineType={"step_response"}
        settings={{
          labels: {
            x: xLabelMap["step_response"],
            y: yLabelMap["step_response"],
          },
          guides: guides,
          limits: {
            xMin: limits?.xMin ?? 0,
            xMax: limits?.xMax ?? 20,
            yMin: limits?.yMin ?? -110,
            yMax: limits?.yMax ?? 110,
          },
          focusPoints: { type: "delaunay" },
          ticks: { y: [-100, -75, -50, -25, 0, 25, 50, 75, 100] },
          zeroGuides: { x: false, y: false },
          gridline: true,
          fullscreen: fullScreenSettings,
          hideSettings: hideSettings,
          legend: legend,
          line: {
            xzDash: true,
          },
          borders: borders,
        }}
      />
    </>
  );
}
