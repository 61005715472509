import { BiMap } from "@domin-frontend/domin-ui";
import {
  RoutineNamesType,
  RoutineNamesType_short,
} from "@domin-frontend/domin-ui/dist/esm/types";
const routineBiMap = new BiMap<RoutineNamesType_short, RoutineNamesType>({
  fe: "front_edge",
  be: "back_edge",
  fa: "flow_angle",
  le: "leakage",
  fd: "flow_demand",
  fr: "frequency_response",
  sr: "step_response",
  pg: "pressure_gain",
  ss: "slow_sine",
  mc: "motor_calibration",
  bp: "bias_position",
  fspl: "fail_safe_position_leakage",
});

export default routineBiMap;
