import { Icon, Tag, capitalise } from "@domin-frontend/domin-ui";
import { BasicStatusType } from "@domin-frontend/domin-ui/dist/esm/types";
import { formatDate } from "helpers/functions/shared/formatDate";
import { TestStatusType, ValveTestsPASType } from "types/responseTypes";

export type StyleType = {
  [key in TestStatusType | "motor_calibration"]: BasicStatusType;
};

const tagStatus: StyleType = {
  complete: "success",
  incomplete: "danger",
  motor_calibration: "pending",
};

export default function TestItem({
  test,
  onClick,
  icon,
  open,
  isLatestTest,
}: {
  test: ValveTestsPASType;
  onClick: () => void;
  icon?: boolean;
  open?: boolean;
  isLatestTest?: boolean;
}) {
  return (
    <div
      onClick={onClick}
      className="transition-colors flex items-center justify-between gap-4 hover:bg-neutral-100 cursor-pointer px-2"
    >
      <div className="flex flex-col py-2">
        <h3 className="whitespace-nowrap">
          <span className="">Test date:</span> {formatDate(test.last_modified)}
        </h3>
      </div>
      <div className="flex h-full items-center gap-2">
        <Tag
          status={
            tagStatus[
              test.latest_rig === "Calibration Station"
                ? "motor_calibration"
                : test.status
            ]
          }
          dominColours
        >
          {/* {capitalise(test.status)} */}
          {test.latest_rig === "Calibration Station"
            ? "Calibration Station"
            : capitalise(test.status)}
        </Tag>
        {isLatestTest && (
          <Tag status="success" dominColours>
            Latest
          </Tag>
        )}
        <div className="h-full w-auto flex items-center">
          {icon && !open ? (
            <Icon
              className={`stroke-primary-800 h-4 w-4 transition-transform ${
                open ? "rotate-180" : ""
              }`}
              name="show"
            />
          ) : (
            <div className="w-4 h-4" />
          )}
        </div>
      </div>
    </div>
  );
}
