import {
  DateInput,
  Label,
  Loading,
  RoutineNames,
  SingleSelect,
  TextInput,
  ValveNames,
  MultiSelect,
} from "@domin-frontend/domin-ui";
import { SelectOptionType } from "@domin-frontend/domin-ui/dist/esm/components/forms/MultiSelect";
import { useEffect, useState } from "react";
import { FilterFieldsPASReqType, RoutineFiltersType } from "types/requestTypes";
import {
  HandleFilterPropsType,
  dropdownValuesType,
} from "types/valveIntelligenceTypes";
import InputHeader from "../valveComparison/Components/InputClearHeader";
import { makeUniqueValuesQuery } from "../helpers/filterQueryFormatter";
import { displayFilterLabel } from "../helpers/displayFilterLabelsMap";
import { useVIUniqueFieldValues } from "queries/ValveIntelligenceQueries";
import { formatDate } from "helpers/functions/shared/formatDate";
import { tagStatusMap } from "helpers/functions/shared/statusMap";

const initialDropdownValues: dropdownValuesType = {
  serial_number: [],
  valve_type: ValveNames,
  status: ["pass", "fail", "pas_by_default"],
  routine_type: RoutineNames,
  rig: [],
  test_id: [],
};

const FilterFieldsArray: RoutineFiltersType[] = [
  "routine_type",
  "status",
  "rig",
  "valve_type",
  "sku",
  "customer",
  "start_date",
  "end_date",
];

const excludedRoutineTypes = ["step_response", "frequency_response"];

interface CCQueryFormProps {
  handleFilterQueries: (props: HandleFilterPropsType) => void;
  filterQuery: FilterFieldsPASReqType;
  column: number;
  routinesMultiSelect: SelectOptionType[];
  setRoutinesMultiSelect: React.Dispatch<
    React.SetStateAction<SelectOptionType[]>
  >;
}

export default function CCQueryForm({
  column,
  handleFilterQueries,
  filterQuery,
  routinesMultiSelect,
  setRoutinesMultiSelect,
}: CCQueryFormProps) {
  const [dropdownValues, setDropdownValues] = useState(initialDropdownValues);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 14))
  );
  const [endDate, setEndDate] = useState(new Date());

  const { data: suggestedData, isLoading } = useVIUniqueFieldValues(
    makeUniqueValuesQuery(filterQuery),
    startDate,
    endDate
  );
  console.log(routinesMultiSelect);

  function handleChange({
    field,
    value,
  }: {
    field: string;
    value: string | undefined;
  }) {
    handleFilterQueries({
      filter: {
        ...filterQuery,
        [field]: value,
      },
      column: column,
    });
  }

  useEffect(() => {
    if (suggestedData) {
      setDropdownValues((d) => ({
        ...d,
        ...suggestedData.filters.reduce((acc, curr) => {
          acc[curr.field] = curr.values;
          return acc;
        }, {} as dropdownValuesType),
      }));
    }
  }, [suggestedData]);

  useEffect(() => {
    if (suggestedData?.routines) {
      setRoutinesMultiSelect(
        suggestedData.routines.map((r) => ({
          label: `${formatDate(r.last_modified)} - ${r.serial_number}`,
          value: r.routine_id,
          selected: true,
          statusTag: {
            status: tagStatusMap[r.status ?? "pass by default"],
            label: r.status,
          },
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedData?.routines]);

  const handleSelectAll = () => {
    setRoutinesMultiSelect((prevRoutines) =>
      prevRoutines.map((routine) => ({ ...routine, selected: true }))
    );
  };

  const handleClearAll = () => {
    setRoutinesMultiSelect((prevRoutines) =>
      prevRoutines.map((routine) => ({ ...routine, selected: false }))
    );
  };

  const filteredRoutineTypes = dropdownValues.routine_type.filter(
    (routine) => !excludedRoutineTypes.includes(routine)
  );

  const selectedRoutineCount = routinesMultiSelect.filter(
    (routine) => routine.selected
  ).length;

  return (
    <div className="flex flex-col h-full gap-4">
      <>
        <div className="relative grid grid-cols-2 gap-2 pr-4 pb-6">
          {FilterFieldsArray.map((field, i) => {
            const value = filterQuery[field as keyof FilterFieldsPASReqType];
            const dropdown = dropdownValues[field as keyof dropdownValuesType];
            return (
              <Label
                htmlFor={field}
                key={`${field}${i}${column}`}
                // className={"!min-w-64 !max-w-96 h-min"}
                className={"!w-full h-min"}
              >
                <InputHeader
                  field={field}
                  onClick={() => handleChange({ field, value: "" })}
                  value={value?.toString()}
                  displayClear={true}
                >
                  {displayFilterLabel(field)}
                </InputHeader>
                {field === "start_date" || field === "end_date" ? (
                  <DateInput
                    name={field}
                    value={field === "start_date" ? startDate : endDate}
                    onChange={(newDate) => {
                      if (field === "start_date") {
                        setStartDate(newDate);
                      } else {
                        setEndDate(newDate);
                      }
                    }}
                  />
                ) : dropdown ? (
                  <SingleSelect
                    value={`${value ?? ""}`}
                    onChange={(e) => {
                      handleChange({ field, value: e.value });
                    }}
                    options={
                      field === "routine_type"
                        ? filteredRoutineTypes.map((v) => ({
                            label: v,
                            value: v,
                          }))
                        : dropdown.map((v) => ({ label: v, value: v }))
                    }
                  />
                ) : (
                  <TextInput
                    name={field}
                    value={value ?? ""}
                    onChange={() => {}}
                    placeholder="Enter Value"
                  />
                )}
              </Label>
            );
          })}
        </div>
        {filterQuery.routine_type && suggestedData?.routines && (
          <Label htmlFor="routine_id" className="max-h-96">
            <div className="flex justify-between items-center pr-4">
              <InputHeader
                field="routines"
                onClick={() => {}}
                value=""
                displayClear={false}
              >
                Routines ({selectedRoutineCount}/{routinesMultiSelect.length})
              </InputHeader>
              <div>
                <button
                  onClick={handleSelectAll}
                  className="text-gray-500 underline mr-4"
                >
                  Select All
                </button>
                <button
                  onClick={handleClearAll}
                  className="text-gray-500 underline"
                >
                  Clear
                </button>
              </div>
            </div>
            <div className="overflow-y-scroll">
              {isLoading && (
                <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center">
                  <Loading />
                </div>
              )}
              <MultiSelect
                displaySearch={true}
                values={routinesMultiSelect}
                setValues={setRoutinesMultiSelect}
              />
            </div>
          </Label>
        )}
      </>
    </div>
  );
}
