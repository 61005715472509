import {
  Icon,
  Label,
  RoutineNames,
  SingleSelect,
  Tag,
  TextInput,
  ValveNames,
} from "@domin-frontend/domin-ui";
import { useAllUniqueFieldValues } from "queries/PASQueries";
import { useFilteredRoutines } from "queries/ValveIntelligenceQueries";
import { useEffect, useState } from "react";
import { FilterFieldsPASReqType } from "types/requestTypes";
import {
  HandleFilterPropsType,
  dropdownValuesType,
} from "types/valveIntelligenceTypes";
import InputHeader from "./InputClearHeader";
import { makeUniqueValuesQuery } from "components/valveIntelligence/helpers/filterQueryFormatter";

// When a routine is selected from the state of the parent component is updated, and the list of routines is rendered based on that state.
const initialDropdownValues: dropdownValuesType = {
  serial_number: [],
  valve_type: ValveNames,
  status: ["pass", "fail", "pas_by_default"],
  routine_type: RoutineNames,
};

interface VCQueryFormProps {
  handleFilterQueries: (props: HandleFilterPropsType) => void;
  filterQuery: FilterFieldsPASReqType;
  clearColumn: (column: number) => void;
  column: number;
}

export default function VCQueryForm({
  column,
  handleFilterQueries,
  filterQuery,
}: VCQueryFormProps) {
  const [dropdownValues, setDropdownValues] = useState(initialDropdownValues);
  const [isFilterOpen, setIsFilterOpen] = useState(
    !!(filterQuery.routine_type || filterQuery.status)
  );
  useFilteredRoutines(filterQuery);

  const { data: suggestedData } = useAllUniqueFieldValues(
    makeUniqueValuesQuery(filterQuery)
  );

  function handleChange({
    field,
    value,
  }: {
    field: string;
    value: string | undefined;
  }) {
    handleFilterQueries({
      filter: {
        ...filterQuery,
        [field]: value,
      },
      column: column,
    });
  }

  // when the suggested data changes, update the dropdown values
  useEffect(() => {
    if (suggestedData) {
      setDropdownValues((d) => ({
        ...d,
        // if the serial number is defined, then the serial number is the only option
        serial_number:
          suggestedData.find((item) => item.field === "serial_number")
            ?.values ?? [],
        // if only one valve type is suggested, then set the valve type to that
        valve_type:
          suggestedData.find((item) => item.field === "valve_type")?.values
            .length === 1
            ? [
                suggestedData.find((item) => item.field === "valve_type")
                  ?.values[0] ?? "",
              ]
            : ValveNames,
      }));
    }
  }, [suggestedData]);

  // when the serial number in suggestedData changes, update the valve type in filterQueries
  useEffect(() => {
    if (filterQuery.serial_number) {
      if (
        suggestedData?.find((item) => item.field === "valve_type")?.values
          .length === 1
      ) {
        handleChange({
          field: "valve_type",
          value:
            suggestedData?.find((item) => item.field === "valve_type")
              ?.values[0] ?? undefined,
        });
      } else if (
        suggestedData?.find((item) => item.field === "valve_type")?.values
          .length === 0
      ) {
        handleChange({
          field: "valve_type",
          value: undefined,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedData]);

  return (
    <>
      <form className="relative">
        <div className="w-full grid grid-cols-1 md:grid-cols-[1fr,_1fr,_auto] gap-4 items-end">
          <Label htmlFor="serial_number">
            <InputHeader
              field="serial_number"
              onClick={() =>
                handleFilterQueries({
                  column: column,
                  filter: { serial_number: undefined },
                })
              }
              value={filterQuery?.serial_number}
            >
              Valve Serial Number
            </InputHeader>
            {dropdownValues.serial_number.length === 0 ? (
              <TextInput
                name="serial_number"
                value={filterQuery?.serial_number}
                onChange={(e) =>
                  handleChange({
                    field: "serial_number",
                    value: e.target.value,
                  })
                }
              />
            ) : (
              <SingleSelect
                name="serial_number"
                options={dropdownValues.serial_number}
                value={filterQuery?.serial_number ?? ""}
                onChange={(e) => {
                  handleChange({
                    field: "valve_type",
                    value: undefined,
                  });
                  handleChange({
                    field: "serial_number",
                    value: e.value === "" ? undefined : e.value,
                  });
                }}
              />
            )}
          </Label>
          <Label htmlFor="valve_type">
            <InputHeader
              field="valve_type"
              onClick={() =>
                handleFilterQueries({
                  column: column,
                  filter: { valve_type: undefined },
                })
              }
              value={filterQuery?.valve_type}
              displayClear={filterQuery?.serial_number !== undefined}
            >
              Valve Type
            </InputHeader>
            <SingleSelect
              name="valve_type"
              options={dropdownValues.valve_type}
              onChange={(e) => {
                handleChange({
                  field: "serial_number",
                  value: undefined,
                });
                handleChange({
                  field: "valve_type",
                  value: e.value,
                });
              }}
              value={filterQuery.valve_type ?? ""}
              disabled={
                (dropdownValues.valve_type.length === 1 &&
                  filterQuery.serial_number !== undefined) ||
                filterQuery.serial_number === undefined
              }
            />
          </Label>
          <div
            className={`w-4 h-full flex items-end pb-5 ${
              isFilterOpen ? "fill-primary-600" : "fill-neutral-400"
            } hover:fill-primary-600`}
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          >
            <Icon name="filter" className=" w-4 h-4" />
          </div>
        </div>
        <div
          className={`transition-all w-full grid grid-cols-1 md:grid-cols-[1fr,_1fr,_auto] px-4 bg-neutral-100 ${
            isFilterOpen
              ? "h-auto pt-1 pb-4  mt-3 gap-4 opacity-100"
              : "h-0 overflow-hidden pt-0 mt-0 opacity-0 gap-0"
          }`}
        >
          <Label
            htmlFor="status"
            className={isFilterOpen ? "h-auto" : "h-0 p-0 m-0"}
          >
            <InputHeader
              field="status"
              onClick={() =>
                handleFilterQueries({
                  column: column,
                  filter: { status: undefined },
                })
              }
              value={filterQuery.status}
            >
              Routine Status
            </InputHeader>
            <SingleSelect
              name="status"
              options={dropdownValues.status}
              value={filterQuery.status ?? ""}
              onChange={(e) => {
                handleChange({
                  field: "status",
                  value: e.value === "" ? undefined : e.value,
                });
              }}
            />
          </Label>
          <Label
            htmlFor="routine_type"
            className={isFilterOpen ? "h-auto" : "h-0 p-0 m-0"}
          >
            <InputHeader
              onClick={() =>
                handleFilterQueries({
                  column: column,
                  filter: { routine_type: undefined },
                })
              }
              field="routine_type"
              value={filterQuery.routine_type}
            >
              Routine Type
            </InputHeader>
            <SingleSelect
              name="routine_type"
              options={dropdownValues.routine_type}
              value={filterQuery.routine_type ?? ""}
              onChange={(e) => {
                handleChange({
                  field: "routine_type",
                  value: e.value === "" ? undefined : e.value,
                });
              }}
            />
          </Label>
        </div>
      </form>

      {/* Tags */}
      <div className="flex gap-2 py-2 ">
        {/* sort so don't include serial number and valve type */}
        {Object.entries(filterQuery)
          .filter(([key, _]) => key !== "serial_number" && key !== "valve_type")
          .map(([key, value], i) => {
            if (value === undefined) {
              return null;
            }

            return (
              <Tag key={`${key}${i}`} status="idle" dominColours>
                {value}
                {((key === "valve_type" && !filterQuery.serial_number) ||
                  key !== "valve_type") && (
                  <div
                    className="w-5 h-5 flex items-center justify-center p-1 rounded-full hover:bg-pink-100 stroke-pink-400 hover:stroke-pink-600"
                    onClick={() =>
                      handleFilterQueries({
                        column: column,
                        filter: {
                          ...filterQuery,
                          [key]: undefined,
                        },
                      })
                    }
                  >
                    <Icon name="close" className="w-2 h-2 " />
                  </div>
                )}
              </Tag>
            );
          })}

        {Object.entries(filterQuery).filter(([_, value]) => value !== undefined)
          .length > 1 && (
          <div
            className="cursor-pointer"
            onClick={() =>
              handleFilterQueries({ column: column, remove: true })
            }
          >
            <Tag status="danger" dominColours>
              Clear all
            </Tag>
          </div>
        )}
      </div>
    </>
  );
}
