import {
  Checkbox,
  ContextMenu,
  Icon,
  Loading,
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tooltip,
  Tr,
  useOutsideMouseDown,
} from "@domin-frontend/domin-ui";
import {
  PasListItemType,
  UniqueFieldsRespType,
} from "../../../types/responseTypes";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import StatusCell from "./StatusCell";
import RoutineStatusCell from "./RoutineStatusCell";
import ResultStatusCell from "./ResultStatusCell";
import { Link, useSearchParams } from "react-router-dom";
import { formatDate } from "helpers/functions/shared/formatDate";
import THDropdownCheckbox from "components/SharedComponents/THDropdownCheckbox";
import { useEffect, useRef, useState } from "react";
import { FilterFieldsPASTypes, RoutineFiltersType } from "types/requestTypes";
import { formatRoutineTitle } from "helpers/functions/shared/formatRoutineName";

import { routineNameMapper } from "@domin-frontend/domin-ui/dist/esm/helpers/routineNamesMap";
import { hyperflowFilters, defaultFilters } from "../filterConfig";

interface Props {
  data: PasListItemType[];
  fieldUniqueValues?: UniqueFieldsRespType[];
  filteredLists?: FilterFieldsPASTypes[];
  setFilteredLists: (lists?: FilterFieldsPASTypes[]) => void;
  isLoading: boolean;
  itemsPerPage: number;
}

const columnHelper = createColumnHelper<PasListItemType>();

export function TableSettings({
  columns,
  hiddenColumns,
  setHiddenColumns,
}: // setDropdownOpen
{
  columns: string[];
  hiddenColumns: string[];
  setHiddenColumns: (columns: string[]) => void;
}) {
  function handleChange(value: string) {
    if (columns.includes(value) || value === "all") {
      if (value === "all") {
        setHiddenColumns([]);
        window.localStorage.setItem(
          "PASTableHiddenColumns",
          JSON.stringify([])
        );
      } else if (hiddenColumns.includes(value)) {
        setHiddenColumns(hiddenColumns.filter((column) => column !== value));
        window.localStorage.setItem(
          "PASTableHiddenColumns",
          JSON.stringify(hiddenColumns.filter((column) => column !== value))
        );
      } else {
        setHiddenColumns([...hiddenColumns, value]);
        window.localStorage.setItem(
          "PASTableHiddenColumns",
          JSON.stringify([...hiddenColumns, value])
        );
      }
    }
  }

  return (
    <div className="absolute flex flex-col gap-1 p-2 top-0 right-0 z-50 bg-neutral-100 shadow">
      {columns.map((column, i) => (
        <Checkbox
          key={`${column}-${i}`}
          checked={!hiddenColumns.includes(column)}
          name={column}
          id={column}
          value={column}
          onClick={() => handleChange(column)}
        />
      ))}
      <span
        className="flex items-center w-full text-primary-600"
        onClick={() => handleChange("all")}
      >
        Select all
      </span>
    </div>
  );
}

function DropdownLinks({
  links,
}: {
  links: {
    link: string;
    name: string;
    newTab?: boolean;
  }[];
}) {
  return (
    <div className="flex flex-col bg-neutral-0 shadow">
      {links.map((link, i) => {
        const external = link.newTab ?? link.link.includes("http");

        return (
          <Link
            key={`${link.link}-${i}`}
            className="p-2 bg-neutral-0 hover:bg-primary-600 text-primary-600 hover:text-neutral-0"
            to={link.link}
            target={external ? "_blank" : ""}
            rel={external ? "noreferrer noopener" : ""}
          >
            {link.name}
          </Link>
        );
      })}
    </div>
  );
}

const columns = [
  columnHelper.accessor("serial_number", {
    cell: (i) => {
      const value = i.getValue();
      return (
        <Link
          className="w-max"
          to={`/pas/${i.row.original.serial_number}`}
          state={{ redirected: false }}
        >
          <p>{value}</p>
        </Link>
      );
    },
  }),
  columnHelper.accessor("date", {
    cell: (date) => {
      const value = formatDate(date.getValue());
      return (
        <Link className="w-max" to={`/pas/${date.row.original.serial_number}`}>
          <p>{value}</p>
        </Link>
      );
    },
  }),
  columnHelper.accessor("status", {
    cell: (i) => {
      const value = i.getValue();
      return (
        <Link className="w-max" to={`/pas/${i.row.original.serial_number}`}>
          <StatusCell value={value} />
        </Link>
      );
    },
  }),
  columnHelper.accessor("result_status", {
    cell: (i) => {
      return <ResultStatusCell cell={i} />;
    },
  }),
  columnHelper.accessor("customer", {
    cell: (i) => {
      const value = i.getValue();
      return (
        <span className="relative max-w-[8rem]">
          <ContextMenu
            dropdown={
              <DropdownLinks
                links={[
                  {
                    link: `/pas/${i.row.original.serial_number}?view=customer-report`,
                    name: "Customer Report",
                    newTab: true,
                  },
                  {
                    link: `/pas/${i.row.original.serial_number}`,
                    name: "Open in new tab",
                    newTab: true,
                  },
                ]}
              />
            }
          >
            <Link
              className="relative w-max"
              to={`/pas/${i.row.original.serial_number}`}
            >
              <p className="truncate w-min max-2-[6rem] 3xl:max-w-[7rem]">
                {value}
              </p>
            </Link>
          </ContextMenu>
        </span>
      );
    },
  }),
  columnHelper.accessor("sku", {
    cell: (i) => {
      const value = i.getValue();
      return (
        <Link
          className="relative w-max"
          to={`/pas/${i.row.original.serial_number}`}
        >
          <p className="w-auto">{value}</p>
        </Link>
      );
    },
  }),
  columnHelper.accessor("latest_rig", {
    cell: (i) => {
      const value = i.getValue();
      return (
        <Link
          className="relative w-max"
          to={`/pas/${i.row.original.serial_number}`}
        >
          <p className="w-auto">{value}</p>
        </Link>
      );
    },
  }),
  columnHelper.accessor("motor_calibration", {
    cell: (i) => {
      return <RoutineStatusCell cell={i} />;
    },
  }),
  columnHelper.accessor("flow_angle", {
    cell: (i) => {
      return <RoutineStatusCell cell={i} />;
    },
  }),
  columnHelper.accessor("flow_demand", {
    cell: (i) => {
      return <RoutineStatusCell cell={i} />;
    },
  }),
  columnHelper.accessor("step_response", {
    cell: (i) => {
      return <RoutineStatusCell cell={i} />;
    },
  }),
  columnHelper.accessor("frequency_response", {
    cell: (i) => {
      return <RoutineStatusCell cell={i} />;
    },
  }),
  columnHelper.accessor("leakage", {
    cell: (i) => {
      return <RoutineStatusCell cell={i} />;
    },
  }),
  columnHelper.accessor("pressure_gain", {
    cell: (i) => {
      return <RoutineStatusCell cell={i} />;
    },
  }),
  columnHelper.accessor("bias_position", {
    cell: (i) => {
      return <RoutineStatusCell cell={i} />;
    },
  }),
  columnHelper.accessor("fail_safe_position_leakage", {
    cell: (i) => {
      return <RoutineStatusCell cell={i} />;
    },
  }),
];

export default function PasTable({
  data,
  fieldUniqueValues,
  filteredLists,
  setFilteredLists,
  isLoading,
  itemsPerPage,
}: Props) {
  const localStoredHiddenColumns = localStorage.getItem(
    "PASTableHiddenColumns"
  );

  const [hiddenColumns, setHiddenColumns] = useState<string[]>(
    localStoredHiddenColumns
      ? JSON.parse(localStoredHiddenColumns)
      : ["customer", "latest_rig"]
  );
  const [isTableSettings, setIsTableSettings] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<string>();
  const { getHeaderGroups, getRowModel, getAllColumns } = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const ref = useRef<HTMLDivElement>(null);
  useOutsideMouseDown(ref, () => setIsTableSettings(false));

  const [searchParams] = useSearchParams();

  // holds filters currently selected in open drop down
  const [newFilters, setNewFilters] = useState<FilterFieldsPASTypes>();

  // adds a new filters to the current ones, used when drop down is closed
  function addFilterParam(field: RoutineFiltersType, value: string[]) {
    !newFilters
      ? setNewFilters({
          field: field,
          values: value,
        })
      : setNewFilters({
          field: field,
          values: newFilters.values.concat(value),
        });
  }

  function handleFilterChange(field: RoutineFiltersType, values: string[]) {
    // If there are values in the field
    if (values.length > 0) {
      // If there are already filtered fields
      if (filteredLists) {
        // Add the new field and its values to the existing filtered list,
        // filtering out any fields with no values
        const newList: FilterFieldsPASTypes[] = [
          ...filteredLists.filter(
            (f) => f.field !== field && f.values.length > 0
          ),
          { field, values },
        ];
        setFilteredLists(newList);
      } else {
        // If there are no filtered fields, create a new list with the new field and its values
        setFilteredLists([{ field, values }]);
      }
    } else if (filteredLists) {
      // Remove the field from the existing filtered list, filtering out any fields with no values
      const newList = filteredLists.filter(
        (f) => f.field !== field && f.values.length > 0
      );
      if (newList.length > 0) {
        setFilteredLists(newList);
      } else {
        // If there are no more filtered fields, remove the filtered list altogether
        setFilteredLists(undefined);
      }
    }
  }
  // use effect triggered when the drop down is closed, calling handle filters with all selected values
  useEffect(() => {
    if (!openDropdown && newFilters) {
      // console.log(newFilters);
      handleFilterChange(newFilters.field, newFilters.values);
      setNewFilters(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDropdown, newFilters, filteredLists, setFilteredLists]);

  // filters the PAS table, uses filters defined in filterConfig
  useEffect(() => {
    const isHyperflow = searchParams.get("isHyperflow") === "true";
    if (isHyperflow) {
      setHiddenColumns(hyperflowFilters.hidden_columns);
      hyperflowFilters.filters.forEach((filter) => {
        handleFilterChange(filter.field, filter.values);
      });
    } else {
      setHiddenColumns(defaultFilters.hidden_columns);
      setFilteredLists(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setHiddenColumns, setFilteredLists]);

  // if local storage is empty, set hidden columns to customer and latest rig
  if (localStoredHiddenColumns === null) {
    localStorage.setItem(
      "PASTableHiddenColumns",
      JSON.stringify(["customer", "latest_rig"])
    );
  }

  return (
    <>
      {isLoading && getRowModel().rows.length > 0 && (
        <div className="absolute inset-0 flex items-center justify-items-center w-full h-full bg-neutral-0 opacity-30 z-20">
          <Loading className="items-center" />
        </div>
      )}
      <div
        className={`w-full h-auto max-h-[80vh] ${
          getRowModel().rows.length < 13
            ? "border-none"
            : "border-b border-neutral-100"
        } overflow-y-scroll scroll-m-12 `}
      >
        <Table className={`cursor-default `}>
          <THead className="sticky top-0 z-40">
            {getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers
                  .filter((c) => {
                    return !hiddenColumns.includes(c.id.toLowerCase());
                  })
                  .map((header, i) => {
                    const title = formatRoutineTitle(header.id);
                    const titleShort = formatRoutineTitle(header.id, true);
                    if (fieldUniqueValues?.find((f) => f.field === header.id)) {
                      return (
                        <THDropdownCheckbox
                          // capitalise the first letter of the field name
                          className="first:sticky first:left-0 first:z-20"
                          title={header.id}
                          displayedTitle={title}
                          key={`${header.id}-${i}`}
                          options={
                            fieldUniqueValues?.find(
                              (f) => f.field === header.id
                            )?.values ?? []
                          }
                          onChange={(e) =>
                            addFilterParam(header.id as RoutineFiltersType, e)
                          }
                          // selected options are the currently selected but switch to filteredList values when drop down is closed
                          selectedOptions={
                            newFilters?.values ||
                            (filteredLists?.find((f) => f.field === header.id)
                              ?.values ??
                              [])
                          }
                          open={openDropdown === header.id}
                          setOpen={setOpenDropdown}
                        />
                      );
                    }
                    return (
                      <Th className="whitespace-nowrap" key={header.id}>
                        <div className="3xl:hidden">
                          <Tooltip
                            content={routineNameMapper(header.id)}
                            minLength={1}
                          >
                            {/* in smaller screens displays only acronym */}
                            <span className="text-base  3xl:hidden">
                              {titleShort}
                            </span>
                          </Tooltip>
                        </div>
                        <span className="text-base  hidden 3xl:!inline">
                          {title}
                        </span>
                      </Th>
                    );
                  })}
                <Th>
                  <div
                    className="relative min-w-max"
                    onClick={() => {
                      setIsTableSettings(true);
                    }}
                  >
                    <Icon
                      className="w-4 h-4 fill-neutral-0 hover:fill-primary-100"
                      name="options"
                    />
                    {isTableSettings && (
                      <div ref={ref} className="absolute top-8 right-0 z-10">
                        <TableSettings
                          columns={getAllColumns().map((c) => c.id)}
                          hiddenColumns={hiddenColumns}
                          setHiddenColumns={setHiddenColumns}
                        />
                      </div>
                    )}
                  </div>
                </Th>
              </Tr>
            ))}
          </THead>
          {isLoading && getRowModel().rows.length === 0 ? (
            <tbody>
              <tr>
                <td colSpan={getAllColumns().length}>
                  <div style={{ height: `${itemsPerPage * 48}px` }}>
                    <Loading />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <TBody>
              {getRowModel().rows.map((row) => (
                <Tr key={row.id} className="relative">
                  {row
                    .getVisibleCells()
                    .filter((c) => {
                      return !hiddenColumns.includes(c.column.id.toLowerCase());
                    })
                    .map((cell) => (
                      <Td
                        key={cell.id}
                        className="first:sticky first:left-0 first:bg-neutral-0 first:z-10 whitespace-nowrap"
                      >
                        <ContextMenu
                          dropdown={
                            <DropdownLinks
                              links={[
                                {
                                  link: `/pas/${cell.row.original.serial_number}?view=customer-report`,
                                  name: "Customer Report",
                                  newTab: true,
                                },
                                {
                                  link: `/pas/${cell.row.original.serial_number}`,
                                  name: "Open in new tab",
                                  newTab: true,
                                },
                              ]}
                            />
                          }
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </ContextMenu>
                      </Td>
                    ))}
                </Tr>
              ))}
            </TBody>
          )}
        </Table>
      </div>
    </>
  );
}
