import { PASRoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types";
import { useQuery } from "@tanstack/react-query";
import { client } from "components/api";
import {
  FilterFieldsPASReqType,
  FilterFieldsPASTypes,
} from "types/requestTypes";
import {
  FilterPASRoutinesRespType,
  VIUniqueFieldsRespType,
  RoutineDataRespType,
} from "types/responseTypes";

async function getVIUniqueFieldValues(
  filter?: FilterFieldsPASTypes[],
  start_date?: Date,
  end_date?: Date
) {
  function dateToString(date: Date | undefined, isStartDate: boolean) {
    if (date) {
      // Set the time string to '00:00:00' for start date and '23:59:59' for end date
      const time = isStartDate ? "00:00:00" : "23:59:59";

      // Get the date as a string in the YYYY-MM-DD format
      const dateString = date.toISOString().split("T")[0];

      // Get the timezone offset
      const tzo = -date.getTimezoneOffset();
      const dif = tzo >= 0 ? "+" : "-";
      const tzoHours = String(Math.floor(Math.abs(tzo / 60))).padStart(2, "0");
      const tzoMinutes = String(Math.abs(tzo % 60)).padStart(2, "0");

      // Format the date, time, and timezone offset as a string
      return `${dateString}T${time}${dif}${tzoHours}:${tzoMinutes}`;
    } else {
      return undefined;
    }
  }

  const response = await client.post<VIUniqueFieldsRespType>(
    "/vi/get-unique-field-values",
    {
      filter: filter,
      start_date: dateToString(start_date, true),
      end_date: dateToString(end_date, false),
    }
  );
  return response.data;
}

async function getFilteredRoutines(request: FilterFieldsPASReqType) {
  const response = await client.get<FilterPASRoutinesRespType[]>(
    "/vi/get-filtered-routines",
    {
      params: request,
    }
  );
  return response.data;
}

async function getRoutineData(
  routine_id: string[],
  routine_type: PASRoutineNamesType,
  x_axis: string,
  y_axis: string,
  z_axis?: string
) {
  const payload: any = {
    routine_id: routine_id,
    routine_type: routine_type,
    x_axis: x_axis,
    y_axis: y_axis,
  };

  if (z_axis) {
    payload.z_axis = z_axis;
  }

  const response = await client.post<RoutineDataRespType>(
    "/vi/get-routine-data",
    payload
  );
  return response.data;
}

export function useRoutineData(
  routine_id: string[],
  routine_type?: PASRoutineNamesType,
  x_axis?: string,
  y_axis?: string,
  z_axis?: string
) {
  return useQuery<RoutineDataRespType>({
    queryKey: [
      "vi",
      "routine-details",
      routine_id,
      routine_type,
      x_axis,
      y_axis,
      z_axis,
    ],
    queryFn: () =>
      getRoutineData(routine_id, routine_type!, x_axis!, y_axis!, z_axis),
    enabled:
      !!routine_type &&
      !!routine_id &&
      routine_id.length > 0 &&
      !!x_axis &&
      !!y_axis,
  });
}

// react query hook to get filtered routines

export function useFilteredRoutines(request?: FilterFieldsPASReqType) {
  return useQuery({
    queryKey: ["vi", "filtered-routines", request],
    queryFn: () => getFilteredRoutines(request!),
    enabled: !!request || !!(request && Object.keys(request!).length > 0),
  });
}

export function useVIUniqueFieldValues(
  filter?: FilterFieldsPASTypes[],
  start_date?: Date,
  end_date?: Date
) {
  return useQuery({
    queryKey: ["vi", "unique-field-values", filter, start_date, end_date],
    queryFn: () => getVIUniqueFieldValues(filter, start_date, end_date),
    // enabled: !!filter || !!(filter && filter.length > 0),
  });
}
