import { BasicStatusType } from "@domin-frontend/domin-ui/dist/esm/types";

export function calcLandStatus({
    current,
    lowerLimit,
    upperLimit,
  }: {
    current: number | undefined;
    lowerLimit?: number | undefined;
    upperLimit?: number | undefined;
  }): BasicStatusType {
    if (
      (lowerLimit && current && current < lowerLimit) ||
      current === undefined
    ) {
      return "danger";
    } else if (upperLimit && current && current > upperLimit) {
      return "pending";
    } else if (!lowerLimit && !upperLimit) {
      return "pending";
    } else {
      return "success";
    }
  }