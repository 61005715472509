import { LineChart, RadioButton } from "@domin-frontend/domin-ui";
import { MultilineType } from "@domin-frontend/domin-ui/dist/esm/types";
import { RoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types/testTypes";
import { memo } from "react";
import { xLabelMap, yLabelMap } from "./labelFormatMap";
import { useSearchParams, useLocation } from "react-router-dom";
import { calculateChartRange } from "./SinglePASChart";
import { calculateRangePercentage } from "helpers/functions/shared/calculateRangePerct";

const MemoLineChart = memo(LineChart);

interface FlowDemandChartProps {
  data: MultilineType;
  type: RoutineNamesType;
  guides?: MultilineType;
  fullScreenSettings?: {
    margins?: { top?: number; bottom?: number; left?: number; right?: number };
  };
  percentage: number;
  setPercentage?: (value: number) => void;
  guideArea?: boolean;
  hideSettings?: boolean;
  borders?: {
    top?: boolean;
    bottom?: boolean;
    left?: boolean;
    right?: boolean;
  };
}

export default function FlowDemandChart({
  data,
  type,
  guides,
  fullScreenSettings,
  percentage: displayPercentage = 5,
  setPercentage: setDisplayPercentage,
  guideArea = true,
  hideSettings = false,
  borders,
}: FlowDemandChartProps) {
  // get the search params for the view
  const [searchParams] = useSearchParams();
  const isOverview = searchParams.get("view") === "overview";
  const { pathname } = useLocation();
  const isValveCompare = pathname.includes("valve-compare");

  const chartRange = calculateChartRange(data);

  return (
    <>
      {!isOverview && !isValveCompare && setDisplayPercentage && (
        <div className="flex h-6 p-4 w-full justify-end gap-2">
          <RadioButton
            checked={displayPercentage === 5}
            onClick={
              setDisplayPercentage ? () => setDisplayPercentage(5) : undefined
            }
            name="5%"
          />
          <RadioButton
            checked={displayPercentage === 100}
            onClick={
              setDisplayPercentage ? () => setDisplayPercentage(100) : undefined
            }
            name="100%"
          />
        </div>
      )}
      {displayPercentage === 5 ? (
        <MemoLineChart
          data={data}
          settings={{
            labels: { x: xLabelMap[type], y: yLabelMap[type] },
            focusPoints: { numberOfPoints: 2, type: "delaunay" },
            zoom: { maxScale: 100, minScale: 0.05 },
            limits: {
              xMin: -5.5,
              xMax: 5.5,
              yMin: -calculateRangePercentage(data, 5),
              yMax: calculateRangePercentage(data, 5),
            },
            limitsArea:
              guideArea && guides
                ? {
                    upper_limit: {
                      line: guides?.upper_limit!,
                      axis: "y",
                      type: "max",
                      colour: "danger",
                    },
                    lower_limit: {
                      line: guides?.lower_limit!,
                      axis: "y",
                      type: "min",
                      colour: "danger",
                    },
                  }
                : undefined,
            guides: guideArea ? undefined : guides,
            gridline: true,
            fullscreen: fullScreenSettings,
            zeroGuides: { center: !isOverview },
            hideSettings: hideSettings,
            borders: borders,
          }}
        />
      ) : (
        <LineChart
          data={data}
          settings={{
            labels: { x: xLabelMap[type], y: yLabelMap[type] },
            guides: guides,
            focusPoints: { numberOfPoints: 2, type: "delaunay" },
            gridline: true,
            limits: {
              xMin: -100.5,
              xMax: 100.5,
              yMin: -chartRange.y ?? undefined,
              yMax: chartRange.y ?? undefined,
            },
            fullscreen: fullScreenSettings,
            zeroGuides: { center: !isOverview },
            hideSettings: hideSettings,
            borders: borders,
          }}
        />
      )}
    </>
  );
}
