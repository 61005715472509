import { AuthTemplate } from "@domin-frontend/domin-ui";
import { ToastContainer } from "react-toastify";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import VTPNav from "./VTPNav";

export default function VTPLayout() {
  const { pathname } = useLocation();
  if (pathname === "/") return <Navigate to="/pas/dashboard" />;

  return (
    <AuthTemplate
      className="!pt-0"
      navComponent={<VTPNav />}
      breadcrumbs={false}
    >
      <Outlet />
      <ToastContainer
        position="top-right"
        newestOnTop={false}
        rtl={false}
        theme="light"
        limit={5}
        closeOnClick={true}
        toastStyle={{ borderRadius: "0px" }}
      />
    </AuthTemplate>
  );
}
