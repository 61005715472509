import { Button, Icon, Modal } from "@domin-frontend/domin-ui";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
interface ShareCompareModalProps {
  setShowPopupModal: (showPopupModal: boolean) => void;
  message?: string;
}
export default function ShareModal({
  setShowPopupModal,
  message,
}: ShareCompareModalProps) {
  const [copied, setCopied] = useState("");
  const [location, setLocation] = useState(window.location.href);
  useEffect(() => {
    if (copied !== "") {
      setLocation(window.location.href);
    }
  }, [copied]);

  return (
    <Modal
      className="!z-50"
      title="Share link"
      onCloseClick={() => setShowPopupModal(false)}
    >
      <div className="flex flex-col items-center gap-4">
        <p className="text-center">{message ?? "Share this link"}</p>
        <div className="flex items-center gap-4">
          <input
            name="share-link"
            className="border border-primary-600 px-2 py-1 truncate max-w-md h-9 cursor-pointer"
            value={location}
            disabled
            onClick={() => {
              setCopied(location);
              navigator.clipboard.writeText(location);
              toast.success("Link copied to clipboard");
            }}
          />
          <Button
            variant="secondary"
            onClick={() => {
              navigator.clipboard.writeText(location);
              setCopied(location);
              toast.success("Link copied to clipboard");
            }}
          >
            {copied === location ? "Copied" : "Copy"}
          </Button>
        </div>

        {/* social media links */}
        <div className="flex gap-4 items-center">
          {/* share in teams */}
          <div
            className="teams-share-button hover:bg-neutral-100 rounded-full p-1 cursor-pointer flex gap-2 items-center justify-items-center"
            data-href={location}
          >
            <a
              target="ms-teams-share-popup"
              onClick={() => {
                window.open(
                  location,
                  "ms-teams-share-popup",
                  "width=600,height=600",
                );
                return false;
              }}
              href={`https://teams.microsoft.com/share?href=${location}`}
            >
              Share on Teams
            </a>
            <Icon name="teams" />
          </div>
        </div>
      </div>
    </Modal>
  );
}
