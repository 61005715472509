import { Button, Icon } from "@domin-frontend/domin-ui";
import { routineNameMapper } from "@domin-frontend/domin-ui/dist/esm/helpers/routineNamesMap";
import { RoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types/testTypes";
import { formatDate } from "helpers/functions/shared/formatDate";
import { ReactNode } from "react";
import { TestSettingsType } from "types/responseTypes";

export function makeChartTitle(
  routine: RoutineNamesType,
  settings?: TestSettingsType,
  displayPercentage?: number,
  suffixString?: string,
): string {
  const name = routineNameMapper(routine);
  let prefix = "";
  let suffix = "";

  if (settings) {
    if (
      ["flow_angle", "flow_demand", "leakage", "slow_sine"].includes(routine) &&
      settings["P*"]
    ) {
      suffix = ` at ${settings["P*"]} bar`;
    }
    if (routine === "step_response" && settings["Test Pressures"]) {
      suffix = ` at ${settings["Test Pressures"]} bar`;
    }
    if (routine === "frequency_response" && settings["Min Test Pressure"]) {
      suffix = ` at ${settings["Min Test Pressure"]} bar`;
    }
    if (
      ["pressure_gain", "leakage"].includes(routine) &&
      settings["Pressure"]
    ) {
      suffix = ` at ${settings["Pressure"]} bar`;
      if (routine === "leakage") {
        suffix = ` vs Demand ${suffix}`;
      }
    }
  }

  if (suffixString) {
    suffix += suffixString;
  }
  if (displayPercentage && routine === "flow_demand") {
    prefix = ` ${displayPercentage}% `;
  }

  return `${prefix}${name}${suffix}`;
}

const noCSVRoutines: RoutineNamesType[] = [
  "motor_calibration",
  "pressure_gain",
];

// TODO: Make this a map of routine to secondary data to make button reusable for all routines
const toggleDataRoutines: RoutineNamesType[] = [
  "bias_position"
]

interface Props {
  routineId: string;
  type: RoutineNamesType;
  lastModified: string;
  testSettings?: TestSettingsType;
  displayPercentage?: number;
  displayRawData?: { csv?: boolean; json?: boolean };
  tag?: ReactNode;
  suffixString?: string;
  toggleSecondaryDataState?: boolean;
  toggleSecondaryData?: () => void;
}
export default function TitleChartArea({
  routineId,
  type,
  lastModified,
  testSettings,
  displayPercentage,
  displayRawData = { csv: true, json: true },
  tag,
  suffixString,
  toggleSecondaryDataState,
  toggleSecondaryData,
}: Props) {
  return (
    <div className="w-full flex justify-between items-center p-4">
      <p>{formatDate(lastModified)}</p>
      <h3 className="text-lg ">
        {makeChartTitle(type, testSettings, displayPercentage, suffixString)}
      </h3>
      <div className="flex gap-4 min-w-[10rem]">
        {toggleDataRoutines.includes(type) &&(
          <Button
          variant="secondary"
          className="hover:stroke-neutral-0 stroke-primary-600"
          onClick={toggleSecondaryData}
        >
          {toggleSecondaryDataState ? "Hide z" : "Show z"}
          </Button>
        )}
        {!noCSVRoutines.includes(type) && displayRawData?.csv && (
          <Button
            variant="secondary"
            className="hover:stroke-neutral-0 stroke-primary-600"
          >
            <a
              href={`${process.env.REACT_APP_API_URL}raw-data/get-routine?id=${routineId}&type=${type}&file_type=csv`}
              target="_blank"
              rel="noreferrer"
              className="flex gap-2 items-center justify-between"
            >
              CSV
              <Icon name="download" className="stroke-[inherit]" />
            </a>
          </Button>
        )}
        {tag}
        {displayRawData?.json && (
          <Button
            variant="secondary"
            className="!stroke-primary-600 hover:!stroke-neutral-0 "
          >
            <a
              href={`${process.env.REACT_APP_API_URL}raw-data/get-routine?id=${routineId}&type=${type}&file_type=json`}
              target="_blank"
              rel="noreferrer"
              className="flex gap-2 items-center justify-between"
            >
              JSON
              <Icon name="download" className="stroke-[inherit]" />
            </a>
          </Button>
        )}
      </div>
    </div>
  );
}
