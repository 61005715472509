import {
  CardLayout,
  SingleSelect,
} from "@domin-frontend/domin-ui";
import TableInfoHeader from "components/SharedComponents/TableInfoHeader";
import { formatDate } from "helpers/functions/shared/formatDate";
import useSetDocumentName from "helpers/hooks/shared/useSetDocumentName";
import { useEdgeMatchingInfo, usePostMatchStatusData } from "queries/EMQueries";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import InfoRow from "tempComponents/infoTable/InfoRow";
import InfoTableCard from "tempComponents/infoTable/InfoTableCard";
import InfoWrapper from "tempComponents/infoTable/InfoWrapper";
import { withTransaction } from "@elastic/apm-rum-react";
import { MatchStatusType } from "types/responseTypes";
import MatchStatusCell from "../EdgeMatching/MatchStatusCell";
import { useMsal } from "@azure/msal-react";


function EMManifoldView() {
  const [infoOpen, setInfoOpen] = useState(true);
  const [prodOpen, setProdOpen] = useState(true);

  const { manifold_spool, routine } = useParams();
  const navigate = useNavigate();
  const urlManifold = manifold_spool?.split("-")[0];
  const urlSpool = manifold_spool?.split("-")[1];
  const matchStatusList: MatchStatusType[] = ["remeasure", "undercut", "overcut", "matched", "validated", "scrapped", "investigation"]

  const { accounts } = useMsal();
  const email = accounts[0].username.toLocaleLowerCase();
  const val_users = process.env.REACT_APP_VAL_USERS?.split(",") ?? [];
  const isVALUser = val_users.includes(email);

  const { data: manifoldData } = useEdgeMatchingInfo(urlManifold, urlSpool);
  const [newMatchStatus, setNewMatchStatus] = useState<MatchStatusType>();
  const [override, setOverride] = useState(false);

  const { mutate, } = usePostMatchStatusData();


  // check if url params contain a routine name, if not, check if manifold has a routine and navigate to that routine
  useEffect(() => {
    if (!routine && manifoldData) {
      if (manifoldData.front_edge) {
        navigate(`front_edge`);
      } else if (manifoldData.back_edge) {
        navigate(`back_edge`);
      } else if (manifoldData.flow_angle) {
        navigate(`flow_angle`);
      } else if (manifoldData.leakage) {
        navigate(`leakage`);
      }
    }
  }, [manifoldData, navigate, routine]);

  useEffect(() => {
    if (newMatchStatus) {
      console.log(newMatchStatus);
      mutate({
        manifold_spool: `${urlManifold}-${urlSpool}`,
        matchStatus: newMatchStatus,
      });
    }
  }, [newMatchStatus, mutate, urlManifold, urlSpool]);

  // update document name
  useSetDocumentName(`EM ${urlManifold}-${urlSpool}`);

  return (
    <CardLayout title={`Edge Matching: ${urlManifold}-${urlSpool}`}>
      <div className="flex w-full border-b border-primary-600 px-4" />
      <div
        className={`relative grid grid-cols-[24px,_1fr]  xl:grid-cols-[minmax(2rem,auto),_1fr] gap-4 pt-2`}
      >
        <InfoWrapper
          infoOpen={infoOpen}
          setInfoOpen={setInfoOpen}
          title="Manifold Info"
        >
          <InfoTableCard>
            <InfoRow name="Manifold" value={urlManifold} />
            <InfoRow name="Spool" value={urlSpool} />
            {manifoldData && (
              <>
                {manifoldData.variant && (
                  <InfoRow name="Variant" value={manifoldData?.variant} />
                )}
                {manifoldData.operator && (
                  <InfoRow name="Operator" value={manifoldData?.operator} />
                )}
                {manifoldData.last_modified && (
                  <InfoRow
                    name="Last Modified"
                    value={formatDate(manifoldData?.last_modified)}
                  />
                )}
              </>
            )}
          </InfoTableCard>
          <TableInfoHeader
            onClick={() => setProdOpen(!prodOpen)}
            isOpen={prodOpen}
          >
            Matching Information
          </TableInfoHeader>
          <div className={prodOpen ? "block" : "hidden"}>
            {manifoldData?.base_sku && manifoldData?.match_status ? (
              <div className="w-full">
                <InfoRow name="Base SKU" value={manifoldData.base_sku}/>
                {isVALUser ? (
                  <>
                    {(!override && manifoldData.match_status) ||
                    newMatchStatus ? (
                      <>
                        <div>
                          <tr className="text-sm flex gap-2 justify-between">
                            <td className="py-[6px]">Match Status</td>
                            <td className="py-[6px] text-end cursor-pointer" 
                              onClick={() => {
                                setOverride(true)
                              }}>
                              <MatchStatusCell
                                value={
                                  override
                                    ? newMatchStatus
                                    : manifoldData.match_status
                                }
                              />
                            </td>
                          </tr>
                        </div>
                      </>
                    ) : (
                      <div>
                        <SingleSelect
                          onChange={(e) => {
                            setNewMatchStatus(e.value as MatchStatusType);
                            setOverride(true)
                          }}
                          options={matchStatusList.map((o) => {
                            return { value: o, label: o };
                          })}
                          value=""
                          placeholder={"Please Select a Status"}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <tr className="text-sm flex gap-2 justify-between">
                    <td className="py-[6px]">Match Status</td>
                    <td className="py-[6px] text-end">
                      <MatchStatusCell
                        value={
                          override ? newMatchStatus : manifoldData.match_status
                        }
                      />
                    </td>
                  </tr>
                )}
              </div>
            ) : (
              <InfoRow name="Requirement" value="Select a Base SKU" />
            )}
          </div>
        </InfoWrapper>
        <Outlet />
      </div>
    </CardLayout>
  );
}

export default withTransaction("EMManifoldView", "page")(EMManifoldView);
