import { CardLayout, Loading } from "@domin-frontend/domin-ui";
import { useCustomerReportData } from "queries/PASQueries";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import ReportEditModal from "./EditModal";
import { PDFFrame } from "./PDFFrame";
import { useReactToPrint } from "react-to-print";
import { getToday } from "helpers/functions/shared/formatDate";
import IconButton from "tempComponents/IconButton";
import {
  PASRoutineNamesType,
  RoutineNamesType,
} from "@domin-frontend/domin-ui/dist/esm/types";

const customerReportRoutines: PASRoutineNamesType[] = [
  "flow_demand",
  "frequency_response",
  "step_response",
  "pressure_gain",
  "leakage",
];

export default function CustomerReport() {
  const { accounts } = useMsal();
  const [date, setDate] = useState<string>(getToday());
  const [user, setUser] = useState({
    name: accounts[0].name,
    email: accounts[0].username.toLocaleLowerCase(),
  });
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const { serialNumber } = useParams();
  const { data: valve } = useCustomerReportData(serialNumber);
  const [routines, setRoutines] = useState<
    { routineId: string; routineType: RoutineNamesType }[]
  >([]);
  const [displayTests, setDisplayTests] = useState<
    { routineType: RoutineNamesType; show: boolean }[]
  >(
    customerReportRoutines.map((routineType) => {
      return { routineType: routineType, show: true };
    })
  );
  const ref = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: `Customer Report: ${valve?.serial_number}`,
  });

  useEffect(() => {
    if (valve?.tests) {
      //  check the latest routines in the latest test and set them to state
      const latestTest = valve.tests[valve.tests.length - 1];
      const latestRoutines = latestTest.routines;

      // format to match the useRoutinesData in an array with objects with routineId and routineType, latestRoutines is an object with routineType as key and array of routines as value

      if (latestRoutines) {
        const routines = customerReportRoutines
          .filter((routineType) => latestRoutines?.[routineType]?.[0])
          .map((routineType) => {
            return {
              routineId: latestRoutines?.[routineType]?.[0].routine_id,
              routineType: routineType,
            };
          });
        setRoutines(routines);
        setDisplayTests(
          routines.map((routine) => {
            return { routineType: routine.routineType, show: true };
          })
        );
      }
    }
  }, [valve, serialNumber]);

  useEffect(() => {
    if (valve?.deal_owner_name && valve?.deal_owner_email) {
      setUser({ name: valve.deal_owner_name, email: valve.deal_owner_email });
    }
  }, [valve]);

  return (
    <CardLayout className="h-[84vh]">
      {isSettingsOpen && (
        <ReportEditModal
          setIsOpen={setIsSettingsOpen}
          user={user}
          setUser={setUser}
          displayTests={displayTests}
          setDisplayTests={setDisplayTests}
          date={date}
          setDate={setDate}
        />
      )}
      {!valve ? (
        <Loading />
      ) : (
        <div className="relative flex gap-4 py-4 h-[80vh] overflow-scroll bg-neutral-100 justify-between">
          <div
            className="sticky flex gap-2 top-2 left-4 justify-center items-center bg-neutral-0 rounded-full h-8 w-8 z-10"
            onMouseEnter={() => setIsInfoOpen(true)}
            onMouseLeave={() => setIsInfoOpen(false)}
          >
            <span className="text-2xl text-primary-600">i</span>
            {isInfoOpen && (
              <div className="absolute top-0 left-0 bg-neutral-0 shadow rounded-sm flex flex-col min-w-[24rem] z-10">
                <div className="flex flex-col gap-2 p-4">
                  <p className="text-primary-700 font-medium">
                    Customer Report
                  </p>
                  <p className="text-sm">
                    This report is generated from the latest test results for
                    this valve. The report can be edited to include different
                    tests and information.
                  </p>
                  <p>
                    To generate the PDF click on the download icon and that will
                    open the browser's print dialog. If the PDF is not formatted
                    correctly, please:
                  </p>
                  {/* add a list to explain that the user has to choose "save as PDF" and A4*/}
                  <ul className="list-disc list-inside">
                    <li>Choose "Save as PDF"</li>
                    <li>Choose A4 as the paper size</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          <PDFFrame
            ref={ref}
            valve={valve}
            user={{ name: user.name, email: user.email }}
            routines={routines}
            showRoutines={displayTests}
            date={date}
          />
          <div className="sticky flex gap-2 top-2 right-4">
            <IconButton onClick={handlePrint} icon="download" />
            <IconButton onClick={() => setIsSettingsOpen(true)} icon="edit" />
          </div>
        </div>
      )}
    </CardLayout>
  );
}
