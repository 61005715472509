import { RoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types/testTypes";
import { Dispatch, SetStateAction, useEffect } from "react";
import {
  CompareDropdownItemsType,
  FilterFieldsPASObjectType,
  QueriesShortType,
} from "types/valveIntelligenceTypes";
import routineBiMap from "./maps/routineTypeBiMap";
import { paramBiMap } from "./maps/searchParamsBiMap";
import { formatValuesToLong } from "./maps/getValueHelper";

/**
 * Format the routine entries url search params into an array the format for ValveCompare state
 * @param routineEntries - the routine entries from the url search params
 * @returns - the formatted routine entries
 */
function formatRoutineEntries(
  routineEntries: [string, string][]
): CompareDropdownItemsType[][] | undefined {
  if (routineEntries.length === 0) {
    return;
  }

  const selectedRoutinesFromQuery: CompareDropdownItemsType[][] = [];
  // loop through the routineEntries and add them to the selectedRoutinesFromQuery state
  for (const [key, value] of routineEntries) {
    const [column, row] = key.split("_").map((part) => +part.split("-")[1]);
    // if the column or row is not a number, return
    if (Number.isNaN(column) || Number.isNaN(row)) {
      return;
    }
    // if the column is not in the selectedRoutinesFromQuery state, add it
    const [routineId, shortRoutine, unixTime] = value.split("_");

    const lastModified = new Date(+unixTime * 60000)
      .toISOString()
      .replace(/\.\d{3}Z$/, "Z");

    const longRoutine = routineBiMap.get(shortRoutine as RoutineNamesType);

    // check if the routine is already in the selectedRoutines state in the same column and row
    if (
      selectedRoutinesFromQuery[column]?.[row]?.routine_id === routineId &&
      selectedRoutinesFromQuery[column]?.[row]?.routine_type === longRoutine
    ) {
      return;
    }
    if (!selectedRoutinesFromQuery?.[column]) {
      selectedRoutinesFromQuery[column] = [];
    }

    if (selectedRoutinesFromQuery?.[column]?.[row]) {
      selectedRoutinesFromQuery[column][row] = {
        routine_id: routineId,
        routine_type: longRoutine as RoutineNamesType,
        last_modified: lastModified,
      };
    } else if (row !== undefined) {
      selectedRoutinesFromQuery[column].push({
        routine_id: routineId,
        routine_type: longRoutine as RoutineNamesType,
        last_modified: lastModified,
      });
    }
  }

  return selectedRoutinesFromQuery;
}

/**
 * Format the filter entries url search params into an object the format for ValveCompare state
 * @param filterEntries - the filter entries from the url search params
 * @returns - the formatted filter entries
 */
function formatFilterEntries(
  filterEntries: [string, string][]
): FilterFieldsPASObjectType | undefined {
  if (filterEntries.length === 0) {
    return;
  }

  const filterQueriesFromQuery: FilterFieldsPASObjectType = {};

  for (const [key, value] of filterEntries) {
    // column is the first number in the key, it doesn't have an hyphen
    const column = +key.charAt(key.length - 1);
    if (Number.isNaN(column) || column > 3 || column < 1) {
      return;
    }
    const shortKey = key.slice(0, -1) as QueriesShortType;

    const longKey = paramBiMap.getValue(shortKey);

    if (!longKey) {
      return;
    }
    const longValue = formatValuesToLong(longKey, value);

    if (
      !filterQueriesFromQuery[`c${column}` as keyof FilterFieldsPASObjectType]
    ) {
      filterQueriesFromQuery[`c${column}` as keyof FilterFieldsPASObjectType] =
        {};
    }

    if (longKey) {
      filterQueriesFromQuery[`c${column}` as keyof FilterFieldsPASObjectType] =
        {
          ...filterQueriesFromQuery[
            `c${column}` as keyof FilterFieldsPASObjectType
          ],
          [longKey]: longValue,
        };
    }
  }
  return filterQueriesFromQuery;
}

/**
 * format ValveCompare state for routines and filter queries from the url search params
 * @param searchParams - the url search params
 * @param setSelectedRoutines - the setSelectedRoutines function from ValveCompare state
 * @param setFilterQueries - the setFilterQueries function from ValveCompare state
 */
export function useUpdateFromSearchParams({
  searchParams,
  setSelectedRoutines,
  setFilterQueries,
}: {
  searchParams: URLSearchParams;
  setSelectedRoutines: Dispatch<SetStateAction<CompareDropdownItemsType[][]>>;
  setFilterQueries: Dispatch<SetStateAction<FilterFieldsPASObjectType>>;
}) {
  useEffect(() => {
    // Get the selected routines from the search params
    const entries = Array.from(searchParams.entries());

    const queriesEntries = entries.filter(
      ([key]) => key.includes("c-") === false && key.includes("_r-") === false
    );
    const routineEntries = entries.filter(
      ([key]) => key.includes("c-") && key.includes("_r-")
    );

    const formattedRoutineEntries = formatRoutineEntries(routineEntries);
    const formattedFilterEntries = formatFilterEntries(queriesEntries);

    if (formattedFilterEntries) {
      setFilterQueries(formattedFilterEntries);
    }
    if (formattedRoutineEntries) {
      setSelectedRoutines(formattedRoutineEntries);
    }
  }, [searchParams, setSelectedRoutines, setFilterQueries]);
}
