import { RoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types/testTypes";

type labelStatus = {
  [key in RoutineNamesType]: string;
};

export const xLabelMap: labelStatus = {
  motor_calibration: "Theta Offset (deg)",
  flow_angle: "Angle (deg)",
  flow_demand: "Demand (%)",
  step_response: "Time (ms)",
  frequency_response: "Frequency (Hz)",
  leakage: "Demand (%)",
  pressure_gain: "Demand (%)",
  slow_sine: "Demand (%)",
  back_edge: "Angle (deg)",
  front_edge: "Angle (deg)",
  bias_position: "Time (s)",
  fail_safe_position_leakage: "Measurement No.",
};

export const yLabelMap: labelStatus = {
  motor_calibration: "Motor Current (mA)",
  flow_angle: "Flow (LPM)",
  flow_demand: "Flow (LPM)",
  step_response: "Demand/Feedback (%)",
  frequency_response: "Gain (dB)",
  leakage: "Leakage (LPM)",
  pressure_gain: "Pressure Gain (%)",
  slow_sine: "Flow (LPM)",
  back_edge: "Flow (LPM)",
  front_edge: "Flow (LPM)",
  bias_position: "Flow (LPM)",
  fail_safe_position_leakage: "Leakage Flow (LPM)",
};
