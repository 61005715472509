import { RoutineFiltersType } from "types/requestTypes";
import { StyleMapType } from "@domin-frontend/domin-ui/dist/esm/types";

export const displayFilterMap: StyleMapType<RoutineFiltersType, string> = {
  routine_type: "Routine Type",
  valve_type: "Valve Type",
  start_date: "Start Date",
  end_date: "End Date",
  rig: "Rig",
  serial_number: "Serial Number",
  customer: "Customer",
  status: "Status",
  test_id: "Test ID",
  routine_id: "Routine ID",
  total_time: "Total Time",
  sku: "SKU",
};

export function displayFilterLabel(filter: RoutineFiltersType) {
  if (!displayFilterMap[filter]) {
    return filter;
  }
  return displayFilterMap[filter];
}
