import { Tag } from "@domin-frontend/domin-ui";
import { BasicStatusType } from "@domin-frontend/domin-ui/dist/esm/types";
import { PasListItemType, ResultStatusType, RoutineStatusType } from "../../../types/responseTypes";
import { CellContext } from "@tanstack/react-table";

export type ResultStatusStyleType = {
  [key in ResultStatusType ]: BasicStatusType;
};

export const tagResultStatus: ResultStatusStyleType = {
    pass: "success",
    fail: "danger",
    incomplete: "pending",
};

function formatTagValue(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

function getHyperflowResultStatus(statuses: RoutineStatusType[]){
    if (statuses.every(status => status === "pass")) {
        return "pass"
    } else if (statuses.some(status => status === undefined)) {
        return "incomplete"
    } else {
        return "fail"
    }
}
interface Props {
    cell: CellContext<PasListItemType, ResultStatusType>;
}

export default function ResultStatusCell({cell}: Props) {
    // TODO: Currently hardcoded pass/fail for Hyperflow change to use filter
    const routine_statuses = {
        "MC": cell.row.original.motor_calibration?.status,
        "FA": cell.row.original.flow_angle?.status,
        "FD": cell.row.original.flow_demand?.status,
        "SR": cell.row.original.step_response?.status,
        "FR": cell.row.original.frequency_response?.status,
        "L": cell.row.original.leakage?.status,
        "PG": cell.row.original.pressure_gain?.status,
        "BP": cell.row.original.bias_position?.status,
    };
    const statuses = Object.values(routine_statuses);
    const value = getHyperflowResultStatus(statuses);

  return (
    <Tag status={tagResultStatus[value as ResultStatusType]} dominColours>
      {formatTagValue(value)}
    </Tag>
  );
}
