import { TBody, THead, Table, Tag, Td, Th, Tr } from "@domin-frontend/domin-ui";
import { EMRoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { formatDate } from "helpers/functions/shared/formatDate";
import { Link } from "react-router-dom";
import { EMListItemType } from "types/EMTypes";
import MatchStatusCell from "./MatchStatusCell";

interface Props {
  data: EMListItemType[];
  isLoading: boolean;
}

const columnHelper = createColumnHelper<EMListItemType>();

const testNames: EMRoutineNamesType[] = [
  "front_edge",
  "back_edge",
  "flow_angle",
  "leakage",
];

const columns = [
  columnHelper.accessor("manifold_number", {
    header: "Manifold",
    cell: (row) => row.getValue(),
  }),
  columnHelper.accessor("spool_number", {
    header: "Spool",
    cell: (row) => row.getValue(),
  }),
  columnHelper.accessor("last_modified", {
    header: "Last Modified",
    cell: (row) => formatDate(row.getValue()),
  }),
  columnHelper.accessor("match_status", {
    header: "Status",
    cell: (row) => {
      const value = row.getValue();
      return (
          <MatchStatusCell value={value} />
      );
    },
  }),
  columnHelper.accessor("variant", {
    header: "Variant",
    cell: (row) => row.getValue(),
  }),
  columnHelper.accessor("front_edge", {
    header: "FE Measurement",
    cell: (row) => {
      const value = row.getValue()?.count;
      return (
        <Tag status={value ? "success" : "danger"}>
          {value ? `Completed (${value})` : "Incomplete"}
        </Tag>
      );
    },
  }),
  columnHelper.accessor("back_edge", {
    header: "BE Measurement",
    cell: (row) => {
      const value = row.getValue()?.count;
      const isS4Pro = row.row.original.variant === "S4 Pro";
      if (isS4Pro) {
        return (
          <Tag status={value ? "success" : "danger"}>
            {value ? `Completed (${value})` : "Incomplete"}
          </Tag>
        );
      }
    },
  }),
  columnHelper.accessor("flow_angle", {
    header: "Flow vs. Angle",
    cell: (row) => {
      const value = row.getValue()?.count;
      return (
        <Tag status={value ? "success" : "danger"}>
          {value ? `Completed (${value})` : "Incomplete"}
        </Tag>
      );
    },
  }),
  columnHelper.accessor("leakage", {
    header: "Leakage",
    cell: (row) => {
      const value = row.getValue()?.count;
      return (
        <Tag status={value ? "success" : "danger"}>
          {value ? `Completed (${value})` : "Incomplete"}
        </Tag>
      );
    },
  }),
];

export default function EMTable({ data }: Props) {
  const { getHeaderGroups, getRowModel } = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table className="mt-2">
      {getHeaderGroups().map((headerGroup, i) => (
        <THead key={`head-${headerGroup.id}-${i}`} className="h-6">
          <Tr>
            {headerGroup.headers.map((header, i) => (
              <Th key={`${header.id}-${i}`} className="text-base">
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </Th>
            ))}
          </Tr>
        </THead>
      ))}
      <TBody>
        {getRowModel().rows.map((row, i) => (
          <Tr key={`body-${row.id}-${i}`} className="relative">
            {row.getVisibleCells().map((cell, i) => (
              <Td key={`${cell.id}-${i}`}>
                <Link
                  className="whitespace-nowrap"
                  to={`/edge-matching/${cell.row.getValue(
                    "manifold_number"
                  )}-${cell.row.getValue("spool_number")}${
                    testNames.includes(cell.column.id as EMRoutineNamesType)
                      ? `/${cell.column.id}`
                      : ""
                  }`}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Link>
              </Td>
            ))}
          </Tr>
        ))}
      </TBody>
    </Table>
  );
}
