import { SelectOptionType } from "@domin-frontend/domin-ui/dist/esm/components/forms/SingleSelect";
import { formatDate } from "./formatDate";
import { FilterPASRoutinesRespType } from "types/responseTypes";

export function formatRoutineOptions(routines: FilterPASRoutinesRespType[]) {
  const routinesArray: SelectOptionType[] | [] =
    routines.map((item) => {
      return {
        label: `${
          item.last_modified ? formatDate(item.last_modified) + " - " : ""
        } ${item.routine_type}`,
        value: item.routine_id,
        statusTag: {
          status: item.status
            ? item.status === "pass"
              ? "success"
              : item.status === "fail"
                ? "danger"
                : item.status === "pass by default"
                  ? "pending"
                  : "idle"
            : "idle",
          label: item.status || "idle",
        },
      };
    }) ?? [];
  return routinesArray;
}
