import {
  SelectOptionType,
  SingleSelect,
} from "@domin-frontend/domin-ui/dist/esm/components/forms/SingleSelect";

interface DisplayRoutineRowProps {
  options: SelectOptionType[];
  name?: string;
  value?: string;
  onChange: ({ value, label }: { value: string; label: string }) => void;
  editable: boolean;
  setEdit: () => void;
}

export default function DisplayRoutineRow({
  options,
  value,
  onChange,
  editable,
  setEdit,
  name,
}: DisplayRoutineRowProps) {
  return (
    <SingleSelect
      name="routines"
      options={options.map(function (option) {
        const selectOptions: SelectOptionType = {
          value: option.value,
          label: option.label,
          statusTag: option.statusTag
            ? {
                status: option.statusTag.status,
                label: option.statusTag.label,
              }
            : undefined,
        };
        return selectOptions;
      })}
      value={name ?? value ?? ""}
      onChange={onChange}
      onClickDisabled={() => setEdit()}
      disabled={!editable}
    />
  );
}
