import { forwardRef, useEffect, useState } from "react";
import {
  CustomerReportDataRespType,
  RoutineDataRespType,
} from "types/responseTypes";
import { ReactComponent as DominLogo } from "components/svg/domin_logo.svg";
import { ReactComponent as LogomarkBg } from "components/svg/logomark_background.svg";
import { ErrorMessage, Loading } from "@domin-frontend/domin-ui";
import { useRoutinesData } from "queries/PASQueries";
import { RoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types/testTypes";
import CustomerReportChart from "./CustomerReportChart";
import PageLayout from "./CRPageLayout";

interface PDFFrameProps {
  valve: CustomerReportDataRespType;
  user: { name?: string; email?: string };
  routines: {
    routineId: string;
    routineType: RoutineNamesType;
  }[];
  showRoutines: {
    routineType: RoutineNamesType;
    show: boolean;
  }[];
  date: string;
  pageGap?: number;
}

export const PDFFrame = forwardRef<HTMLDivElement, PDFFrameProps>(
  ({ valve, user, routines, showRoutines, date, pageGap }, ref) => {
    const [localRoutines, setLocalRoutines] = useState<RoutineDataRespType[]>(
      [],
    );

    const {
      data: routinesData,
      isLoading: isRoutinesLoading,
      isError: isRoutinesError,
    } = useRoutinesData(routines, true);

    useEffect(() => {
      if (routinesData) {
        // routines data is an array of routines as RoutineDataRespType and is has to be formatted to match the r state
        setLocalRoutines(
          routinesData.filter((routine) =>
            showRoutines.some(
              (showRoutine) =>
                showRoutine.routineType === routine.routine_type &&
                showRoutine.show,
            ),
          ),
        );
      }
    }, [routinesData, showRoutines]);

    return (
      <div className={`flex flex-col ${pageGap ? "gap-4" : "gap-0"}`} ref={ref}>
        {isRoutinesLoading ? (
          <Loading />
        ) : isRoutinesError ? (
          <ErrorMessage>Error Loading routines</ErrorMessage>
        ) : (
          <>
            <PageLayout>
              <div className="bg-primary-600 w-4 h-full" />
              <div className="w-full">
                {/* Header */}
                <div className="flex justify-between w-full items-center gap-4 p-4">
                  <DominLogo className="dark:!fill-neutral-900 !fill-neutral-900" />
                </div>
                {/* Body */}
                <div className="pt-28 px-20 flex flex-col gap-11">
                  <div className="flex flex-col gap-2">
                    <p className="text-4xl text-primary-700 font-medium">
                      {valve.customer}
                    </p>
                    <p className="text-lg text-neutral-700">
                      Order: {valve.order}
                    </p>
                    <p className="text-lg text-neutral-700">
                      Contact: {user.name}
                    </p>
                    <p className="text-lg text-neutral-700">
                      Email: {user.email}
                    </p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <p className="text-lg text-neutral-700">SKU: {valve.sku}</p>
                    <p className="text-lg text-neutral-700">Date: {date}</p>
                    <p className="text-lg text-neutral-700">
                      S/N: {valve.serial_number}
                    </p>
                  </div>
                </div>
                {/* dommin logomark at the right bottom */}
                <div className="absolute bottom-0 right-0">
                  <LogomarkBg />
                </div>
              </div>
            </PageLayout>

            {routinesData && (
              <>
                {localRoutines.find((r) => r.routine_type === "flow_demand") &&
                  showRoutines.some(
                    (routine) =>
                      routine.routineType === "flow_demand" && routine.show,
                  ) && (
                    <PageLayout footer page={1}>
                      <div className="w-full h-full flex flex-col gap-12 items-stretch justify-around py-[2cm]">
                        <CustomerReportChart
                          data={localRoutines.find(
                            (r) => r.routine_type === "flow_demand",
                          )}
                        />
                      </div>
                    </PageLayout>
                  )}

                {/* Go throw all the routines from routineData except flowDemand and add two routines max inside one PageLayout component */}
                {localRoutines &&
                  localRoutines
                    .filter((routine) => routine.routine_type !== "flow_demand")
                    .map((routine, index) => {
                      const lastRoutines = localRoutines.filter(
                        (routine) => routine.routine_type !== "flow_demand",
                      );
                      if (index % 2 === 0) {
                        return (
                          <PageLayout
                            key={index}
                            footer
                            page={
                              Math.floor(index / 2) +
                              (localRoutines.find(
                                (r) => r.routine_type === "flow_demand",
                              )
                                ? 2
                                : 1)
                            }
                          >
                            <div
                              className={`w-full h-full flex flex-col py-[2cm] ${
                                lastRoutines[index + 1] ? "justify-between" : ""
                              } gap-12`}
                            >
                              <CustomerReportChart data={routine} />
                              {lastRoutines[index + 1] && (
                                <CustomerReportChart
                                  data={lastRoutines[index + 1]}
                                />
                              )}
                            </div>
                          </PageLayout>
                        );
                      }
                      return null;
                    })}
              </>
            )}
          </>
        )}
      </div>
    );
  },
);
