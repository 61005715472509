import { Icon } from "@domin-frontend/domin-ui";

export interface InfoWrapperProps {
  title?: string;
  children: React.ReactNode;
  infoOpen: boolean;
  setInfoOpen: (value: boolean) => void;
}

export default function InfoWrapper({
  children,
  infoOpen,
  setInfoOpen,
  title,
}: InfoWrapperProps) {
  return (
    <div
      className={`xl:overflow-hidden absolute bg-neutral-0 shadow-[1px_0_4px_-2px_rgba(0,0,0,0.3)] xl:!shadow-none xl:z-auto pr-4 xl:!pr-0 
  ${
    infoOpen
      ? "relative min-w-fit xl:max-w-[20rem] lg:w-auto"
      : "absolute w-auto"
  }
`}
      style={
        infoOpen
          ? { transform: "translateY(0px) translateX(0px)" }
          : {
              transform: "translateY(48px) translateX(-40px) rotate(-90deg)",
            }
      }
    >
      {title && (
        <div
          className={`flex items-center justify-between gap-x-2 border-b border-primary-600 cursor-pointer ${
            infoOpen ? "py-2 px-1 w-auto" : "py-1 flex-row "
          }"`}
          onClick={() => setInfoOpen(!infoOpen)}
        >
          <div className="whitespace-nowrap">{title}</div>
          <div
            className={`transition-transform stroke-primary-600 w-4 h-4 rotate-0  ${
              infoOpen ? "rotate-90 " : "rotate-0 translate-y-2"
            }`}
          >
            <Icon name="show" className="w-full h-full " />
          </div>
        </div>
      )}
      <div
        className={`transition-opacity flex flex-col gap-y-4 ${
          infoOpen
            ? "scale-x-100 translate-x-0 opacity-100 h-auto sm:w-auto lg:w-60 2xl:w-80"
            : "translate-x-8 scale-x-0 w-0 h-0 opacity-0"
        }`}
      >
        {children}
      </div>
    </div>
  );
}
