import { Loading, Tag, Tooltip } from "@domin-frontend/domin-ui";
import {
  BasicStatusType,
  RoutineNamesType,
} from "@domin-frontend/domin-ui/dist/esm/types";
import { formatDate } from "helpers/functions/shared/formatDate";
import { routineShortMap } from "helpers/hooks/compareValveArea/maps/routineShorterNameMap";
import routineBiMap from "helpers/hooks/compareValveArea/maps/routineTypeBiMap";
import { useMediaQuery } from "react-responsive";
import { RoutineRespItemType, RoutineStatusType } from "types/responseTypes";

export interface RoutineItemProps<RoutineNames extends RoutineNamesType> {
  name: RoutineNames;
  numberOfRoutines: number;
  selected: boolean;
  setSelected: () => void;
  routineInfo?: RoutineRespItemType<RoutineNames>;
  isLoading?: boolean;
  displayedRoutines?: number;
}

export function formatDateHM(dateString?: string) {
  if (!dateString) {
    return "-";
  }
  const date = new Date(dateString);
  return `${date.toLocaleTimeString(navigator.language, {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  })}`;
}

function getNumberOfRoutines(
  numberOfRoutines: number,
  displayedRoutines?: number
) {
  if (displayedRoutines && numberOfRoutines > displayedRoutines) {
    return `(${displayedRoutines} of ${numberOfRoutines})`;
  } else if (numberOfRoutines > 0) {
    return `(${numberOfRoutines})`;
  }
  return "";
}

type RoutineStatus = {
  [key in RoutineStatusType]: BasicStatusType;
};

const tagStatusMap: RoutineStatus = {
  pass: "success",
  fail: "danger",
  "pass by default": "idle",
  completed: "success",
  "no completed": "idle",
};
export default function RoutineItem<RoutineNames extends RoutineNamesType>({
  name,
  numberOfRoutines,
  routineInfo,
  selected,
  setSelected,
  isLoading,
  displayedRoutines,
}: RoutineItemProps<RoutineNames>) {
  const isDesktop = useMediaQuery({ query: "(min-width: 1280px)" });

  return (
    <div
      onClick={routineInfo ? setSelected : undefined}
      className={`relative transition-colors rounded-sm flex flex-col justify-evenly items-center
       md:w-20 w-full h-28 min-w-[5rem] ${
         selected && routineInfo
           ? "bg-primary-600 text-neutral-0 cursor-default"
           : routineInfo
             ? "bg-neutral-200 hover:bg-neutral-300 cursor-pointer"
             : "bg-neutral-100"
       }`}
    >
      <div className={name === "slow_sine" ? "opacity-0" : ""}>
        <Tag
          status={
            routineInfo?.status ? tagStatusMap[routineInfo?.status] : "idle"
          }
          dominColours
        >
          <span className="truncate hover:text-clip sm:max-w-[3rem] xl:max-w-[4rem] 2xl:max-2-[5rem] capitalize">
            {routineInfo?.status ?? "-"}
          </span>
        </Tag>
      </div>

      <p className=" whitespace-nowrap">
        {isDesktop ? (
          routineShortMap.get(name)
        ) : (
          <Tooltip content={routineBiMap.get(name) ?? name} minLength={0}>
            {routineBiMap.get(name)?.toUpperCase() ?? name}
          </Tooltip>
        )}
        {` ${getNumberOfRoutines(numberOfRoutines, displayedRoutines)}`}
      </p>
      <Tooltip content={formatDate(routineInfo?.last_modified)}>
        <p className="text-sm">{formatDateHM(routineInfo?.last_modified)}</p>
      </Tooltip>
      {isLoading && (
        <div className="absolute inset-0 flex justify-items-center items-center bg-neutral-200 opacity-50">
          <Loading className="items-center" size={30} />
        </div>
      )}
    </div>
  );
}
