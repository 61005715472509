import { ValveType } from "@domin-frontend/domin-ui/dist/esm/types";

export function calculateMotorCalibrationLimits(valveVariant?: ValveType):
  | {
      xMin?: number;
      xMax?: number;
      yMin?: number;
      yMax?: number;
      zMin?: number;
      zMax?: number;
      overwrites?: boolean;
    }
  | undefined {
  if (!valveVariant) {
    return undefined;
  }

  if (valveVariant === "S10 Pro") {
    return {
      yMin: 0,
      yMax: 1000,
    };
  }
  if (valveVariant === "S6 Pro") {
    return {
      yMin: 0,
      yMax: 600,
    };
  }
  if (valveVariant === "S4 Pro") {
    return {
      yMin: 0,
      yMax: 400,
    };
  }
  if (valveVariant === "S6 Pro X") {
    return {
      yMin: 0,
    };
  }
  return { yMin: 0 };
}
