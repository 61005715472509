import { useEffect, useState } from "react";

/**
 * @description
 * Custom hook to share a link on Microsoft Teams, it handles the state of the modal
 * and the use of the Microsoft Teams SDK
 */
export default function useShareOnTeams(
  showModal: boolean = false,
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const [showPopupModal, setShowPopupModal] = useState(showModal);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://teams.microsoft.com/share/launcher.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [showPopupModal]);

  return [showPopupModal, setShowPopupModal] as [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ];
}
