import { PASFirmwareSettingsType } from "types/responseTypes";
import TableHeader from "./TableInfoHeader";
import { useState } from "react";
import { formatDigits } from "helpers/functions/shared/formatDigits";
import { Tag } from "@domin-frontend/domin-ui";

interface Props {
  data: PASFirmwareSettingsType;
}

function TableBody({ data }: Props) {
  const [isOpen, setIsOpen] = useState<number | false>(false);

  return (
    <>
      {Object.keys(data).map((key, i) => {
        let value = undefined;
        if (data[key] === null) {
          value = "null";
        } else {
          value = data[key];
        }

        if (typeof value === "object") {
          return (
            <div className="pl-4">
              <TableHeader
                onClick={() =>
                  setIsOpen(isOpen === false ? i : isOpen === i ? false : i)
                }
                isOpen={isOpen === i}
              >
                {key}
              </TableHeader>

              <div>
                {isOpen === i && (
                  <div className="flex justify-end flex-col">
                    <TableBody data={value} />
                  </div>
                )}
              </div>
            </div>
          );
        }
        return (
          <div
            key={`${key}-${i}`}
            className="h-8 hover:bg-primary-600 hover:text-neutral-0 transition-colors flex items-center gap-2"
          >
            {key === "error" ? (
              <Tag status="danger">Error</Tag>
            ) : (
              <span className="pl-1 text-start whitespace-nowrap">{key}</span>
            )}
            <span className="text-end pr-4 truncate w-full">{`${
              typeof value === "number" ? formatDigits(value, 9) : value
            }`}</span>
          </div>
        );
      })}
    </>
  );
}

export default function FirmwareSettingsTable({ data }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <TableHeader onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
        Firmware Settings
      </TableHeader>
      {isOpen && (
        <div
          className={`transition-all gap-y-4 overflow-hidden ${
            isOpen ? "scale-y-100 h-auto" : "scale-y-0 w-0 h-0"
          }`}
        >
          <div>
            <TableBody data={data} />
          </div>
        </div>
      )}
    </div>
  );
}
