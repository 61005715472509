export interface InfoRowProps {
  name: string;
  value?: string;
}

export default function InfoRow({ name, value }: InfoRowProps) {
  if (!value) {
    return null;
  }
  return (
    <tr className="text-sm flex gap-2 justify-between">
      <td className="py-[6px]">{name}</td>
      <td className="py-[6px] text-end">{value}</td>
    </tr>
  );
}
