import { AppLayout, AuthWeb } from "@domin-frontend/domin-ui";
import { loginRequest } from "auth/authConfig";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";
import VTPLayout from "./layouts/VTPLayout";
import EdgeMatchingDashboard from "./pages/EMDashboard";
import PASDashboard from "./pages/PASDashboard";
import PASValveView from "./pages/PASValveView";
import VTPLoginPage from "./pages/VTPLoginPage";
import PASRoutinesArea from "./PAS/PASValveView/PASRoutinesArea";
import PASTestArea from "./PAS/PASValveView/PASTestArea";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import EMManifoldView from "./pages/EMManifoldView";
import EMRoutinesArea from "./EdgeMatching/EMRoutinesArea";
import ValveCompare from "./pages/ValveCompare";
import CustomCharts from "./pages/CustomChart";

const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppLayout />}>
      <Route path="/login" element={<VTPLoginPage />} />
      <Route
        path="/"
        element={
          <>
            <AuthenticatedTemplate>
              <Navigate to="/pas/dashboard" />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Navigate to="/login" />
            </UnauthenticatedTemplate>
          </>
        }
      />
      <Route
        path="/*"
        element={
          <AuthWeb loginRequest={loginRequest} unauthPath="/login">
            <VTPLayout />
          </AuthWeb>
        }
      >
        <Route path="pas" element={<Navigate to="/pas/dashboard" />} />
        <Route path="pas/dashboard/:page?" element={<PASDashboard />} />
        <Route path="pas/:serialNumber/" element={<PASValveView />}>
          <Route path=":test" element={<PASTestArea />}>
            <Route path=":routine" element={<PASRoutinesArea />} />
          </Route>
        </Route>
        <Route
          path="edge-matching"
          element={<Navigate to="/edge-matching/dashboard" />}
        />
        <Route
          path="edge-matching/dashboard/:page?"
          element={<EdgeMatchingDashboard />}
        />
        <Route
          path="edge-matching/:manifold_spool"
          element={<EMManifoldView />}
        >
          <Route path=":routine" element={<EMRoutinesArea />} />
        </Route>
        <Route
          path="valve-intelligence/dashboard"
          element={<Navigate to="/valve-intelligence/valve-compare" />}
        />
        <Route path="valve-intelligence/*">
          <Route path="valve-compare" element={<ValveCompare />} />
          <Route path="pas-custom-chart" element={<CustomCharts />} />
        </Route>
      </Route>
    </Route>,
  ),
);

export default appRouter;
