import { Loading } from "@domin-frontend/domin-ui";
import { ReactNode } from "react";

interface InputHeaderProps {
  onClick: (field: string) => void;
  field: string;
  children: ReactNode;
  value?: string;
  loading?: boolean;
  displayClear?: boolean;
  displayText?: string;
}

export default function InputHeader({
  onClick,
  field,
  children,
  value,
  loading,
  displayClear = true,
  displayText = "clear",
}: InputHeaderProps) {
  return (
    <div className="flex justify-between items-center">
      {children}
      <div className="flex gap-2 justify-end items-center">
        <div className="h-4 w-4 overflow-hidden">
          {loading && <Loading size={12} />}
        </div>
        {value && displayClear && (
          <p
            className="transition-opacity font-normal text-sm text-neutral-500 cursor-pointer opacity-0 hover:opacity-100"
            onClick={() => onClick(field)}
          >
            {displayText}
          </p>
        )}
      </div>
    </div>
  );
}
