import {
  Button,
  CardLayout,
  ErrorMessage,
  Loading,
  Pagination,
} from "@domin-frontend/domin-ui";
import stringToNumber from "helpers/functions/shared/stringToNumber";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PasListItemType } from "types/responseTypes";
import {
  prefetchPasList,
  useAllUniqueFieldValues,
  usePasList,
} from "../../queries/PASQueries";
import PasTable from "../PAS/PASDashboard/PasTable";
import { FilterFieldsPASTypes } from "types/requestTypes";
import useSetDocumentName from "helpers/hooks/shared/useSetDocumentName";
import { withTransaction } from "@elastic/apm-rum-react";

export const PASITEMSPERPAGE = 10;

// Generate array from 5 to 50 that display every 5 numbers up to 30 and then every 10 numbers
const itemsPerPageOptions = [
  ...Array.from(Array(8).keys()).map((i) =>
    i < 5 ? (i + 1) * 5 : (i - 2) * 10
  ),
  100,
];

function PASDashboard() {
  const LSItemsPerPage =
    window.localStorage.getItem("pasItemsPerPage") ?? PASITEMSPERPAGE;
  const [itemsPerPage, setItemsPerPage] = useState<number>(+LSItemsPerPage);
  const [filteredLists, setFilteredLists] = useState<
    FilterFieldsPASTypes[] | undefined
  >();
  const [searchValue, setSearchValue] = useState<string>();
  const [searchQuery, setSearchQuery] = useState<string>();
  const { page } = useParams();
  const {
    data: pasList,
    isLoading: isPasListLoading,
    isError: isPasListError,
    error: pasListError,
  } = usePasList({
    page: stringToNumber(page ?? 1),
    nOfItems: itemsPerPage,
    search: searchQuery,
    filter: filteredLists,
  });
  const navigate = useNavigate();
  const [lastPage, setLastPage] = useState<number>();
  const [localList, setLocalList] = useState<PasListItemType[]>([]);
  const [searchSuggestions, setSearchSuggestions] = useState<string[]>([]);
  const { data: uniqueValues } = useAllUniqueFieldValues(filteredLists);
  const itemsPerPageRef = useRef<HTMLDivElement>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const [isHyperflow, setIsHyperflow] = useState(searchParams.get("isHyperflow") ? true : false);

  const toggleHyperflow = () => {
    const changeHyperflow = !isHyperflow;
    setIsHyperflow(changeHyperflow);
    setSearchParams({ isHyperflow: changeHyperflow.toString() });
  };

  function setPage(e: number) {
    if (isHyperflow){
      navigate(
        `/pas/dashboard/${e}?isHyperflow=${true}`
      );
    } else {
      navigate(
        `/pas/dashboard/${e}${searchQuery ? `?search=${searchQuery}` : ""}`
      );
    }
  }

  // set tab title to "PAS Tests"
  useSetDocumentName(`PAS Tests - ${page ?? 1}`);

  // Set the local list of PAS items and the last page number when the pasList data is available
  useEffect(() => {
    if (pasList?.data && pasList?.total_pages) {
      setLocalList(pasList.data);
      setLastPage(pasList.total_pages);
    }
  }, [pasList?.data, pasList?.total_pages]);

  // Generate search suggestions based on unique field values whenever the unique field values change
  useEffect(() => {
    const mergedSuggestions: string[] = [];
    uniqueValues?.forEach((field) => {
      mergedSuggestions.push(...field.values);
    });
    setSearchSuggestions((prevSuggestions) =>
      [
        ...mergedSuggestions,
        ...prevSuggestions.filter(
          (suggestion) => !mergedSuggestions.includes(suggestion)
        ),
      ].filter((value, index, array) => array.indexOf(value) === index)
    );
  }, [uniqueValues, setSearchSuggestions]);

  // Update pas list after 1 second of no typing
  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchQuery(searchValue);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [searchValue, setSearchQuery]);

  // Clear the search query if the search value is empty
  useEffect(() => {
    if (!searchValue || searchValue === "") {
      setSearchQuery(undefined);
    }
  }, [searchValue, setSearchQuery]);

  // Clear the search query and display an error toast if no results
  useEffect(() => {
    if (!isPasListLoading && (pasList?.data[0] === undefined || pasListError)) {
      toast.error("No results found");
      const timeout = setTimeout(() => {
        setSearchQuery(undefined);
        setSearchValue("");
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [pasList?.data, pasListError, setSearchQuery, isPasListLoading]);

  // if returned page is different from the requested page, navigate to the returned page
  useEffect(() => {
    if (pasList?.page !== stringToNumber(page ?? 1) && pasList?.page) {
      navigate(`/pas/dashboard/${pasList.page}`);
    }
  }, [pasList?.page, page, navigate]);

  function handlePrefetchPasList(
    page: number,
    itemsPerPage?: number,
    search?: string,
    filter?: FilterFieldsPASTypes[]
  ) {
    prefetchPasList({
      page: page,
      nOfItems: itemsPerPage,
      search: search,
      filter: filter,
    });
  }

  function handleItemsPerPage(e: number) {
    setItemsPerPage(e);
    if (e !== PASITEMSPERPAGE) {
      window.localStorage.setItem("pasItemsPerPage", e.toString());
    } else {
      window.localStorage.removeItem("pasItemsPerPage");
    }
  }

  return (
    <CardLayout
      title={isHyperflow ? "PAS Tests - Hyperflow" : "PAS Tests"}
      className="relative"
      buttons={[
        {
          children: isHyperflow  ? "Show All Valves" : "Show Hyperflow",
          onClick: toggleHyperflow,
        },
      ]}
      search={{
        name: "Search valve",
        onChange: (e) => setSearchValue(e.target.value),
        value: searchValue ?? "",
        suggestions: searchSuggestions,
        isLoading: !!searchValue && isPasListLoading,
      }}
    >
      {isPasListLoading && (!localList || !localList[0]) ? (
        <div className={`h-full w-full`}>
          <Loading className="flex items-center justify-center h-full w-full" />
        </div>
      ) : isPasListError ? (
        <ErrorMessage>{`${pasListError}`}</ErrorMessage>
      ) : (
        <div
          className="relative bg-neutral-0 overflow-y-hidden overflow-x-auto py-2 sm:py-0"
          style={
            filteredLists
              ? {
                  height: `${(itemsPerPage < 20 ? itemsPerPage : 20) * 56}px`,
                  maxHeight: "80vh",
                }
              : undefined
          }
        >
          <PasTable
            data={localList}
            fieldUniqueValues={uniqueValues}
            filteredLists={filteredLists}
            setFilteredLists={setFilteredLists}
            isLoading={isPasListLoading}
            itemsPerPage={itemsPerPage}
          />
        </div>
      )}
      {!isPasListLoading &&
        pasList?.data[0] &&
        (searchQuery || filteredLists) && (
          <div className="flex justify-center">
            <Button
              onClick={() => {
                setSearchQuery(undefined);
                setSearchValue("");
                setFilteredLists(undefined);
              }}
              variant="ghost"
            >
              {searchQuery
                ? filteredLists
                  ? "Clear search and filters"
                  : "Clear search"
                : "Clear filters"}
            </Button>
          </div>
        )}
      <div className="flex w-full py-4 overflow-hidden justify-between gap-4 items-center">
        <div
          ref={itemsPerPageRef}
          className="flex items-center justify-between text-neutral-900 whitespace-nowrap bg-neutral-0 gap-2"
        >
          {/* <div className="grid grid-cols-2 items-center gap-2"> */}
          <p className="w-auto whitespace-nowrap text-base">Rows per Page: </p>
          <select
            name="itemsPerPage"
            onChange={(e) => handleItemsPerPage(stringToNumber(e.target.value))}
            value={itemsPerPage}
            // delete default select style for selected option
            className="appearance-none bg-neutral-0 hover:bg-neutral-100 text-base p-2 flex items-center border border-primary-600 hover:border-primary-700 cursor-pointer"
          >
            {itemsPerPageOptions.map((option, i) => (
              <option
                key={`${option}-${i}`}
                value={option}
                className="w-auto text-base"
              >
                {option}
              </option>
            ))}
          </select>
          {/* </div> */}
        </div>
        <div className="w-min">
          <Pagination
            page={stringToNumber(page ?? 1)}
            totalPages={lastPage}
            setPage={setPage}
            prefetchFunc={pasList?.data ? handlePrefetchPasList : undefined}
            prefetchFuncArgs={{
              search: searchQuery,
              itemsPerPage: itemsPerPage,
              filter: filteredLists,
            }}
            pagesRange={1}
          />
        </div>
        {/* set width to be the same than id itemsperpage */}
        <div style={{ width: itemsPerPageRef.current?.clientWidth }} />
      </div>
    </CardLayout>
  );
}

export default withTransaction("PASDashboard", "page")(PASDashboard);
