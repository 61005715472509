import { useEffect } from "react";
import { useLocation } from "react-router-dom";
export const DOCUMENT_TITLE_SUFFIX = "VTP";

// Add JS Doc explaning what this hook does

/**
 * A hook that sets the tab title to the name of the page
 */
export default function useSetDocumentName(name: string) {
  // update if location changes
  const isStaging = window.location.href
  ?.split("//")[1]
  ?.split("/")[0]
  .includes("domin-wa-vtp-develop");
const environment =
  process.env.NODE_ENV === "production"
    ? isStaging
      ? "STAG:"
      : ""
    : "DEV: ";
  const location = useLocation();
  
  useEffect(() => {
    document.title = `${environment}${name} | ${DOCUMENT_TITLE_SUFFIX}`;
  }, [name, location, environment]);
}
