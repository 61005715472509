// libraries
import { useEffect, useRef, useState } from "react";
import {
  CardItem,
  CardLayout,
  Icon,
  useOutsideMouseDown,
} from "@domin-frontend/domin-ui";
import { withTransaction } from "@elastic/apm-rum-react";
import { useSearchParams } from "react-router-dom";
// components
import RoutineChartsColumn from "components/valveIntelligence/valveComparison/Components/RoutineChartsColumn";
import ShareModal from "components/valveIntelligence/valveComparison/Components/ShareModal";
import VCQueryForm from "components/valveIntelligence/valveComparison/Components/VCQueryForm";
// helpers
import { updateFilterQueries } from "helpers/functions/compareValveArea/updateFilterQueries";
import { updateSelectRoutine } from "helpers/functions/compareValveArea/updateSelectRoutine";
import { useUpdateFromSearchParams } from "helpers/hooks/compareValveArea/useUpdateRoutinesFromSearchParams";
import { useUpdateSearchParams } from "helpers/hooks/compareValveArea/useUpdateSearchParams";
import useSetDocumentName from "helpers/hooks/shared/useSetDocumentName";
// temp components

import IconButton from "tempComponents/IconButton";
import OptionsItem from "tempComponents/OptionsItem";

// types
import { FilterFieldsPASReqType } from "types/requestTypes";
import {
  CompareDropdownItemsType,
  FilterFieldsPASObjectType,
  HandleFilterPropsType,
  handleSelectRoutineProps,
} from "types/valveIntelligenceTypes";
import useShareOnTeams from "helpers/hooks/shared/useShareOnTeams";
import useHasReachTop from "helpers/hooks/shared/useHasReachTop";

export const initialValues: FilterFieldsPASReqType = {
  valve_type: undefined,
  routine_type: undefined,
  serial_number: undefined,
  status: undefined,
};

function ValveCompare() {
  // not component specific hooks
  useSetDocumentName(`Valve Intelligence`);
  const [showPopupModal, setShowPopupModal] = useShareOnTeams();
  // references
  const optionsRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  // component specific state
  const [searchParams] = useSearchParams();
  const [columns, setColumns] = useState(1);
  //   first level array is columns, second level array is rows
  const [selectedRoutines, setSelectedRoutines] = useState<
    CompareDropdownItemsType[][]
  >([[]]);
  const [filterQueries, setFilterQueries] = useState<FilterFieldsPASObjectType>(
    { c1: initialValues }
  );
  // Visibility state
  const [editRow, setEditRow] = useState<
    { column: number; row: number } | false
  >({ column: 0, row: 0 });
  const [optionsOpen, setOptionsOpen] = useState<number | false>(false);
  const isReachedTop = useHasReachTop(ref);

  // This function wraps the updateSelectRoutine function and passes the setSelectedRoutines function
  function handleSelectRoutine({
    routine,
    column,
    row,
    remove,
    duplicate,
  }: handleSelectRoutineProps) {
    updateSelectRoutine({
      routine,
      column,
      row,
      setSelectedRoutines,
      remove,
      duplicate,
    });
  }

  // This function wraps the updateFilterQueries function and passes the setFilterQueries function
  function handleFilterQueries({
    column,
    filter,
    remove,
  }: HandleFilterPropsType) {
    updateFilterQueries({
      column,
      totalColumns: columns,
      filter,
      remove,
      setFilterQueries,
    });
  }

  useOutsideMouseDown(
    optionsRef,
    () => {
      setOptionsOpen(false);
    },
    { ids: ["options-item"] }
  );

  useOutsideMouseDown(
    ref,
    () => {
      setEditRow(false);
    },
    { ids: ["single-select"] }
  );

  useUpdateSearchParams({
    selectedRoutines,
    filterQueries,
  });

  useUpdateFromSearchParams({
    searchParams,
    setSelectedRoutines,
    setFilterQueries,
  });

  // update columns when the number of columns is less than the number of selected routines
  useEffect(() => {
    // get all the columns included in the filter queries in the url. Not the clearest code, but want to keep it short
    const maxColumnFilters = Math.max(
      ...Array.from(searchParams.entries())
        .filter(
          ([key]) =>
            key.includes("c-") === false && key.includes("_r-") === false
        )
        .map(([key]) => {
          const column = +key.slice(-1);
          if ([1, 2, 3].includes(column)) {
            return column;
          } else {
            return 0;
          }
        })
    );
    const totalColumnsRoutine = selectedRoutines.length;
    const totalColumns = Math.max(totalColumnsRoutine, maxColumnFilters);
    if (totalColumns > columns) {
      setColumns(totalColumns);
    }
    if (totalColumns === 0) {
      setSelectedRoutines([[]]);
      setColumns(1);
    }
  }, [selectedRoutines, columns, searchParams]);

  return (
    <CardLayout
      title={"Valve Compare"}
      buttons={[
        {
          children: "Share",
          onClick: () => {
            setShowPopupModal(true);
          },
          variant: "secondary",
        },
      ]}
    >
      <CardItem className="!pt-0">
        {/* Options Item */}
        <div className="flex sticky top-0 bg-neutral-0 z-10">
          {Array.from({ length: columns }).map((_, i) => {
            const column = i + 1;

            return (
              <div
                ref={ref}
                key={`${i}${selectedRoutines?.[i]?.length ?? 0}${
                  Object.keys(filterQueries).length
                }`}
                className={`relative w-full max-w-[50%] border-l border-primary-600 
                first:border-none pl-4 pr-3 first:pl-0 pt-3 transition-colors ${
                  isReachedTop ? "shadow-[0_4px_2px_-4px_rgba(0,0,0,0.5)]" : ""
                }`}
              >
                <div className="absolute top-0 right-2 flex h-8 justify-end p-1 stroke-neutral-500 hover:stroke-primary-600 z-10 whitespace-nowrap">
                  <div
                    className="w-10 flex justify-end items-center cursor-pointer "
                    ref={optionsRef}
                    onClick={() => setOptionsOpen(i)}
                  >
                    {optionsOpen === i && (
                      <div className="absolute top-8 right-0 z-10 bg-neutral-0 shadow rounded-sm flex flex-col">
                        <OptionsItem
                          onClick={() => {
                            // clear column
                            setSelectedRoutines((prev) => {
                              const newSelectedRoutines = [...prev];
                              newSelectedRoutines[i] = [];
                              return newSelectedRoutines;
                            });
                            // clear filter queries
                            setFilterQueries((prev) => {
                              const newFilterQueries = { ...prev };
                              newFilterQueries[
                                `c${i + 1}` as keyof FilterFieldsPASObjectType
                              ] = initialValues;
                              return newFilterQueries;
                            });

                            setOptionsOpen(false);
                          }}
                        >
                          Clear column
                        </OptionsItem>
                        {columns < 3 &&
                          selectedRoutines?.[i] &&
                          selectedRoutines[i]?.length > 0 && (
                            <OptionsItem
                              onClick={() => {
                                if (
                                  selectedRoutines?.[i] &&
                                  selectedRoutines?.[i]?.length > 0
                                ) {
                                  // add queries to next column
                                  setFilterQueries((prev) => {
                                    const newFilterQueries = { ...prev };

                                    newFilterQueries[
                                      `c${
                                        i + 2
                                      }` as keyof FilterFieldsPASObjectType
                                    ] =
                                      newFilterQueries[
                                        `c${
                                          i + 1
                                        }` as keyof FilterFieldsPASObjectType
                                      ];
                                    return newFilterQueries;
                                  });
                                  selectedRoutines[i].forEach((routine, i) => {
                                    handleSelectRoutine({
                                      routine,
                                      column: columns,
                                      row: i,
                                      duplicate: true,
                                    });
                                  });
                                  setOptionsOpen(false);
                                }
                              }}
                            >
                              Duplicate
                            </OptionsItem>
                          )}
                        <OptionsItem
                          className="!text-danger-600"
                          onClick={() => {
                            handleFilterQueries({
                              column: i + 1,
                              remove: true,
                            });

                            // delete routines in column
                            setSelectedRoutines((prev) => {
                              return i === 0
                                ? prev.slice(1)
                                : i === columns - 1
                                  ? prev.slice(0, i)
                                  : [...prev.slice(0, i), ...prev.slice(i + 1)];
                            });
                            setOptionsOpen(false);
                            // setColumns after the setFilterQueries function with a timeout
                            setTimeout(() => {
                              setColumns((prev) => {
                                if ([2, 3].includes(prev)) {
                                  return prev - 1;
                                } else {
                                  return prev;
                                }
                              });
                            }, 0);
                          }}
                        >
                          Delete
                        </OptionsItem>
                      </div>
                    )}
                    <Icon name="options" />
                  </div>
                </div>
                <VCQueryForm
                  handleFilterQueries={handleFilterQueries}
                  filterQuery={
                    filterQueries[`c${i + 1}` as keyof typeof filterQueries] ??
                    initialValues
                  }
                  clearColumn={() =>
                    setSelectedRoutines((prev) => {
                      const newSelectedRoutines = [...prev];
                      return (newSelectedRoutines[i] = []);
                    })
                  }
                  column={column}
                />
              </div>
            );
          })}
          {columns < 3 && (
            <div className="flex w-16 border-l p-2 border-primary-600">
              <IconButton
                onClick={() => {
                  if (columns < 3) {
                    setSelectedRoutines((prev) => [...prev, []]);
                  }
                }}
              />
            </div>
          )}
        </div>
        <div className="flex row-start-2">
          {Array.from({ length: columns }).map((_, i) => (
            <RoutineChartsColumn
              setEditRow={setEditRow}
              editRow={editRow && editRow.column === i ? editRow.row : false}
              key={`${i}${selectedRoutines?.[i]?.length ?? 0}${
                Object.keys(filterQueries).length
              }`}
              routines={selectedRoutines?.[i] ?? []}
              setRoutines={handleSelectRoutine}
              totalColumns={columns}
              column={i}
              filterQuery={
                filterQueries[`c${i + 1}` as keyof typeof filterQueries] ??
                initialValues
              }
            />
          ))}
          {columns < 3 && (
            // This is a placeholder for the add column button
            <div className="flex pb-1 w-16 border-l p-2 border-primary-600 items-center">
              <span className="w-8" />
            </div>
          )}
        </div>
      </CardItem>
      {showPopupModal && (
        <ShareModal
          setShowPopupModal={setShowPopupModal}
          message="Copy the link below and to share this valve comparison with others"
        />
      )}
    </CardLayout>
  );
}

export default withTransaction("Valve Compare", "page")(ValveCompare);
