import { Tag } from "@domin-frontend/domin-ui";
import {
  BasicStatusType,
  RoutineNamesType,
} from "@domin-frontend/domin-ui/dist/esm/types";
import TableInfoHeader from "components/SharedComponents/TableInfoHeader";
import { formatDigits } from "helpers/functions/shared/formatDigits";
import { Fragment, ReactNode, useState } from "react";
import { SpecsRoutineItem } from "types/responseTypes";

interface Props {
  specs: SpecsRoutineItem[];
  routineType?: RoutineNamesType;
}

function calculateSpecs({
  actual,
  min,
  max,
}: {
  actual: number;
  min?: number;
  max?: number;
}): BasicStatusType {
  if (min && actual < min) {
    return "danger";
  } else if (max && actual > max) {
    return "danger";
  } else if (!min && !max) {
    return "idle";
  } else {
    return "success";
  }
}

// function to check if the current spec is the end of a percentage for freq response
function isEndOfPercentage(currentSpec: string, nextSpec: string | undefined) {
  // get the percentage from the current spec, which is all the numbers before the % and after the last space
  if (
    !currentSpec ||
    !nextSpec ||
    !currentSpec.includes("%") ||
    !nextSpec.includes("%")
  ) {
    return false;
  }
  const currentPercentage = currentSpec.split(" ").pop()?.split("%").shift();

  const nextPercentage = nextSpec?.split(" ").pop()?.split("%").shift();
  // check if the percentage is different than the one in the next spec
  if (currentPercentage !== nextPercentage) {
    return true;
  }
  return false;
}

// Style components for specs table
export function SpecName({ children }: { children: ReactNode }) {
  return <td className="pl-1 whitespace-nowrap">{children}</td>;
}

export function SpecValue({
  children,
  status,
  onClick,
  className = "",
}: {
  children?: ReactNode | null;
  status?: BasicStatusType;
  onClick?: () => void;
  className?: string;
}) {
  if (children === null || children === undefined || Number.isNaN(children)) {
    return (
      <td
        onClick={onClick}
        className={`whitespace-nowrap text-end pr-6 ${className}`}
      >
        -
      </td>
    );
  }
  return !status || status === "idle" ? (
    <td
      onClick={onClick}
      className={`whitespace-nowrap text-end pr-3 last:pr-4 ${className}`}
    >
      {children}
    </td>
  ) : (
    <td onClick={onClick} className={`flex justify-end ${className}`}>
      <Tag status={status} dominColours>
        {children}
      </Tag>
    </td>
  );
}

export function SpecHead({ children }: { children: ReactNode }) {
  return (
    <th className="text-end pr-3 last:pr-4 h-8 font-medium text-xs">
      {children}
    </th>
  );
}

// Main table component
export default function RoutineSpecs({ specs, routineType }: Props) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div
      className={`transition xl:min-w-[24rem]  ${isOpen ? "h-auto" : "h-8"}`}
    >
      <TableInfoHeader onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
        Routine Specs
      </TableInfoHeader>
      {isOpen && (
        <table
          className={`w-full transition-all -translate-y-56 ${
            isOpen
              ? "scale-y-100 translate-y-0"
              : "scale-y-0 -translate-y-2/4 h-0"
          }`}
        >
          <thead>
            <tr>
              <th className="pl-1 text-start font-medium text-xs">Name</th>
              {specs.some((spec) => formatDigits(spec.min)) && (
                <SpecHead>Min</SpecHead>
              )}
              {specs.some((spec) => formatDigits(spec.actual)) && (
                <SpecHead>Actual</SpecHead>
              )}
              {specs.some((spec) => formatDigits(spec.max)) && (
                <SpecHead>Max</SpecHead>
              )}
              {specs.some((spec) => formatDigits(spec.spec)) && (
                <SpecHead>Spec</SpecHead>
              )}
            </tr>
          </thead>
          <tbody>
            {specs
              // TODO: commenting out to display freq response in correct order, find a better way to sort
              // .sort((a, b) => a.name.localeCompare(b.name))
              .map((s, i) => {
                const hasValues = s.min || s.actual || s.max || s.spec;

                if (!hasValues) {
                  return null;
                }

                return (
                  <Fragment key={i}>
                    <tr
                      className="h-8 hover:bg-primary-600 hover:text-neutral-0 transition-colors"
                      key={i}
                    >
                      <SpecName>
                        {s.name}
                        {s.unit ? ` (${s.unit})` : ""}
                      </SpecName>
                      {specs.some((spec) => formatDigits(spec.min)) && (
                        <SpecValue>{s.min && formatDigits(s.min)}</SpecValue>
                      )}
                      {specs.some((spec) => formatDigits(spec.actual)) && (
                        <SpecValue
                          status={
                            s.status ??
                            (s.actual
                              ? calculateSpecs({
                                  actual: s.actual ?? 0,
                                  min: s.min,
                                  max: s.max,
                                })
                              : "idle")
                          }
                        >
                          {s.actual && formatDigits(s.actual)}
                        </SpecValue>
                      )}
                      {specs.some((spec) => formatDigits(spec.max)) && (
                        <SpecValue>{s.max && formatDigits(s.max)}</SpecValue>
                      )}
                      {specs.some((spec) => formatDigits(spec.spec)) && (
                        <SpecValue>{s.spec}</SpecValue>
                      )}
                    </tr>
                    {routineType === "frequency_response" &&
                      isEndOfPercentage(s.name, specs[i + 1]?.name) && (
                        // draw a line between each percentage
                        <>
                          <tr className="h-1">
                            <td />
                          </tr>
                          <tr className="h-1">
                            <td
                              colSpan={5}
                              className="border-t border-neutral-300"
                            />
                          </tr>
                        </>
                      )}
                  </Fragment>
                );
              })}
          </tbody>
        </table>
      )}
    </div>
  );
}
