import {
  FilterFieldsPASReqType,
  FilterFieldsPASTypes,
} from "types/requestTypes";

export function makeUniqueValuesQuery(
  filterQuery: FilterFieldsPASReqType,
): FilterFieldsPASTypes[] {
  const query: FilterFieldsPASTypes[] = [];
  for (const key in filterQuery) {
    const value = filterQuery[key as keyof FilterFieldsPASReqType];
    if (value) {
      query.push({
        field: key as keyof FilterFieldsPASReqType,
        values: [`${value}`],
      });
    }
  }
  return query;
}
