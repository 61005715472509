interface OptionsItemProps {
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

export default function OptionsItem({
  children,
  className,
  onClick,
}: OptionsItemProps) {
  return (
    <span
      id="options-item"
      onClick={onClick}
      className={`transition-colors p-2 cursor-pointer bg-neutral-0 hover:bg-neutral-100 text-neutral-800 hover:text-neutral-900 ${
        className ?? ""
      }`}
    >
      {children}
    </span>
  );
}
