import { UnitsType } from "@domin-frontend/domin-ui/dist/esm/types/testTypes";
import {
  SpecName,
  SpecValue,
} from "components/PAS/PASRoutineItems/RoutineSpecs";
import { formatDigits } from "helpers/functions/shared/formatDigits";
import { BasicTableType } from "types/responseTypes";

interface LeakageResultsTableProps {
  isOpen: boolean;
  results: BasicTableType;
}

const resultsMap: { [key: string]: string } = {
  max_leakage: "Max Leakage",
  lhs_off_centre: "LHS Off Centre",
  rhs_off_centre: "RHS Off Centre",
  avg_oil_temperature: "Oil Temperature (avg)",
};

function formatResultValue(
  type: string,
  value: string | number | boolean
): string {
  const unitsMap: { [key: string]: UnitsType } = {
    max_leakage: "LPM",
    lhs_off_centre: "LPM",
    rhs_off_centre: "LPM",
    avg_oil_temperature: "°C",
  };
  const formattedValue =
    typeof value === "number" ? formatDigits(value) : value;

  return `${formattedValue} ${unitsMap[type] ? `(${unitsMap[type]})` : ""}`;
}
export default function LeakageResultsTable({
  isOpen,
  results,
}: LeakageResultsTableProps) {
  return (
    <table
      className={`w-full transition-all -translate-y-56 ${
        isOpen ? "scale-y-100 translate-y-0" : "scale-y-0 -translate-y-2/4 h-0"
      }`}
    >
      <tbody>
        {Object.entries(results).map(([r, i]) => {
          const value = results[r];
          return (
            <tr
              className="h-8 hover:bg-primary-600 hover:text-neutral-0 transition-colors"
              key={`${r}-${i}`}
            >
              <SpecName>{resultsMap[r]}</SpecName>
              <SpecValue>{formatResultValue(r, value)}</SpecValue>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
