import { useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  NavigationType,
  useLocation,
} from "react-router-dom";
import PASChartsArea from "./PASChartsArea";
import RoutineItem from "./RoutineItem";
import { usePASValveInfo } from "queries/PASQueries";
import PASOverview from "./PASOverview";
import appRouter from "components/AppRouter";
import { PASRoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types";
import { PASRoutineNames } from "@domin-frontend/domin-ui";
import { hyperflowFilters, defaultFilters } from "../filterConfig";

export default function PASRoutinesArea() {
  const { serialNumber, test, routine } = useParams<{
    serialNumber: string;
    test: string;
    routine: PASRoutineNamesType;
  }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: infoData } = usePASValveInfo(serialNumber);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedRoutine, setSelectedRoutine] = useState<
    PASRoutineNamesType | false
  >(false);

  const [filteredRoutines, setFilteredRoutines] = useState<
    PASRoutineNamesType[]
  >(PASRoutineNames);

  // hyperflow filter check
  useEffect(() => {
    if (
      infoData?.customer === hyperflowFilters.filters[0].values[0] &&
      hyperflowFilters.filters[1].values.some((value) => infoData?.sku === value)
    ) {
      setFilteredRoutines(hyperflowFilters.routines);
    } else {
      setFilteredRoutines(defaultFilters.routines);
    }
  }, [infoData?.customer, infoData?.sku]);

  const routines =
    infoData?.tests?.find((t) => t.test_id === test)?.routines ??
    infoData?.tests?.[0]?.routines ??
    undefined;

  // if the user navigates back from the charts page, navigate to the overview page
  useEffect(() => {
    appRouter.subscribe((state) => {
      if (
        state.historyAction === NavigationType.Pop &&
        location.state?.redirected
      ) {
        navigate("/pas/dashboard", { state: { redirected: false } });
      }
    });
  }, [navigate, location]);

  useEffect(() => {
    if (routine) {
      setSelectedRoutine(routine);
    }
  }, [routine]);

  if (searchParams.get("view") === "overview") {
    return (
      <PASOverview routines={routines} filteredRoutines={filteredRoutines} />
    );
  }

  if (!searchParams.get("view")) {
    setSearchParams({ view: "overview" });
  }

  return (
    <>
      <div className="relative w-full grid">
        {/* Routines */}
        <div className="flex gap-2 lg:gap-4 w-full">
          {filteredRoutines.map((r, i) => {
            const lastRoutine = routines
              ? routines[r]?.slice(-1)[0]
              : undefined;

            return (
              <RoutineItem<PASRoutineNamesType>
                name={r}
                numberOfRoutines={routines ? routines[r]?.length : 0}
                key={`${r}-${i}`}
                routineInfo={lastRoutine}
                selected={selectedRoutine === r}
                setSelected={() =>
                  navigate(`/pas/${serialNumber}/${test}/${r}?view=detail`)
                }
              />
            );
          })}
        </div>
        {/* Charts */}
      </div>
      {routines && selectedRoutine && (
        <PASChartsArea
          key={selectedRoutine}
          routines={routines[selectedRoutine]}
          type={selectedRoutine}
        />
      )}
    </>
  );
}
