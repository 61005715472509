import {
  Button,
  ErrorMessage,
  Loading,
  PASRoutineNames,
  RoutineNames,
} from "@domin-frontend/domin-ui";
import { useEffect, useState } from "react";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ValveTestsPASType } from "types/responseTypes";
import PASTestsDropdown from "./PASTestsDropdown";
import { usePASValveInfo } from "queries/PASQueries";
import CustomerReport from "../CustomerReport/CustomerReport";

export default function PASTestArea() {
  const { serialNumber, test, routine } = useParams<string>();
  const { data: infoData, isLoading } = usePASValveInfo(serialNumber);
  const navigate = useNavigate();
  const [selectedTest, setSelectedTest] = useState<ValveTestsPASType>();
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view") ?? "overview";
  const { data: valve } = usePASValveInfo(serialNumber);

  // if test and routine are defined, set selectedTest to the test with the given test_id
  useEffect(() => {
    if (test && infoData?.tests) {
      const { tests } = infoData;
      if (routine) {
        const newTest = tests?.find((t) => t.test_id === test);
        if (newTest) {
          setSelectedTest(newTest);
        }
      } else if (!routine && tests) {
        // navigate  to the first with data of the selected test
        const routinetype =
          PASRoutineNames.find(
            (r) => tests.find((t) => t.test_id === test)?.routines[r],
          ) ?? RoutineNames[0];

        navigate(`/pas/${serialNumber}/${test}/${routinetype}?view=${view}`, {
          replace: true,
        });
      }
    }
  }, [test, routine, infoData, navigate, serialNumber, view]);

  function handleTestClick(id: string) {
    if (infoData?.tests) {
      const newTest = infoData.tests.find((t) => t.test_id === id);
      if (newTest) {
        navigate(`/pas/${serialNumber}/${newTest.test_id}?view=${view}`);
      }
    }
    return setOpen(false);
  }
  const latestTest = infoData?.tests?.sort(
    (a, b) => +b.last_modified - +a.last_modified,
  )[0];
  const isLatestTest = selectedTest === latestTest;

  if (isLoading) {
    return <Loading />;
  }

  if (!selectedTest || !infoData?.tests) {
    return <ErrorMessage>No test data found for this valve.</ErrorMessage>;
  }

  if (view === "customer-report") {
    return <CustomerReport />;
  }

  return (
    // This is a CardItem component but with some custom functionality
    <div className="flex flex-col h-auto bg-neutral-0 col-start-2 gap-2 sticky top-0">
      {/* header */}
      <div className="flex justify-between min-w-[24rem]">
        <div className="flex">
          <PASTestsDropdown
            selectedTest={selectedTest}
            tests={infoData.tests}
            setOpen={setOpen}
            setSelected={handleTestClick}
            open={open}
          />
          {!isLatestTest && (
            <Button
              variant="ghost"
              onClick={
                latestTest && !isLatestTest
                  ? () => handleTestClick(latestTest.test_id)
                  : undefined
              }
            >
              Go to latest test
            </Button>
          )}
        </div>
        {searchParams.get("view") === "overview" &&
          isLatestTest &&
          valve?.status === "concess" && (
            <Button
              variant="ghost"
              className="ml-2"
              onClick={() =>
                // open link in new tab
                window.open(
                  `https://airtable.com/appw0Cs7EmfW6o06m/shrvLrOIN5QFO0J9d?prefill_Concessed+Valve=${serialNumber}`,
                  "_blank",
                )
              }
            >
              Open Concession Form
            </Button>
          )}
      </div>
      <Outlet />
    </div>
  );
}
