import DisplayRoutineRow from "./DisplayRoutineRow";
import {
  CompareDropdownItemsType,
  handleSelectRoutineProps,
} from "types/valveIntelligenceTypes";
import { useFilteredRoutines } from "queries/ValveIntelligenceQueries";
import { FilterFieldsPASReqType } from "types/requestTypes";
import { Dispatch } from "react";

import { formatRoutineOptions } from "helpers/functions/shared/formatRoutineOptions";
import IconButton from "tempComponents/IconButton";
import { ErrorMessage, Loading } from "@domin-frontend/domin-ui";
import RoutineCompareItem from "./RoutineCompareItem";

interface Props {
  routines: CompareDropdownItemsType[];
  editRow: number | false;
  // setEditRow: ({column, row}:{column: number, row: number } | false) => void;
  setEditRow: Dispatch<
    React.SetStateAction<
      | false
      | {
          column: number;
          row: number;
        }
    >
  >;
  totalColumns: number;
  column: number;
  filterQuery: FilterFieldsPASReqType;
  setRoutines: ({
    routine,
    column,
    row,
    remove,
  }: handleSelectRoutineProps) => void;
}

export default function RoutineChartsColumn({
  routines,
  totalColumns,
  editRow,
  setEditRow,
  column,
  filterQuery,
  setRoutines,
}: Props) {
  const {
    data: filteredRoutinesData,
    isLoading: isFilteredRoutinesLoading,
    isError: isFilteredRoutinesError,
  } = useFilteredRoutines(filterQuery);

  return (
    <div className="w-full max-w-[50%] border-l border-primary-600 first:border-none px-4 first:pl-0 flex flex-col gap-8">
      {routines &&
        routines.map((routine, row) => (
          <RoutineCompareItem
            setEdit={() => {
              setEditRow({ column, row });
            }}
            key={`${routine.routine_id}${column}${row}`}
            routine={routine}
            edit={editRow === row}
            column={column}
            row={row}
            filteredRoutinesData={filteredRoutinesData}
            totalColumns={totalColumns}
            setRoutines={setRoutines}
            isRoutinesLoading={isFilteredRoutinesLoading}
          />
        ))}
      {filteredRoutinesData?.[routines.length] &&
      (editRow === (routines.length ?? 1) || routines.length === 0) ? (
        <div id="routine-dropdown">
          <DisplayRoutineRow
            name={filteredRoutinesData ? "Select Routine..." : ""}
            editable={routines.length === 0 || editRow === routines.length}
            setEdit={() => {
              setEditRow({ column, row: routines.length });
            }}
            options={formatRoutineOptions(filteredRoutinesData ?? [])}
            value={filteredRoutinesData[routines.length].routine_id}
            onChange={(e) => {
              const routine = filteredRoutinesData?.find(
                (item) => item?.routine_id === e.value,
              );
              if (routine && e) {
                setRoutines({
                  routine,
                  column,
                  row: routines.length,
                });
              }
            }}
          />
        </div>
      ) : isFilteredRoutinesLoading ? (
        <div className="flex w-full justify-center h-9">
          <Loading size={30} />
        </div>
      ) : isFilteredRoutinesError ? (
        <div className="flex w-full">
          <ErrorMessage>
            {routines.length === 0
              ? "Error loading routines"
              : "Error loading new routines"}
          </ErrorMessage>
        </div>
      ) : (
        <div className="transition-opacity flex w-full justify-center opacity-0 hover:opacity-100">
          <IconButton
            icon="plus"
            onClick={() =>
              setEditRow({
                column,
                row: routines.length,
              })
            }
          />
        </div>
      )}
    </div>
  );
}
