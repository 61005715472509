import { MultilineType } from "@domin-frontend/domin-ui/dist/esm/types";

export function getPercentages(
  data: MultilineType,
): { from: number; to: number; selected: boolean }[] {
  // get the from and to numbers at the start of the key
  return (
    Object.keys(data)
      .map((key) => ({
        from: Number(key.split("_")[0]),
        to: Number(key.split("_")[2]),
        selected: true,
      }))
      //   filter out duplicates
      .filter(
        (v, i, a) =>
          a.findIndex((t) => t.from === v.from && t.to === v.to) === i,
      )
      //   sort from lowest to highest, if "to" is 0 then order by "from"
      .sort((a, b) => {
        if (a.to === 0) {
          return a.from - b.from;
        } else {
          return a.to - b.to;
        }
      })
      //   map to the percentage
      .map((t) => ({ ...t, percentage: t.to === 0 ? t.from : t.to }))
  );
}
