import { ReactNode, useRef, useState } from "react";
import { TestSettingsType } from "types/responseTypes";
import TableHeader from "./TableInfoHeader";
import { formatDigits } from "helpers/functions/shared/formatDigits";
import { useOutsideHover } from "@domin-frontend/domin-ui";

function TSHead({ children }: { children: ReactNode }) {
  return (
    <th className="font-medium text-xs text-start pr-3 h-8 first:pl-1 last:pr-4 last:text-end whitespace-nowrap ">
      {children}
    </th>
  );
}

export default function TestSettingsTable({
  data,
}: {
  data: TestSettingsType;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLTableElement>(null);

  useOutsideHover(ref, () => {
    document.body.style.overflow = "auto";
  });

  return (
    <div>
      <TableHeader onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
        Test Settings
      </TableHeader>
      {isOpen && (
        <table
          className={`transition-all gap-y-4 overflow-hidden ${
            isOpen ? "scale-y-100 h-auto" : "scale-y-0 w-0 h-0"
          }`}
        >
          <thead>
            <tr className={`transition`}>
              <TSHead>Setting</TSHead>
              <TSHead>Value</TSHead>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map((key, i) => {
              const value = data[key];
              return (
                <tr
                  key={`${key}-${i}`}
                  className="h-8 hover:bg-primary-600 hover:text-neutral-0 transition-colors"
                >
                  <td className="pl-1 text-start whitespace-nowrap">{key}</td>
                  {typeof value === "number" || typeof value === "string" ? (
                    <td className="whitespace-nowrap text-end pr-4 truncate w-full">
                      {value}
                    </td>
                  ) : (
                    <td className="text-end w-full flex justify-end">
                      {/* use vertical scroll as horizontal scroll */}
                      <div
                        ref={ref}
                        onWheel={(e) => {
                          e.currentTarget.scrollLeft += e.deltaY;
                          // prevent scrolling the page
                          e.stopPropagation();

                          // prevent scrolling the page
                          document.body.style.overflow = "hidden";
                          // find id "valve-info" and add overflow hidden
                          const valveInfo =
                            document.getElementById("valve-info");
                          if (valveInfo) {
                            valveInfo.style.overflow = "hidden";
                          }
                        }}
                        className={`whitespace-nowrap text-end mr-4 max-w-[7rem] overflow-x-scroll`}
                        style={{
                          // style horizontal scroll to prevent it to block the text, so with a little margin and do not show a bigger scrollbar when hovered
                          // firefox
                          scrollbarWidth: "thin",
                          scrollbarColor: "#00000030 transparent",
                        }}
                      >
                        {value.map((value) => formatDigits(value)).join(", ")}
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}
