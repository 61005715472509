import { Icon } from "@domin-frontend/domin-ui";
import { ReactNode } from "react";

export default function TableHeader({
  children,
  onClick,
  isOpen,
}: {
  children: ReactNode;
  onClick?: () => void;
  isOpen: boolean;
}) {
  return (
    <div
      className="flex justify-between items-center border-b border-primary-600 cursor-pointer"
      onClick={onClick ? () => onClick() : undefined}
    >
      <p className=" p-1">{children}</p>
      <div
        className={`transition-transform w-4 h-4 ${
          isOpen ? "rotate-180 -translate-y-1" : "rotate-0 translate-y-1"
        }`}
      >
        {onClick && <Icon name="show" className="w-4 h-4 mr-2" />}
      </div>
    </div>
  );
}
