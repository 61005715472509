import {
  LineChart,
  Loading,
  Tag,
  isMultiline,
  isSingleLine,
} from "@domin-frontend/domin-ui";
import {
  EMRoutineNamesType,
  LineDataType,
  MultilineType,
  ValveType,
} from "@domin-frontend/domin-ui/dist/esm/types";
import TitleChartArea from "components/PAS/PASValveView/PASCharts/TitleChartArea";
import {
  xLabelMap,
  yLabelMap,
} from "components/PAS/PASValveView/PASCharts/labelFormatMap";
import { useEdgeMatchingRoutineData } from "queries/EMQueries";
import { useEffect, useState } from "react";

export const FIRSTLINEARDATADATE = new Date("2024-02-06");
interface EMChartProps {
  routineId: string;
  type: EMRoutineNamesType;
  lastModified: string;
  data: MultilineType | LineDataType<string>[];
  xzData?: MultilineType;
  guides?: MultilineType;
  info?: {
    master_spool?: boolean;
  };
  isLinearEncoder: boolean;
}

function EMChart({
  routineId,
  type,
  lastModified,
  data,
  xzData,
  guides,
  info,
  isLinearEncoder,
}: EMChartProps) {
  return (
    <div className="w-full">
      <TitleChartArea
        routineId={routineId}
        lastModified={lastModified}
        type={type}
        displayRawData={{ csv: false, json: false }}
        tag={info?.master_spool && <Tag status="pending">Master Spool</Tag>}
        suffixString={isLinearEncoder ? " (Linear)" : " (Rotary)"}
      />

      <div className="flex h-[70vh] w-full">
        {type === "front_edge" || type === "back_edge" ? (
          <LineChart
            key={`${type}-${routineId}-${isLinearEncoder}`}
            data={data}
            xzData={xzData}
            settings={{
              guides: guides,
              tooltip: { displayLineName: true },
              focusPoints: { numberOfPoints: 1, type: "delaunay" },
              zeroGuides: {
                x: isLinearEncoder ? undefined : true,
                y: isLinearEncoder ? true : undefined,
              },
              // TODO: We need to find a way to set chart limits for S4 variants based on Base SKU
            }}
          />
        ) : type === "flow_angle" ? (
          <LineChart
            data={data}
            settings={{
              labels: { x: xLabelMap[type], y: yLabelMap[type] },
              focusPoints: { numberOfPoints: 1, type: "delaunay" },
            }}
          />
        ) : (
          // the only other case is "leakage"
          <LineChart
            data={data}
            xzData={xzData}
            settings={{
              focusPoints: { numberOfPoints: 2, type: "delaunay" },
              limits: {
                yMin: 0,
                zMin: 0,
                zMax: 120,
              },
              tooltip: {
                displayLineName: false,
                displayDistance: 5,
              },
            }}
          />
        )}
      </div>
    </div>
  );
}

export interface EMPlotProps {
  routineId: string;
  type: EMRoutineNamesType;
  lastModified: string;
  valveType?: ValveType;
  isLinear: boolean;
}
export default function EMPlotWrapper({
  routineId,
  type,
  lastModified,
  valveType,
  isLinear,
}: EMPlotProps) {
  const [isLinearValve, setIsLinearValve] = useState(!!isLinear);

  const { data, isLoading, isError } = useEdgeMatchingRoutineData({
    id: routineId,
    type: type,
    linear: isLinear,
    base_sku: undefined
  });

  useEffect(() => {
    if (data && data.data) {
      if (
        isMultiline(data.data) &&
        data.data["p_s1"][0]["position"] === undefined
      ) {
        setIsLinearValve(false);
      } else if (
        isSingleLine(data.data) &&
        data.data[0]["position"] === undefined
      ) {
        setIsLinearValve(false);
      } else if (!data && !isLoading && !isError) {
        setIsLinearValve(false);
      }
    }
  }, [data, isLoading, isError]);

  if (isLinear !== isLinearValve) {
    return null;
  }
  return (
    <div className="w-full pb-8">
      {isLoading ? (
        <div className="w-full h-[70vh] flex flex-col justify-center items-center">
          <p className="animate-pulse">{`Loading ${isLinearValve ? "Linear" : "Rotary"} Chart`}</p>
          <Loading className="w-full h-full" />
        </div>
      ) : isError ? (
        <div>Something went wrong with routine: {routineId}</div>
      ) : (
        data && (
          <EMChart
            key={`${type}-${routineId}-${isLinear ? "linear" : "rotary"}`}
            data={data.data}
            guides={data.guides}
            info={data.info}
            isLinearEncoder={isLinearValve}
            lastModified={lastModified}
            routineId={routineId}
            type={type}
          />
        )
      )}
    </div>
  );
}
