import { PASRoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types";

const routineDataPointMapping: { [key in PASRoutineNamesType]: string[] } = {
  motor_calibration: ["theta_offset", "motor_current"],
  flow_angle: [
    "angle",
    "flow_up",
    "flow_down",
    "temperature_up",
    "temperature_down",
    "flow_average",
  ],
  flow_demand: [
    "flow_up",
    "flow_down",
    "demand",
    "temperature_up",
    "temperature_down",
    "flow_average",
  ],
  step_response: ["", "", ""],
  frequency_response: ["", "", ""],
  leakage: [
    "supply_pressure",
    "return_pressure",
    "service_1_pressure",
    "service_2_pressure",
    "demand",
    "feedback",
    "leakage",
    "pressure_gain",
  ],
  pressure_gain: [
    "supply_pressure",
    "return_pressure",
    "service_1_pressure",
    "service_2_pressure",
    "demand",
    "feedback",
    "leakage",
    "pressure_gain",
  ],
  slow_sine: ["demand_down", "demand_up", "flow_down", "flow_up"],
  bias_position: [],
  fail_safe_position_leakage: [],
};

export default routineDataPointMapping;
