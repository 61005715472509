import { loginRequest, msalConfig } from "auth/authConfig";
import axios, { InternalAxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { PublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { CustomErrorType } from "types/responseTypes";

export const msalInstance = new PublicClientApplication(msalConfig);

const getTokenInterceptor =
  (msalInstance: PublicClientApplication, loginRequest: SilentRequest) =>
  async (config: InternalAxiosRequestConfig<any>) => {
    await msalInstance.initialize();
    const accounts = msalInstance.getAllAccounts();
    const accessTokenRequest: SilentRequest = accounts.length
      ? { ...loginRequest, account: accounts[0] }
      : loginRequest;
    try {
      const response =
        await msalInstance.acquireTokenSilent(accessTokenRequest);
      const { accessToken } = response;
      config.headers.Authorization = `Bearer ${accessToken}`;
    } catch (error) {
      console.log(error);
    }
    return config;
  };

export const client = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
});

client.interceptors.request.use(
  getTokenInterceptor(msalInstance, loginRequest),
);

export const logError = ({ message, code, status_code }: CustomErrorType) => {
  const errorMessage = message ?? status_code ?? "unknown";
  console.error({ code, status_code, message });
  return toast.error(`Error ${code} - ${status_code}: ${errorMessage}`);
};
