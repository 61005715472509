import { FilterPASRoutinesRespType } from "types/responseTypes";
import {
  CompareDropdownItemsType,
  handleSelectRoutineProps,
} from "types/valveIntelligenceTypes";
import DisplayRoutineRow from "./DisplayRoutineRow";
import { memo, useRef, useState } from "react";
import { formatRoutineTitle } from "helpers/functions/shared/formatRoutineName";
import { formatDate } from "helpers/functions/shared/formatDate";
import { formatRoutineOptions } from "helpers/functions/shared/formatRoutineOptions";
import CompareSingleChart from "../ComparePlotData";
import { Icon, Loading, useOutsideMouseDown } from "@domin-frontend/domin-ui";
import OptionsItem from "tempComponents/OptionsItem";

const MemoDisplayRoutineRow = memo(DisplayRoutineRow);

interface RoutineCompareItemProps {
  routine: CompareDropdownItemsType;
  row: number;
  edit: boolean;
  setEdit: () => void;
  column: number;
  filteredRoutinesData: FilterPASRoutinesRespType[] | undefined;
  isRoutinesLoading: boolean;
  totalColumns: number;
  setRoutines: ({
    routine,
    column,
    row,
    remove,
  }: handleSelectRoutineProps) => void;
}

export default function RoutineCompareItem({
  row,
  edit,
  setEdit,
  filteredRoutinesData,
  isRoutinesLoading,
  totalColumns,
  routine,
  column,
  setRoutines,
}: RoutineCompareItemProps) {
  const optionsRef = useRef<HTMLDivElement>(null);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const filteredRoutineData = filteredRoutinesData?.[row];

  useOutsideMouseDown(optionsRef, () => {
    setOptionsOpen(false);
  });

  return (
    <div className="relative flex flex-col gap-2">
      <div className="flex items-center">
        {isRoutinesLoading ? (
          <div className="w-20 h-9 flex items-center justify-start">
            <Loading size={20} />
          </div>
        ) : (
          filteredRoutineData && (
            <MemoDisplayRoutineRow
              setEdit={setEdit}
              name={
                filteredRoutineData.routine_id
                  ? `${formatRoutineTitle(routine.routine_type)} - ${
                      routine.last_modified
                        ? formatDate(routine.last_modified)
                        : ""
                    }`
                  : ""
              }
              editable={edit}
              options={formatRoutineOptions(filteredRoutinesData ?? [])}
              value={filteredRoutineData.routine_id}
              onChange={(e) => {
                const routine = filteredRoutinesData?.find(
                  (item) => item?.routine_id === e.value,
                );
                if (routine && e) {
                  setRoutines({ routine, column, row: row });
                }
              }}
            />
          )
        )}

        <div
          className={`relative w-10 flex justify-end items-center cursor-pointer mb-2 ${
            optionsOpen ? "" : "opacity-0 hover:opacity-100 transition-opacity"
          }`}
          ref={optionsRef}
          onClick={() => setOptionsOpen(!optionsOpen)}
        >
          {optionsOpen && (
            <div className="absolute top-8 right-0 z-10 bg-neutral-0 shadow rounded-sm flex flex-col">
              <OptionsItem
                className="!text-danger-600"
                onClick={() => {
                  // delete routine from column
                  setRoutines({
                    routine: routine,
                    column: column,
                    row: row,
                    remove: true,
                  });
                  setOptionsOpen(false);
                }}
              >
                Delete
              </OptionsItem>
            </div>
          )}
          <Icon name="options" />
        </div>
      </div>

      <CompareSingleChart
        type={routine.routine_type}
        routine_id={routine.routine_id}
        columns={totalColumns}
      />
    </div>
  );
}
