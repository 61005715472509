import { LineChart, isMultiline } from "@domin-frontend/domin-ui";
import {
  LineDataType,
  MultilineType,
} from "@domin-frontend/domin-ui/dist/esm/types";
import { useEffect, useState } from "react";
import { RoutineDataRespType } from "types/responseTypes";
import { makeChartTitle } from "../PASValveView/PASCharts/TitleChartArea";
import FlowDemandChart from "../PASValveView/PASCharts/FlowDemandChart";
import { xLabelMap, yLabelMap } from "../PASValveView/PASCharts/labelFormatMap";
import StepResponseChart from "../PASValveView/PASCharts/StepResponseChart";
import { formatDigits } from "helpers/functions/shared/formatDigits";

interface FRCustomerChartProps {
  xyData: MultilineType | LineDataType[];
  xzData?: MultilineType;
}
interface WrapperProps {
  children: React.ReactNode;
  title: string;
  info?: string;
}

interface CustomerChartProps {
  data?: RoutineDataRespType;
  xzData?: MultilineType;
}

// 400 - 37 =

function Wrapper({ children, title, info }: WrapperProps) {
  return (
    <div className="flex flex-col justify-center gap-4 h-[400px] items-start">
      <p className="text-2xl text-primary-700  w-full">{title}</p>
      {info && <p>{info}</p>}
      <div className={`w-full h-full px-12`}>{children}</div>
    </div>
  );
}

function FRCustomerChart({ xyData, xzData }: FRCustomerChartProps) {
  return (
    <LineChart
      data={xyData}
      xzData={xzData}
      routineType="frequency_response"
      settings={{
        zoom: {
          maxScale: 50,
        },
        limits: {
          xMin: 40,
          xMax: 100,
          yMin: -12,
          yMax: 3,
          zMin: -150,
          zMax: 0,
        },
        ticks: {
          x: [1, 10, 100, 1000],
        },
        labels: { x: xLabelMap["frequency_response"] },
        exponential: { x: true },
        focusPoints: { type: "delaunay" },
        zeroGuides: { x: false, y: false },
        gridline: true,
        line: {
          xzDash: true,
        },
        reverseAxis: {
          z: true,
        },
        hideSettings: true,
        margins: {
          bottom: 40,
        },
        legend: {
          show: true,
          initialPosition: {
            x: 60,
            y: 140,
          },
        },
        borders: { top: true },
      }}
    />
  );
}

export default function CustomerReportChart({
  data,
  xzData,
}: CustomerChartProps) {
  const [chartData, setChartData] = useState<
    LineDataType<string>[] | MultilineType | undefined
  >(undefined);
  const [xzLocalData, setXZLocalData] = useState<MultilineType | undefined>(
    undefined,
  );

  useEffect(() => {
    if (data) {
      setChartData(data.data);
    }
    if (xzData) {
      setXZLocalData(xzData);
    }
  }, [data, xzData]);

  if (!chartData || !data) {
    return null;
  }

  return (
    <>
      {data.routine_type === "flow_demand" &&
        isMultiline(chartData) &&
        chartData && (
          <Wrapper
            title={makeChartTitle(data.routine_type, data.test_settings, 5)}
          >
            <FlowDemandChart
              data={chartData}
              guides={data.guides}
              percentage={5}
              type="flow_demand"
              guideArea={false}
              hideSettings={true}
              borders={{ top: true }}
            />
          </Wrapper>
        )}
      <Wrapper
        title={makeChartTitle(
          data.routine_type,
          data.test_settings,
          data.routine_type === "flow_demand" ? 100 : undefined,
        )}
        info={
          data.routine_type === "pressure_gain"
            ? `Pressure Gain: ${
                formatDigits(
                  data?.specs?.find((t) => t.name === "Pressure Gain Gradient")
                    ?.actual,
                ) || "N/A"
              }%, Hysteresis: ${
                formatDigits(
                  data?.specs?.find((t) => t.name === "Hysteresis")?.actual,
                ) || "N/A"
              }%`
            : data.routine_type === "leakage"
              ? `Max Leakage: ${
                  formatDigits(
                    data?.specs?.find((t) => t.name === "On Centre Max Leakage")
                      ?.actual,
                  ) || "N/A"
                } LPM`
              : undefined
        }
      >
        {data.routine_type === "flow_demand" ? (
          <LineChart
            data={chartData}
            routineType={data.routine_type}
            settings={{
              labels: {
                x: xLabelMap[data.routine_type],
                y: yLabelMap[data.routine_type],
              },
              guides: data.guides,
              focusPoints: { type: "delaunay" },
              gridline: true,
              hideSettings: true,
              borders: { top: true },
              zeroGuides: { center: true },
              limits: { xMin: -100, xMax: 100 },
            }}
          />
        ) : data.routine_type === "step_response" && isMultiline(chartData) ? (
          <StepResponseChart
            data={chartData}
            hideSettings
            initialLines={[
              { from: 0, to: 50, selected: true },
              { from: 0, to: 100, selected: true },
            ]}
            limits={{ yMin: 0 }}
            legend={{
              show: true,
              initialPosition: {
                x: 380,
                y: 250,
              },
            }}
          />
        ) : data.routine_type === "frequency_response" ? (
          <FRCustomerChart xyData={chartData} xzData={xzLocalData} />
        ) : data.routine_type === "pressure_gain" ? (
          <LineChart
            data={chartData}
            xzData={xzLocalData}
            routineType={data.routine_type}
            settings={{
              labels: {
                x: xLabelMap["pressure_gain"],
                y: yLabelMap["pressure_gain"],
              },
              limits: { yMin: -102, yMax: 102 },
              focusPoints: { type: "delaunay", numberOfPoints: 1 },
              gridline: true,
              line: {
                xzDash: true,
                zColourArray: ["#00AEC760", "#ca1c4660"],
              },
              hideSettings: true,
              zeroGuides: { center: true },
              legend: {
                show: true,
                initialPosition: {
                  x: 60,
                  y: 20,
                },
              },
            }}
          />
        ) : data.routine_type === "leakage" ? (
          <LineChart
            data={chartData}
            xzData={xzLocalData}
            routineType={data.routine_type}
            settings={{
              labels: { x: xLabelMap["leakage"], y: yLabelMap["leakage"] },
              limits: {
                yMin: 0,
              },
              gridline: true,
              tooltip: {
                displayLineName: false,
              },
              line: {
                xzDash: true,
                zColourArray: ["#00AEC760", "#ca1c4660"],
                mean: {
                  xyLines: {
                    mean: true,
                    reverse: [0],
                  },
                },
              },
              hideSettings: true,
              // borders: { top: true },
            }}
          />
        ) : null}
      </Wrapper>
    </>
  );
}
