import { ErrorMessage, LineChart, Loading } from "@domin-frontend/domin-ui";
import { RoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types/testTypes";
import { SingleLineChart } from "components/PAS/PASValveView/PASCharts/SinglePASChart";
import { calculateMotorCalibrationLimits } from "helpers/functions/calculateMCYLimits";
import { useRoutineData } from "queries/PASQueries";
import { useState } from "react";

interface CompareChartsProps {
  routine_id: string;
  type: RoutineNamesType;
  columns: number;
}

export default function CompareSingleChart({
  type,
  columns,
  routine_id,
}: CompareChartsProps) {
  const [customChart] = useState(false);
  const {
    data: routineData,
    isLoading,
    isError,
  } = useRoutineData(routine_id, type);

  if (isLoading || !routineData) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  if (isError) {
    return <ErrorMessage>Failed to load routine data</ErrorMessage>;
  }

  if (["frequency_response", "step_response"].includes(type) || !customChart) {
    return (
      <SingleLineChart
        height={20 + 10 / columns}
        data={routineData?.data}
        xzData={routineData?.xz_data}
        type={type}
        guides={routineData?.guides}
        valveVariant={routineData?.valve_type}
      />
    );
  }
  return (
    <LineChart
      data={routineData?.data}
      settings={{
        labels: { x: "Time [s]", y: "Flow Angle [deg]" },
        guides: routineData?.guides,

        limits:
          type === "motor_calibration" && routineData.valve_type
            ? calculateMotorCalibrationLimits(routineData.valve_type)
            : undefined,
      }}
    />
  );
}
