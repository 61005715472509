import { useLocation } from "react-router-dom";
import { NavSectionType } from "@domin-frontend/domin-ui/dist/esm/types";
import { loginRequest } from "auth/authConfig";
import { Nav } from "@domin-frontend/domin-ui";
import { msalInstance } from "components/api";

export default function VTPLayout() {
  const username = msalInstance.getAllAccounts()[0]?.name ?? undefined;

  const { pathname: route } = useLocation();

  const navItems: NavSectionType[] = [
    {
      title: "Valve Tests",
      sectionOpen: route?.includes("pas") || route?.includes("edge-matching"),
      items: [
        {
          text: "PAS",
          link: "/pas/dashboard",
          active: route?.includes("pas") && !route?.includes("custom"),
        },
        {
          text: "Edge Matching",
          link: "/edge-matching/dashboard",
          active: route?.includes("edge-matching"),
        },
      ],
    },
    {
      title: "Valve Intelligence",
      sectionOpen: route?.includes("intelligence"),
      items: [
        {
          text: "Valve Compare",
          link: "/valve-intelligence/valve-compare",
        },
        {
          text: "Custom Charts",
          link: "/valve-intelligence/pas-custom-chart",
        },
      ],
    },
  ];

  return (
    <Nav
      sections={navItems}
      loginRequest={loginRequest}
      user={username}
      settings={{
        options: [
          {
            text: "Feature Request",
            link: `${process.env.REACT_APP_FEAT_REQUEST_LINK}?prefill_Apps=Valve+Test+Portal`,
            alwaysVisible: true,
            external: true,
          },
          {
            text: "VTP 1.0",
            link: "https://vtportal.domin.co/",
            external: true,
          },
        ],
      }}
    />
  );
}
