import { MultilineType } from "@domin-frontend/domin-ui/dist/esm/types";

/*
 * Calculate the range of the chart based on the percentage
 */
export function calculateRangePercentage(
  data: MultilineType,
  percentage: number
) {
  // data can either have flow_down or FlowAverage

  const flowAverage = data.flow_average ?? data.FlowAverage ?? undefined;

  if (flowAverage) {
    return (
      (Math.max(...flowAverage.map((d) => d.flow_average)) -
        Math.min(...flowAverage.map((d) => d.flow_average))) *
      (percentage * 0.01)
    );
  }

  return (
    (Math.max(...data.flow_down.map((d) => d.flow_down)) -
      Math.min(...data.flow_down.map((d) => d.flow_down))) *
    (percentage * 0.01)
  );
}
