import { EMRoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { client, logError } from "components/api";
import { toast } from "react-toastify";
import {
  AnalyseDecisionRequestType,
  AnalyseDecisionResponseType,
  CutsRequestType,
  EMItemModel,
  EMListResponseType,
  EMRoutineDataRespType,
  MatchStatusRequestType,
  MatchStatusResponseType,
  SaveCutsResponseType,
} from "types/EMTypes";

// axios query functions

async function getEdgeMatchingList(page?: number, search?: string) {
  const response = await client.post<EMListResponseType>(
    "/edge-matching/get-em-tests",
    {
      page: page ?? 1,
      page_size: 10,
      search: search,
      sort_order: "desc",
      sort_by: "last_modified",
    }
  );
  return response.data;
}

async function getEdgeMatchingInfo(
  manifoldNumber?: string,
  spoolNumber?: string
) {
  if (!manifoldNumber || !spoolNumber) {
    return;
  }
  const response = await client.get<EMItemModel>("/edge-matching/get-em-info", {
    params: { manifold_number: manifoldNumber, spool_number: spoolNumber },
  });
  return response.data;
}

async function getEdgeMatchingRoutineData({
  id,
  type,
  linear = true,
}: {
  id: string;
  type: EMRoutineNamesType;
  linear: boolean;
}) {
  const response = await client.get<EMRoutineDataRespType>(
    `/edge-matching/get-routine-data`,
    { params: { routine_id: id, routine_type: type, linear: linear } }
  );
  const { data } = response;
  return {
    data: data.data,
    xzData: data.xz_data,
    info: data.info,
    results: data.results,
    guides: data.guides,
  };
}

async function postEdgeMatchingCuttingData({
  request,
  routineId,
  type,
}: {
  request: CutsRequestType;
  routineId: string;
  type: EMRoutineNamesType;
}) {
  const response = await client.post<SaveCutsResponseType>(
    `/edge-matching/add-edge-cut`,
    {
      id: request.id,
      routine_id: routineId,
      routine_type: type,
      base_sku: request.baseSku,
      current_edges: request.currentEdges,
      null_shift: request.nullShift,
    },
    { headers: { "Content-Type": "application/json" } }
  );
  return response.data;
}

// react query hooks

export function useEdgeMatchingList({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) {
  return useQuery({
    queryKey: ["edge-matching-list", page, search],
    queryFn: () => getEdgeMatchingList(page, search),
  });
}

export function useEdgeMatchingInfo(
  manifoldNumber?: string,
  spoolNumber?: string
) {
  return useQuery({
    queryKey: ["edge-matching-info", manifoldNumber, spoolNumber],
    queryFn: () => getEdgeMatchingInfo(manifoldNumber, spoolNumber),
    enabled: !!manifoldNumber,
  });
}

export function useEdgeMatchingRoutineData({
  id,
  type,
  base_sku,
  linear = true,
  enabled = true,
}: {
  id: string;
  type: EMRoutineNamesType;
  linear: boolean;
  enabled?: boolean;
  base_sku?: string;
}) {
  return useQuery({
    queryKey: ["edge-matching-routine-data", id, type, linear, base_sku],
    queryFn: () => getEdgeMatchingRoutineData({ id, type, linear }),
    enabled: !!enabled && !!id && !!type,
  });
}

export function usePostEdgeObjectiveCutData(
  routineId: string,
  type: EMRoutineNamesType
) {
  return useMutation({
    mutationKey: ["edge-matching-cutting-data", routineId],
    mutationFn: (request: CutsRequestType) =>
      postEdgeMatchingCuttingData({
        request: request,
        routineId: routineId,
        type: type,
      }),
    onError: logError,
    onSuccess: (r) =>
      toast.success(
        `Objectives and Cuts Saved for ${r.p_s1 ? "PS1" : ""}${
          r.p_s2 ? "PS2" : ""
        }${r.r_s1 ? "RS1" : ""}${r.r_s2 ? "RS2" : ""} | Match Status ${
          r.saved ? "saved as" : "not saved as"
        } ${r.matchStatus ? r.matchStatus : "invalid match status"}`
      ),
  });
}

async function postMatchStatusData({
  request,
}: {
  request: MatchStatusRequestType;
}) {
  const response = await client.post<MatchStatusResponseType>(
    `/edge-matching/set-match-status`,
    {
      manifold_spool: request.manifold_spool,
      match_status: request.matchStatus,
    },
    { headers: { "Content-Type": "application/json" } }
  );
  return response.data;
}

export function usePostMatchStatusData() {
  return useMutation({
    mutationKey: ["edge-matching-match-status"],
    mutationFn: (request: MatchStatusRequestType) =>
      postMatchStatusData({
        request: request,
      }),
    onError: logError,
    onSuccess: (r) =>
      toast.success(
        `Match Status ${r.saved ? "saved as" : "not saved as"} ${
          r.matchStatus ? r.matchStatus : "invalid match status"
        }`
      ),
  });
}

async function postAnalyseDecision({
  request,
}: {
  request: AnalyseDecisionRequestType;
}) {
  const response = await client.post<AnalyseDecisionResponseType>(
    `/edge-matching/set-analyse-decision`,
    request,
    { headers: { "Content-Type": "application/json" } }
  );
  return response.data;
}

export function usePostAnalyseDecision() {
  return useMutation({
    mutationKey: ["update-airtable-manual-decision"],
    mutationFn: (request: AnalyseDecisionRequestType) =>
      postAnalyseDecision({
        request: request,
      }),
    onError: logError,
    onSuccess: (r) =>
      toast.success(`Analysed: ${r.saved ? r.analyseDecision : "failed"}`),
  });
}
