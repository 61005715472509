import { BiMap } from "@domin-frontend/domin-ui";
import { PASRoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types";

export const routineShortMap = new BiMap<PASRoutineNamesType>({
  flow_angle: "Flow Angle",
  frequency_response: "Freq Resp",
  flow_demand: "Flow Dem",
  leakage: "Leakage",
  motor_calibration: "Motor Cal",
  pressure_gain: "Press Gain",
  step_response: "Step Resp",
  slow_sine: "Slow Sine",
  bias_position: "Bias Pos",
  fail_safe_position_leakage: "Fail Safe P L",
});
