import { BiMap } from "@domin-frontend/domin-ui";
import { RoutineStatusType } from "types/responseTypes";
import {
  QueriesLongType,
  QueriesShortType,
  StatusTypeShort,
} from "types/valveIntelligenceTypes";

export const paramBiMap = new BiMap<QueriesShortType, QueriesLongType>({
  sn: "serial_number",
  vt: "valve_type",
  st: "status",
  rt: "routine_type",
  ri: "routine_id",
  ti: "test_id",
  tt: "total_time",
});

export const statusBiMap = new BiMap<StatusTypeShort, RoutineStatusType>({
  nc: "no completed",
  co: "completed",
  pa: "pass",
  fa: "fail",
  pd: "pass by default",
});
