import { PASRoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types";
import { FilterFieldsPASTypes } from "types/requestTypes";

export type projectFilterTypes = null | "isHyperflow"

export interface FilterSchema {
    hidden_columns: string[],
    filters: FilterFieldsPASTypes[],
    routines: PASRoutineNamesType[],
};

export const defaultFilters: FilterSchema = {
    hidden_columns: [
        "latest_rig", 
        "bias_position", 
        "fail_safe_position_leakage", 
        "result_status"
    ],
    filters: [],
    routines: [
        "motor_calibration",
        "flow_angle",
        "flow_demand",
        "step_response",
        "frequency_response",
        "leakage",
        "pressure_gain",
        "slow_sine",
    ],
}

export const hyperflowFilters: FilterSchema = {
    hidden_columns: [
        "customer",
        "latest_rig",
        "flow_demand",
        "frequency_response",
        "fail_safe_position_leakage",
        "pressure_gain",
    ],
    filters: [
        {
            field: "customer",
            values: [
                "MAN Energy Solutions"
            ],
        },
        {
            field: "sku",
            values: [
                "S6 Pro-40-A-XC-PB-X-Y-R-N-91",
                "S6 Pro-25-A-XC-PB-X-Y-R-N-90",
                "S6 Pro-25-A-XC-PB-X-N-R-N-90"
            ]
        }
    ],
    routines: [
        "motor_calibration",
        "flow_angle",
        "step_response",
        "leakage",
        "bias_position",
    ],
};