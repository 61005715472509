const dominColours = {
  none: "#FFFFFF00",
  neutral: {
    0: "#FFFFFF",
    50: "#FAFAFA",
    100: "#F5F5F5",
    200: "#E8E8E8",
    300: "#D6D6D6",
    400: "#A5A5A5",
    500: "#6B6B6B",
    600: "#575757",
    700: "#424242",
    800: "#2B2B2B",
    900: "#000000",
  },
  primary: {
    100: "#E6F7FA",
    "10%": "#E6F7FA",
    200: "#CCEFF4",
    300: "#99DFE9",
    "20%": "#99DFE9",
    400: "#66CEDD",
    "60%": "#66CEDD",
    500: "#33BED2",
    "80%": "#33BED2",
    600: "#00AEC7",
    "domin-blue": "#00AEC7",
    700: "#20677C",
    "contrast-blue": "#20677C",
    800: "#003E53",
    "domin-dark": "#003E53",
    900: "#00232C",
    "contrast-dark": "#00232C",
  },
  pink: {
    100: "#FCE7F3",
    200: "#FBD0E8",
    300: "#F3A5B8",
    400: "#EA6686",
    500: "#E33561",
    600: "#CA1C46",
  },
  success: {
    100: "#E6FFF3",
    600: "#16A269",
  },
  danger: {
    100: "#FCE7F3",
    600: "#CA1C46",
  },
  pending: {
    200: "#FDE68A",
    700: "#B45309",
  },
};

export default dominColours;
