import { Icon } from "@domin-frontend/domin-ui";
import { IconsType } from "@domin-frontend/domin-ui/dist/esm/types";

interface IconButtonProps {
  onClick: () => void;
  icon?: IconsType;
}

export default function IconButton({ icon, onClick }: IconButtonProps) {
  return (
    <div
      onClick={onClick}
      className="bg-primary-600 hover:bg-primary-700 rounded-full h-8 w-8 flex items-center justify-center stroke-neutral-0 fill-neutral-0 hover:stroke-neutral-200 hover:fill-neutral-200"
    >
      <Icon
        name={icon ?? "plus"}
        className="stroke-[inherit] fill-[inherit] h-4 w-4"
      />
    </div>
  );
}
