import { queryClient } from "components/App";
import { useEffect } from "react";
import { WSMessageType } from "types/WebsocketTypes";

export function useUpdateSubscription() {
  useEffect(() => {
    if (!process.env.REACT_APP_WS_URL) {
      return;
    }
    const socket = new WebSocket(`${process.env.REACT_APP_WS_URL}update`);

    socket.onopen = () => {
      console.log("WebSocket is connected.");
    };

    socket.onmessage = (event) => {
      const data: WSMessageType = JSON.parse(event.data);
      if (data.tag === "pas") {
        queryClient.invalidateQueries({ queryKey: ["valve-info", data.data] });
        queryClient.invalidateQueries({ queryKey: ["pas-list"] });
      } else if (data.tag === "edge_matching") {
        queryClient.invalidateQueries({
          queryKey: ["edge-matching-info", data.data],
        });
        queryClient.invalidateQueries({ queryKey: ["edge-matching-list"] });
      } else {
        console.error("Unknown tag: ", data);
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      console.log("WebSocket is closed.");
    };

    return () => {
      socket.close();
    };
  }, []);
}
