import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  CompareDropdownItemsType,
  FilterFieldsPASObjectType,
  QueriesLongType,
} from "types/valveIntelligenceTypes";
import routineBiMap from "./maps/routineTypeBiMap";
import { paramBiMap } from "./maps/searchParamsBiMap";
import { FilterFieldsPASReqType } from "types/requestTypes";
import { formatValuesToShort } from "./maps/getValueHelper";

export function formatToSearchParams(
  routines: CompareDropdownItemsType[][],
  filterQueries: FilterFieldsPASObjectType
): URLSearchParams {
  const filterParams = new URLSearchParams();
  const routineParams = new URLSearchParams();

  Object.entries(filterQueries).forEach(([columnKey, filterQuery]) => {
    if (!filterQuery) {
      return;
    }
    const column = columnKey.slice(1); // remove 'col' prefix
    Object.entries(filterQuery as FilterFieldsPASReqType).forEach(
      ([key, value]) => {
        if (value) {
          filterParams.append(
            `${paramBiMap.getKey(key as QueriesLongType)}${column}`,
            formatValuesToShort(key as QueriesLongType, value)
          );
        }
      }
    );
  });

  routines.forEach((column, i) => {
    if (!column?.length) {
      return;
    }
    column.forEach((row, j) => {
      const shortRoutine = routineBiMap.get(row.routine_type);
      const unixTime = Math.floor(
        new Date(row.last_modified).getTime() / 60000
      );
      routineParams.append(
        `c-${i}_r-${j}`,
        `${row.routine_id}_${shortRoutine}_${unixTime}`
      );
    });
  });

  const params = new URLSearchParams(
    filterParams.toString() + "&" + routineParams.toString()
  );

  return params;
}
export function formatSearchFormToSearchParams() {}

// Custom hook to update the search params when the selected routines change
export function useUpdateSearchParams({
  selectedRoutines,
  filterQueries,
}: {
  selectedRoutines: CompareDropdownItemsType[][];
  filterQueries: FilterFieldsPASObjectType;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  // update selected routines only with the routines that are different from the current selected routines
  const newSearchParams = formatToSearchParams(selectedRoutines, filterQueries);

  // update the search params if the selected routines are different from the current selected routines
  useEffect(() => {
    const areSearchParamsDifferent =
      searchParams.toString() !== newSearchParams.toString();

    if (selectedRoutines && areSearchParamsDifferent) {
      setSearchParams(newSearchParams);
    } else if (!selectedRoutines && searchParams.toString()) {
      setSearchParams(new URLSearchParams());
    }
  }, [
    selectedRoutines,
    newSearchParams,
    searchParams,
    setSearchParams,
    filterQueries,
  ]);
}
