import { QueriesLongType, StatusTypeShort } from "types/valveIntelligenceTypes";
import routineBiMap from "./routineTypeBiMap";
import {
  RoutineNamesType,
  RoutineNamesType_short,
  ValveType,
  ValveType_short,
} from "@domin-frontend/domin-ui/dist/esm/types";
import { statusBiMap } from "./searchParamsBiMap";
import { ValveBiMap } from "@domin-frontend/domin-ui";
import { RoutineStatusType } from "types/responseTypes";

export function formatValuesToShort(
  key: QueriesLongType,
  value: string | number,
): string {
  let result: string | undefined = undefined;
  if (key === "routine_type") {
    result = routineBiMap.getKey(value as RoutineNamesType);
  }
  if (key === "status") {
    result = statusBiMap.getKey(value as RoutineStatusType);
  }
  if (key === "valve_type") {
    result = ValveBiMap.getValue(value as ValveType);
  }
  if (result) {
    return result;
  }
  return value.toString();
}

export function formatValuesToLong(
  key: QueriesLongType,
  value: string,
): string {
  let result: string | undefined = undefined;
  if (key === "routine_type") {
    result = routineBiMap.getValue(value as RoutineNamesType_short);
  }
  if (key === "status") {
    result = statusBiMap.getValue(value as StatusTypeShort);
  }
  if (key === "valve_type") {
    result = ValveBiMap.getKey(value as ValveType_short);
  }
  if (result) {
    return result;
  }
  return value;
}
