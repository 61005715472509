import { initialValues } from "components/pages/ValveCompare";
import { SetStateAction } from "react";
import {
  FilterFieldsPASObjectType,
  HandleFilterPropsType,
} from "types/valveIntelligenceTypes";

export interface UpdateFiltersProps extends HandleFilterPropsType {
  setFilterQueries: React.Dispatch<SetStateAction<FilterFieldsPASObjectType>>;
  totalColumns: number;
}

/**
 * This function updates the filter queries object in the state based on the column and filter passed in
 * @param column - the column to update
 * @param filter - the filter to update
 * @param remove - boolean to remove the filter
 * @param setFilterQueries - the function to set the filter queries
 */
export function updateFilterQueries({
  column,
  totalColumns,
  filter,
  remove,
  setFilterQueries,
}: UpdateFiltersProps) {
  if (column < 1 || column > 3) {
    return;
  }
  const columnKey = `c${column}` as keyof FilterFieldsPASObjectType;
  setFilterQueries((prev) => {
    let newFilterQueries = { ...prev };

    if (remove) {
      if (totalColumns === 1) {
        newFilterQueries = { c1: initialValues };
      } else if (totalColumns === 2) {
        if (column === 1) {
          newFilterQueries = { c1: newFilterQueries.c2 };
        } else if (column === 2) {
          delete newFilterQueries.c2;
        }
      } else if (totalColumns === 3) {
        if (column === 1) {
          newFilterQueries = {
            c1: newFilterQueries.c2,
            c2: newFilterQueries.c3,
          };
        } else if (column === 2) {
          newFilterQueries = {
            c1: newFilterQueries.c1,
            c2: newFilterQueries.c3,
          };
        } else if (column === 3) {
          delete newFilterQueries.c3;
        }
      }
      return newFilterQueries;
    } // this is to add a new filter, so it has to have a filter
    else if (
      filter &&
      Object.keys(filter).some((value) => value !== undefined)
    ) {
      // Replace or add the filter at the column key
      newFilterQueries[columnKey] = filter;
    } else {
      // if the filter is empty, add the initial values
      newFilterQueries[columnKey] = initialValues;
    }
    // handle edit state and set any other edit states to false, this can't handle new filters, that is why we have the if statement below
    return newFilterQueries;
  });
}
