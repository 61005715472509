import { ContextMenu, Tag } from "@domin-frontend/domin-ui";
import TableInfoHeader from "components/SharedComponents/TableInfoHeader";
import { tagStatusMap } from "helpers/functions/shared/statusMap";
import { ReactNode, useState } from "react";
import { toast } from "react-toastify";
import { PasRoutineInfoType, RoutineStatusType } from "types/responseTypes";

// Components to have consistent styling
export function InfoName({ children }: { children: ReactNode }) {
  return <td className="whitespace-nowrap  pl-1">{children}</td>;
}

export function InfoValue({ children }: { children: ReactNode }) {
  return <td className="whitespace-nowrap text-end pr-4">{children}</td>;
}

// Format duration string
export function formatDuration(durationString: string) {
  const [hours, minutes, secondsAndMs] = durationString.split(":");
  let [seconds, ms] = secondsAndMs.split(".");
  if (Math.round(Number(ms) / 100) === 1) {
    seconds = String(Number(seconds) + 1);
  }
  return `${hours !== "0" ? `${hours}:` : ""}${minutes}:${seconds}`;
}

function RoutineInfoRow({
  name,
  children,
}: {
  name: string;
  children: ReactNode;
}) {
  return (
    <tr className="flex justify-between items-center h-8 hover:bg-primary-600 hover:text-neutral-0 transition-colors">
      <InfoName>{name}</InfoName>
      {children}
    </tr>
  );
}

interface Props {
  info: PasRoutineInfoType;
  status?: RoutineStatusType;
}

export default function PASRoutineInfoTable({ info, status }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <ContextMenu
        dropdown={[
          <p
            key={info.routine_id}
            className="bg-neutral-0 shadow p-2 cursor-text"
            // copy to clipboard
            onClick={() => {
              navigator.clipboard.writeText(info.routine_id ?? "");
              toast.success("Routine ID copied to clipboard");
            }}
          >
            Routine ID: {info.routine_id ?? ""}
          </p>,
        ]}
      >
        <TableInfoHeader onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
          Routine Info
        </TableInfoHeader>
      </ContextMenu>
      {isOpen && (
        <table
          className={`transition-all flex flex-col gap-y-4 overflow-hidden ${
            isOpen ? "scale-y-100 h-auto" : "scale-y-0 w-0 h-0"
          }`}
        >
          <tbody>
            {status && (
              <RoutineInfoRow name="Status">
                <td className="flex justify-end items-center">
                  <Tag status={tagStatusMap[status]} dominColours>
                    {status}
                  </Tag>
                </td>
              </RoutineInfoRow>
            )}
            {info.version && (
              <RoutineInfoRow name="Version">
                <InfoValue>{info.version}</InfoValue>
              </RoutineInfoRow>
            )}
            {info.firmware_version && (
              <RoutineInfoRow name="Firmware Version">
                <InfoValue>{info.firmware_version ?? ""}</InfoValue>
              </RoutineInfoRow>
            )}
            {info.rig && (
              <RoutineInfoRow name="Rig">
                <InfoValue>{info.rig ?? ""}</InfoValue>
              </RoutineInfoRow>
            )}
            {info.duration && (
              <RoutineInfoRow name="Duration">
                <InfoValue>{formatDuration(info.duration ?? "")}</InfoValue>
              </RoutineInfoRow>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}
