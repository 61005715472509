import { ErrorMessage, Loading } from "@domin-frontend/domin-ui";
import { useRoutineData } from "queries/PASQueries";
import { memo } from "react";
import RoutineSpecs from "../PASRoutineItems/RoutineSpecs";
import PASRoutineInfoTable from "../PASRoutineItems/PASRoutineInfoTable";
import { RoutineRespItemType, RoutineStatusType } from "types/responseTypes";
import { SingleLineChart } from "./PASCharts/SinglePASChart";
import TestSettingsTable from "components/SharedComponents/TestSettingsTable";
import FirmwareSettingsTable from "components/SharedComponents/FirmwareSettingsTable";
import { PASRoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types";
const MemoSingleLineChart = memo(SingleLineChart);

interface Props {
  routines: RoutineRespItemType[];
  type: PASRoutineNamesType;
}

function SingleRoutineChart({
  routine_id,
  type,
  status,
  last_modified,
}: {
  routine_id: string;
  type: PASRoutineNamesType;
  status?: RoutineStatusType;
  last_modified: string;
}) {
  const { data, isLoading } = useRoutineData(routine_id, type);

  return (
    <div className="relative flex flex-col xl:!flex-row gap-4">
      {/* Chart area */}
      {isLoading ? (
        <div className="min-h-[70vh] w-full flex items-center justify-center">
          <Loading />
        </div>
      ) : data ? (
        <div className="relative w-full rounded-sm">
          <MemoSingleLineChart
            data={data?.data}
            xzData={data?.xz_data}
            type={type}
            guides={data?.guides}
            title={{
              lastModified: last_modified,
              routineId: routine_id,
              testSettings: data?.test_settings,
            }}
            valveVariant={data?.valve_type}
          />
        </div>
      ) : (
        <div className="w-full flex justify-center p-4">
          <ErrorMessage>No data to display</ErrorMessage>
        </div>
      )}
      {/* info area */}
      {(data?.info || data?.specs) && (
        <div
          id="routine-info"
          className="relative min-w-fit max-h-[60vh] h-full overflow-y-auto overflow-x-hidden"
        >
          {data?.info && (
            <PASRoutineInfoTable
              info={{ ...data.info, routine_id: routine_id }}
              status={status}
            />
          )}
          {data?.specs && (
            <RoutineSpecs specs={data.specs} routineType={type} />
          )}
          {data?.firmware_settings && (
            <FirmwareSettingsTable data={data.firmware_settings} />
          )}
          {data?.test_settings && (
            <TestSettingsTable data={data.test_settings} />
          )}
        </div>
      )}
    </div>
  );
}
export default function PASChartsArea({ routines, type }: Props) {
  if (!routines) {
    return null;
  }

  // display routines in inverse order based on last_modified
  const orderedRoutines = routines.sort(
    (a, b) =>
      new Date(b.last_modified).getTime() - new Date(a.last_modified).getTime(),
  );

  return (
    <div
      className={`flex flex-col gap-12 ${
        orderedRoutines.length > 1 ? "pb-24" : "pb-4"
      }`}
    >
      {orderedRoutines.map((r, i) => (
        <SingleRoutineChart
          key={`${r.routine_id}-${i}`}
          status={r.status}
          last_modified={r.last_modified}
          routine_id={r.routine_id}
          type={type}
        />
      ))}
    </div>
  );
}
