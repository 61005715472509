import { Tag } from "@domin-frontend/domin-ui";
import { BasicStatusType } from "@domin-frontend/domin-ui/dist/esm/types";
import { formatDate } from "helpers/functions/shared/formatDate";
import InfoRow from "tempComponents/infoTable/InfoRow";
import InfoTableCard from "tempComponents/infoTable/InfoTableCard";
import { PASValveInfoType } from "types/responseTypes";

function calculateAltStatus(altStatus?: string): {
  status: BasicStatusType;
  text: string;
} {
  if (altStatus?.toLocaleLowerCase().includes("complete")) {
    return { status: "success", text: "Completed" };
  } else if (altStatus?.toLocaleLowerCase().includes("required")) {
    return { status: "danger", text: "Incomplete" };
  }
  return { status: "idle", text: "No Data" };
}

interface Props {
  data: PASValveInfoType;
}

export default function PASValveInfoTable({ data }: Props) {
  const { status: ALTStatus, text: ALTText } = calculateAltStatus(
    data.alt_status,
  );
  return (
    <InfoTableCard>
      <InfoRow name="Valve Type" value={data.valve_type} />
      <InfoRow name="SKU" value={data.sku} />
      <InfoRow name="Last Tested" value={formatDate(data.last_modified)} />
      <InfoRow name="Customer" value={data.customer} />
      <tr className="text-sm flex gap-2 justify-between">
        <td className="py-[6px]">ALT Status</td>
        <td>
          <Tag status={ALTStatus} dominColours>
            {ALTText}
          </Tag>
        </td>
      </tr>
    </InfoTableCard>
  );
}
