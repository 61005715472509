import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/App";
import { init as initApm } from "@elastic/apm-rum";

initApm({
  serviceName: process.env.REACT_APP_ELASTIC_APM_SERVICE_NAME,
  serverUrl: process.env.REACT_APP_ELASTIC_APM_SERVER,
  serviceVersion: process.env.REACT_APP_VERSION,
  environment: process.env.NODE_ENV,
  distributedTracingOrigins: [
    process.env.REACT_APP_ELASTIC_APM_SERVER ?? "",
    process.env.REACT_APP_API_URL ?? "",
    process.env.REACT_APP_AUTH_API ?? "",
  ],
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

// add word 'develop' to title if running in development mode
if (process.env.NODE_ENV === "development") {
  document.title = "(develop) " + document.title;
}
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
