function countDecimals(num: number) {
  const decimalPart = String(num).match(/\.\d+$/);
  if (decimalPart) {
    return decimalPart[0].length - 1; // Subtract 1 to exclude the decimal point
  }
  return 0; // No decimals
}

export function formatDigits(
  num: number | undefined | null,
  digits: number = 2,
  average: boolean = true,
) {
  if (num === undefined || num === null) {
    return undefined;
  }
  const local_number = countDecimals(num) > 5 ? +num.toFixed(5) : num;
  if (average) {
    return local_number.toLocaleString("en-US", {
      useGrouping: false,
      // if num has more decimals than digits, use digits as maximum, otherwise use num's decimals
      minimumFractionDigits: Math.min(countDecimals(local_number), digits),
      maximumFractionDigits: digits,
      maximumSignificantDigits: 3,
    });
  } else {
    return num.toString().slice(0, digits + 2);
  }
}
