import { Tag } from "@domin-frontend/domin-ui";
import { BasicStatusType } from "@domin-frontend/domin-ui/dist/esm/types";
import { MatchStatusType } from "../../types/responseTypes";

export type StyleType = {
  [key in MatchStatusType]: BasicStatusType;
};

export const tagStatus: StyleType = {
  undercut: "pending",
  overcut: "danger",
  matched: "idle",
  validated: "success",
  scrapped: "danger",
  remeasure: "idle",
  investigation: "pending"
};

function formatTagValue(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export default function MatchStatusCell({
  value,
}: {
  value: MatchStatusType | undefined;
}) {
    if (value) {
        return (
          <Tag status={tagStatus[value]} dominColours>
            {formatTagValue(value)}
          </Tag>
        );
    } else {
        return (
            <span/>
        )
    }
}