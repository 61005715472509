import { useRef } from "react";
import { ValveTestsPASType } from "types/responseTypes";
import TestItem from "./PASTestDropdownItem";
import { useOutsideMouseDown } from "@domin-frontend/domin-ui";

interface Props {
  selectedTest: ValveTestsPASType;
  tests: ValveTestsPASType[];
  setOpen: (open: boolean) => void;
  setSelected: (id: string) => void;
  open: boolean;
}
export default function PASTestsDropdown({
  selectedTest,
  tests,
  setOpen,
  setSelected,
  open,
}: Props) {
  const ref = useRef(null);
  useOutsideMouseDown(ref, () => setOpen(false));
  return (
    <div ref={ref} className="relative">
      <TestItem
        test={selectedTest}
        onClick={() => setOpen(!open)}
        icon={true}
        open={open}
        isLatestTest={tests[0].test_id === selectedTest.test_id}
      />
      <div
        className={`z-10 transition-shadow absolute w-full top-0  border-x border-b bg-neutral-0 max-h-[50vh] overflow-y-scroll overflow-x-hidden ${
          open
            ? "border-neutral-200 shadow-lg flex flex-col gap-2 pb-1 -translate-x-[1px]"
            : "border-neutral-0 shadow-none"
        }`}
      >
        {open && (
          <>
            <TestItem
              test={selectedTest}
              onClick={() => setOpen(!open)}
              icon={true}
              open={open}
              isLatestTest={tests[0].test_id === selectedTest.test_id}
            />
            {tests
              .filter((t) => t.test_id !== selectedTest.test_id)
              .map((t) => (
                <TestItem
                  key={t.test_id}
                  test={t}
                  onClick={() => setSelected(t.test_id)}
                  isLatestTest={tests[0].test_id === t.test_id}
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
}
