import {
  InfoName,
  InfoValue,
} from "components/PAS/PASRoutineItems/PASRoutineInfoTable";
import TableInfoHeader from "components/SharedComponents/TableInfoHeader";
import { formatDate } from "helpers/functions/shared/formatDate";

interface Props {
  title?: string;
  info: { [key: string]: string | boolean };
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
}

const infoMap: { [key: string]: string } = {
  last_modified: "Last Modified",
  manifold_number: "Manifold Nᵒ",
  spool_number: "Spool Nᵒ",
};

function formatInfoName(name: string) {
  if (name in infoMap) {
    return infoMap[name] ?? name;
  } else {
    return name;
  }
}
export default function RoutineInfoTable({
  info,
  isOpen,
  setIsOpen,
  title,
}: Props) {
  return (
    <div className="w-full">
      <TableInfoHeader
        onClick={setIsOpen ? () => setIsOpen(!isOpen) : undefined}
        isOpen={typeof isOpen === "boolean" ? isOpen : true}
      >
        {title ?? "Info"}
      </TableInfoHeader>
      <table className="w-full">
        <tbody>
          {isOpen &&
            Object.entries(info).map(([key, value], i) => (
              <tr
                key={`${key}-${i}`}
                className="flex gap-2 justify-between hover:bg-primary-600 hover:text-neutral-0 transition-colors w-full"
              >
                <InfoName>{formatInfoName(key)}:</InfoName>
                <InfoValue>
                  {typeof value === "boolean"
                    ? value
                      ? "Yes"
                      : "No"
                    : key === "last_modified"
                      ? formatDate(value)
                      : value}
                </InfoValue>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
