import { BasicStatusType } from "@domin-frontend/domin-ui/dist/esm/types";
import { RoutineStatusType } from "types/responseTypes";

type RoutineStatus = {
    [key in RoutineStatusType]: BasicStatusType;
  };

export const tagStatusMap: RoutineStatus = {
    pass: "success",
    fail: "danger",
    "pass by default": "idle",
    completed: "success",
    "no completed": "idle",
  };
