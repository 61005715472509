import { Label, Modal, Switch, TextInput } from "@domin-frontend/domin-ui";
import { routineNameMapper } from "@domin-frontend/domin-ui/dist/esm/helpers/routineNamesMap";
import { RoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types/testTypes";
import { getToday } from "helpers/functions/shared/formatDate";
import { Dispatch, SetStateAction } from "react";

interface ReportEditModalProps {
  date: string;
  setDate: Dispatch<SetStateAction<string>>;
  setIsOpen: (isOpen: boolean) => void;
  user: { name?: string; email: string };
  setUser: Dispatch<
    SetStateAction<{ name: string | undefined; email: string }>
  >;
  displayTests: { routineType: RoutineNamesType; show: boolean }[];
  setDisplayTests: Dispatch<
    SetStateAction<{ routineType: RoutineNamesType; show: boolean }[]>
  >;
}

export default function ReportEditModal({
  setIsOpen,
  user,
  setUser,
  date,
  setDate,
  displayTests,
  setDisplayTests,
}: ReportEditModalProps) {
  return (
    <Modal title="Settings" onCloseClick={() => setIsOpen(false)}>
      <div className="flex flex-col gap-2">
        <Label htmlFor="customerName">
          Customer name
          <TextInput
            name="customerName"
            value={user.name}
            onChange={(e) =>
              setUser({ ...user, name: e.target.value, email: user.email })
            }
          />
        </Label>
        <Label htmlFor="customerEmail">
          Customer email
          <TextInput
            name="customerEmail"
            value={user.email}
            onChange={(e) =>
              setUser({ ...user, email: e.target.value, name: user.name })
            }
          />
        </Label>
        <Label htmlFor="date">
          <span className="flex justify-between">
            Date
            {date !== getToday() && (
              <span
                className="text-neutral-600 hover:text-neutral-700 cursor-pointer ml-2 text-sm"
                onClick={() => setDate(getToday())}
              >
                today
              </span>
            )}
          </span>
          <TextInput
            name="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </Label>
        <Label htmlFor="routines">
          Display routines
          {displayTests?.map((test) => {
            return (
              <Switch
                className="w-full flex justify-between py-1"
                key={test.routineType}
                checked={test.show}
                name={routineNameMapper(test.routineType)}
                onClick={() => {
                  const newDisplayTests = displayTests.map((t) => {
                    if (t.routineType === test.routineType) {
                      return { ...t, show: !t.show };
                    }
                    return t;
                  });
                  setDisplayTests(newDisplayTests);
                }}
              />
            );
          })}
        </Label>
      </div>
    </Modal>
  );
}
