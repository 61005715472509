import {
  Checkbox,
  Icon,
  TextInput,
  Tooltip,
  useOutsideMouseDown,
} from "@domin-frontend/domin-ui";
import { Dispatch, useRef, MouseEvent, useState, useEffect } from "react";

import { IconsType } from "@domin-frontend/domin-ui/dist/esm/types";
interface Props {
  options: string[];
  title: string;
  displayedTitle?: string;
  selectedOptions: string[];
  onChange: (selectedOptions: string[]) => void;
  open: boolean;
  setOpen: Dispatch<string>;
  icon?: IconsType | true;
  search?: boolean;
  align?: "left" | "right";
  className?: string;
}

export default function THDropdownCheckbox({
  icon,
  title,
  displayedTitle,
  onChange,
  open,
  setOpen,
  options,
  selectedOptions,
  search = true,
  align = "left",
  className = "",
}: Props) {
  const [searchDropdown, setSearchDropdown] = useState("");
  const ref = useRef<HTMLTableCellElement>(null);
  useOutsideMouseDown(
    ref,
    () => {
      if (open) {
        console.log("outside click", ref.current);
        setOpen("");
      }
    },
    {
      ids: ["checkbox", "dropdown"],
    },
  );

  function handleClick(e: MouseEvent) {
    const target = e.target as HTMLInputElement;
    if (
      target.parentElement?.id !== "checkbox" &&
      target.id !== "checkbox" &&
      target.name !== "search dropdown"
    ) {
      setOpen(open ? "" : title);
    }
  }

  // clear search when dropdown is closed
  useEffect(() => {
    if (!open) {
      setSearchDropdown("");
    }
  }, [open]);

  return (
    <th
      onClick={(e) => {
        e.preventDefault();
        handleClick(e);
      }}
      id="dropdown"
      ref={ref}
      className={`h-auto relative first:rounded-tl last:rounded-tr text-left text-neutral-0 text-base  p-2 cursor-pointer ${
        open ? "bg-primary-700" : "bg-primary-600"
      } ${className}`}
    >
      {icon ? (
        <Icon
          name={typeof icon === "string" ? icon : "options"}
          className="w-4 h-4 fill-neutral-0 stroke-neutral-0"
        />
      ) : (
        <span
          className={`flex items-center justify-between pr-2 whitespace-nowrap`}
        >
          {displayedTitle ?? title}
          <Icon
            name="filter"
            className={`transition-transform stroke-current stroke-neutral-0 fill-neutral-0 ${
              selectedOptions.length > 0
                ? "fill-neutral-0"
                : "fill-primary-400 hover:fill-primary-200"
            }`}
          />
        </span>
      )}
      {open && (
        <span
          className={`absolute w-full z-10 top-10 ${align}-0 py-1 bg-neutral-100 shadow h-[30vh] rounded-sm`}
        >
          <span className="flex flex-col h-full  overflow-y-auto px-2  pt-2 pb-4 gap-y-1">
            {/* add input to search if there are more than 8 options */}
            {options.length > 8 && search && (
              <TextInput
                name="search dropdown"
                value={searchDropdown}
                className={`w-full max-w-[20rem] text-neutral-800 ${
                  selectedOptions.length > 0 ? "" : "mb-2"
                } `}
                onChange={(e) => setSearchDropdown(e.target.value)}
              />
            )}
            {selectedOptions.length > 0 && (
              <span
                className="text-primary-700 hover:text-primary-600 cursor-pointer w-full text-end text-sm  pr-1"
                onClick={() => onChange([])}
              >
                Clear filters
              </span>
            )}
            {options
              .filter((option) =>
                option.toLowerCase().includes(searchDropdown.toLowerCase()),
              )
              // sort selected options to the top and then alphabetically
              .sort((a, b) => {
                if (selectedOptions.includes(a)) return -1;
                if (selectedOptions.includes(b)) return 1;
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
              })
              .map((option, i) => (
                <div id="checkbox" key={`${option}${i}`}>
                  <Tooltip
                    key={`${option}${i}`}
                    content={option}
                    minLength={31}
                  >
                    <Checkbox
                      id="checkbox"
                      checked={selectedOptions.includes(option)}
                      value={
                        option.length > 30
                          ? `${option.slice(0, 30)}...`
                          : option
                      }
                      onClick={() => {
                        if (selectedOptions.includes(option)) {
                          onChange(selectedOptions.filter((o) => o !== option));
                        } else {
                          onChange([...selectedOptions, option]);
                        }
                      }}
                    />
                  </Tooltip>
                </div>
              ))}
          </span>
        </span>
      )}
    </th>
  );
}
