import { useEffect, useState } from "react";

export default function useHasReachTop<T extends HTMLElement = HTMLDivElement>(
  ref: React.RefObject<T>,
) {
  const [isReachedTop, setIsReachedTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        setIsReachedTop(ref.current.getBoundingClientRect().top <= 0);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ref]);

  return isReachedTop;
}
