import { ErrorMessage, LineChart, isMultiline } from "@domin-frontend/domin-ui";
import {
  LineDataType,
  MultilineType,
  ValveType,
} from "@domin-frontend/domin-ui/dist/esm/types";
import { RoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types/testTypes";
import { memo, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { xLabelMap, yLabelMap } from "./labelFormatMap";
import StepResponseChart from "./StepResponseChart";
import FlowDemandChart from "./FlowDemandChart";
import BiasPositionChart from "./BiasPositionChart";
import TitleChartArea from "./TitleChartArea";
import { TestSettingsType } from "types/responseTypes";
import { calculateMotorCalibrationLimits } from "helpers/functions/calculateMCYLimits";

const MemoLineChart = memo(LineChart);

export function calculateChartRange(data: MultilineType) {
  const [xLabel, yLabel] = Object.keys(data[Object.keys(data)[0]][0]);
  const xRange = data[Object.keys(data)[0]].map((d) => d[xLabel]);
  const yRange = data[Object.keys(data)[0]].map((d) => d[yLabel]);
  const [xMin, xMax] = [Math.min(...xRange), Math.max(...xRange)];
  const [yMin, yMax] = [Math.min(...yRange), Math.max(...yRange)];
  return {
    x: Math.max(Math.abs(xMin), Math.abs(xMax)),
    y: Math.max(Math.abs(yMin), Math.abs(yMax)),
  };
}

export interface SingleLineChartProps {
  data: MultilineType | LineDataType[];
  xzData?: MultilineType;
  type: RoutineNamesType;
  guides?: MultilineType;
  height?: number;
  title?: {
    routineId: string;
    lastModified: string;
    testSettings?: TestSettingsType;
  };
  valveVariant?: ValveType;
}

export function SingleLineChart({
  data,
  xzData,
  type,
  guides,
  height,
  title,
  valveVariant,
}: SingleLineChartProps) {
  const [displayPercentage, setDisplayPercentage] = useState<number>(5);
  const [xyData, setXYData] = useState<MultilineType | LineDataType[]>();
  const [xzDataLocal, setXZLocalData] = useState<MultilineType | undefined>();
  const [savedSecondaryData, setSavedSecondaryData] = useState<
    MultilineType | undefined
  >();
  const [hideSecondaryData, toggleHideSecondaryData] = useState<boolean>(true);
  const [chartWidth, setChartWidth] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const isOverview = searchParams.get("view") === "overview";

  const toggleSecondaryData = () => {
    const toggleData = !hideSecondaryData;
    toggleHideSecondaryData(toggleData);
    if (hideSecondaryData) {
      setSavedSecondaryData(xzDataLocal);
      setXZLocalData(undefined);
    } else {
      setXZLocalData(savedSecondaryData);
      setSavedSecondaryData(undefined);
    }
  };

  useEffect(() => {
    if (
      ref.current &&
      ref.current.clientWidth !== chartWidth &&
      ref.current.clientWidth !== 0
    ) {
      setChartWidth(ref.current.clientWidth);
    }
  }, [ref, chartWidth]);

  useEffect(() => {
    if (data) {
      setXYData(data);
    }
    if (xzData) {
      setXZLocalData(xzData);
    }
  }, [data, xzData]);

  let chartRange =
    ["flow_angle", "slow_sine"].includes(type) && isMultiline(xyData)
      ? calculateChartRange(xyData)
      : { x: 0, y: 0 };

  const fullScreenSettings = {
    margins: {
      left: 160,
    },
  };

  return (
    <>
      {title && (
        <TitleChartArea
          routineId={title.routineId}
          lastModified={title.lastModified}
          testSettings={title.testSettings}
          type={type}
          displayPercentage={displayPercentage}
          toggleSecondaryDataState={hideSecondaryData}
          toggleSecondaryData={toggleSecondaryData}
        />
      )}
      <div
        ref={ref}
        className={`${!height ? `h-[55vh]` : ""} w-full `}
        style={{
          height: height ? `${height}rem` : undefined,
          maxHeight: chartWidth * 0.7,
        }}
      >
        {xyData ? (
          Array.isArray(xyData) && xyData.length === 0 ? (
            <div>No data to display</div>
          ) : type === "motor_calibration" ? (
            <MemoLineChart
              data={xyData}
              routineType={type}
              settings={{
                labels: { x: xLabelMap[type], y: yLabelMap[type] },
                guides: guides,
                zeroGuides: { x: false, y: false },
                focusPoints: { numberOfPoints: 2 },
                gridline: true,
                tooltip: {
                  displayLineName: false,
                },
                fullscreen: fullScreenSettings,
                limits: calculateMotorCalibrationLimits(valveVariant),
                margins: {
                  left: 40,
                },
              }}
            />
          ) : type === "flow_angle" ? (
            <MemoLineChart
              data={xyData}
              xzData={xzDataLocal}
              settings={{
                labels: { x: xLabelMap[type], y: yLabelMap[type] },
                guides: guides,
                focusPoints: { numberOfPoints: 2 },
                gridline: true,
                fullscreen: fullScreenSettings,
                zeroGuides: isOverview
                  ? undefined
                  : { center: true, y: true, x: true },
                limits: {
                  xMin: -chartRange.x ?? 70,
                  xMax: chartRange.x ?? 70,
                  yMin: -chartRange.y ?? 70,
                  yMax: chartRange.y ?? 70,
                  zMin: 0,
                  zMax: 120,
                },
                line: {
                  xzDash: true,
                },
              }}
            />
          ) : type === "flow_demand" && isMultiline(xyData) ? (
            <FlowDemandChart
              data={xyData}
              type={type}
              guides={guides}
              fullScreenSettings={fullScreenSettings}
              percentage={displayPercentage}
              setPercentage={setDisplayPercentage}
            />
          ) : type === "frequency_response" ? (
            <MemoLineChart
              data={xyData}
              xzData={xzDataLocal}
              routineType={type}
              settings={{
                zoom: {
                  maxScale: 50,
                },
                limits: {
                  xMin: 40,
                  xMax: 100,
                  yMin: -12,
                  yMax: 3,
                  zMin: -150,
                  zMax: 0,
                },
                ticks: {
                  x: [1, 5, 10, 25, 50, 75, 100, 200, 300, 400, 500, 750, 1000],
                  y: [-15, -12, -9, -6, -3, 0, 3, 6, 9, 12, 15],
                  z: [60, 30, 0, -30, -60, -90, -120, -150],
                },
                labels: { x: xLabelMap[type] },
                exponential: { x: true },
                guides: guides,
                focusPoints: { type: "delaunay" },
                zeroGuides: { x: false, y: false },
                gridline: true,
                line: {
                  xzDash: true,
                },
                fullscreen: fullScreenSettings,
                reverseAxis: {
                  z: true,
                },
              }}
            />
          ) : type === "step_response" && isMultiline(xyData) ? (
            <StepResponseChart
              data={xyData}
              guides={guides}
              fullScreenSettings={fullScreenSettings}
            />
          ) : type === "leakage" ? (
            <MemoLineChart
              data={xyData}
              xzData={xzDataLocal}
              settings={{
                labels: { x: xLabelMap[type], y: yLabelMap[type] },
                guides: guides,
                limits: {
                  yMin: 0,
                  zMin: 0,
                  zMax: 120,
                },
                focusPoints: { type: "delaunay" },
                gridline: true,
                tooltip: {
                  displayLineName: false,
                },
                fullscreen: fullScreenSettings,
                line: {
                  xzDash: true,
                  zColourArray: ["#00AEC760", "#ca1c4660"],
                },
                margins: {
                  right: 30,
                  left: 30,
                },
              }}
            />
          ) : type === "pressure_gain" ? (
            <>
              <MemoLineChart
                data={xyData}
                xzData={xzDataLocal}
                settings={{
                  labels: { x: xLabelMap[type], y: yLabelMap[type] },
                  guides: guides,
                  limits: { yMin: -100, yMax: 100, zMin: 0, zMax: 120 },
                  focusPoints: { type: "delaunay", numberOfPoints: 1 },
                  gridline: true,
                  fullscreen: fullScreenSettings,
                  zeroGuides: { center: isOverview ? false : true },
                  line: {
                    xzDash: true,
                    zColourArray: ["#00AEC760", "#ca1c4660"],
                  },
                }}
              />
            </>
          ) : type === "slow_sine" ? (
            <MemoLineChart
              data={xyData}
              settings={{
                labels: { x: xLabelMap[type], y: yLabelMap[type] },
                guides: guides,
                focusPoints: { type: "delaunay" },
                gridline: true,
                fullscreen: fullScreenSettings,
                zeroGuides: { center: isOverview ? false : true },
                limits: {
                  xMin: -chartRange.x ?? -100,
                  xMax: chartRange.x ?? 100,
                  yMin: -chartRange.y ?? 70,
                  yMax: chartRange.y ?? 70,
                },
              }}
            />
          ) : // edge matching charts
          type === "back_edge" || type === "front_edge" ? (
            <MemoLineChart
              data={xyData}
              settings={{
                labels: { x: xLabelMap[type], y: yLabelMap[type] },
                guides: guides,
                focusPoints: { numberOfPoints: 2 },
                gridline: true,
                tooltip: {
                  displayLineName: false,
                },
                fullscreen: fullScreenSettings,
              }}
            />
          ) : // TODO: Add new routine line chart conditions and settings
          type === "bias_position" && isMultiline(xyData) ? (
            <BiasPositionChart
              data={xyData}
              xzData={xzDataLocal ?? undefined}
              guides={guides}
            />
          ) : (
            <MemoLineChart
              data={xyData}
              settings={{
                labels: { x: xLabelMap[type], y: yLabelMap[type] },
                guides: guides,
                focusPoints: { numberOfPoints: 2 },
                gridline: true,
                fullscreen: fullScreenSettings,
              }}
            />
          )
        ) : (
          <ErrorMessage>No data to display</ErrorMessage>
        )}
      </div>
    </>
  );
}
