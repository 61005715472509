import {
  LineChart,
  Loading,
  SingleSelect,
  Label,
  isMultiline,
} from "@domin-frontend/domin-ui";
import { useRoutineData } from "queries/ValveIntelligenceQueries";
import { SelectOptionType } from "@domin-frontend/domin-ui/dist/esm/components/forms/MultiSelect";
import { PASRoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types";
import { useState, useEffect } from "react";
import InputHeader from "../valveComparison/Components/InputClearHeader";
import routineDataPointMapping from "../helpers/routineDataPoints";
import { calculateMotorCalibrationLimits } from "helpers/functions/calculateMCYLimits";
import { calculateChartRange } from "components/PAS/PASValveView/PASCharts/SinglePASChart";

interface CChartProps {
  routinesMultiSelect: SelectOptionType[];
  filterQueryRoutineType: PASRoutineNamesType;
}

const defaultAxisValues: {
  [key in PASRoutineNamesType]: {
    x_axis: string;
    y_axis: string;
    yy_axis?: string;
  };
} = {
  motor_calibration: {
    x_axis: "theta_offset",
    y_axis: "motor_current",
  },
  flow_angle: {
    x_axis: "angle",
    y_axis: "flow_average",
  },
  flow_demand: {
    x_axis: "demand",
    y_axis: "flow_average",
  },
  step_response: {
    x_axis: "",
    y_axis: "",
  },
  frequency_response: {
    x_axis: "",
    y_axis: "",
  },
  leakage: {
    x_axis: "demand",
    y_axis: "leakage",
  },
  pressure_gain: {
    x_axis: "demand",
    y_axis: "pressure_gain",
  },
  slow_sine: {
    x_axis: "demand_down",
    y_axis: "flow_down",
  },
  bias_position: {
    x_axis: "",
    y_axis: "",
  },
  fail_safe_position_leakage: {
    x_axis: "",
    y_axis: "",
  },
};

const getDefaultAxisOptions = (routineType: PASRoutineNamesType) => {
  const options = routineDataPointMapping[routineType];
  const defaults = defaultAxisValues[routineType];

  return {
    options: {
      x_axis: options,
      y_axis: options,
      yy_axis: options,
    },
    defaults: defaults,
  };
};

type AxisKey = "x_axis" | "y_axis" | "yy_axis";

export default function CChart({
  routinesMultiSelect,
  filterQueryRoutineType,
}: CChartProps) {
  const { options, defaults } = getDefaultAxisOptions(filterQueryRoutineType);

  const [axisOptions, setAxisOptions] = useState<{
    x_axis: string[];
    y_axis: string[];
    yy_axis: string[];
  }>(options);
  const [selectedAxisValues, setSelectedAxisValues] = useState<{
    x_axis: string;
    y_axis: string;
    yy_axis?: string;
  }>(defaults);

  const { data, isLoading } = useRoutineData(
    routinesMultiSelect
      .filter((routine) => routine.selected)
      .map((routine) => routine.value),
    filterQueryRoutineType,
    selectedAxisValues.x_axis,
    selectedAxisValues.y_axis,
    selectedAxisValues.yy_axis ?? ""
  );

  useEffect(() => {
    const { options, defaults } = getDefaultAxisOptions(filterQueryRoutineType);
    setAxisOptions(options);
    setSelectedAxisValues(defaults);
  }, [filterQueryRoutineType]);

  const handleAxisChange = (axis: AxisKey, value: string) => {
    setSelectedAxisValues((prev) => ({ ...prev, [axis]: value }));
  };

  function calculateOpacity(
    data: any,
    minOpacity = 8,
    maxOpacity = 80,
    maxLines = 100
  ) {
    const numLines = Object.keys(data).length;
    if (numLines <= 1) return maxOpacity;
    if (numLines >= maxLines) return minOpacity;

    // linearly intepolating to calcuate opacity
    const opacityRange = maxOpacity - minOpacity;
    const linesRange = maxLines - 1; // since  range starts from 1
    const opacity = maxOpacity - ((numLines - 1) * opacityRange) / linesRange;
    console.log(opacity);
    return opacity;
  }

  const opacity = data ? calculateOpacity(data.data) : 1;

  let chartRange =
    ["flow_angle", "slow_sine"].includes(filterQueryRoutineType) &&
    isMultiline(data?.data) &&
    data?.data
      ? calculateChartRange(data?.data)
      : { x: 0, y: 0 };

  const customLimits = () => {
    let limits: any = {};

    switch (filterQueryRoutineType) {
      case "motor_calibration":
        limits = calculateMotorCalibrationLimits();
        break;
      case "leakage":
        limits = {
          yMin: 0,
          zMin: 0,
          zMax: 120,
        };
        break;
      case "pressure_gain":
        limits = {
          yMin: -100,
          yMax: 100,
          zMin: 0,
          zMax: 120,
        };
        break;
      case "slow_sine":
      case "flow_angle":
        limits = {
          xMin: -chartRange.x ?? -100,
          xMax: chartRange.x ?? 100,
          yMin: -chartRange.y ?? 70,
          yMax: chartRange.y ?? 70,
        };
        break;
      default:
        limits = {};
        break;
    }

    return limits;
  };

  return (
    <div className="w-full">
      <div className="grid grid-cols-3 gap-4">
        {(["x_axis", "y_axis", "yy_axis"] as AxisKey[]).map((axis) => (
          <Label key={axis} htmlFor={`${axis}_select`}>
            <div className="flex items-center">
              {axis === "y_axis" && selectedAxisValues.yy_axis && (
                <span
                  style={{
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#ea6686",
                    marginRight: "5px",
                  }}
                />
              )}
              {axis === "yy_axis" && selectedAxisValues.yy_axis && (
                <span
                  style={{
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#00aec7",
                    marginRight: "5px",
                  }}
                />
              )}
              <InputHeader
                field=""
                onClick={(e) => handleAxisChange(axis, "")}
                value={selectedAxisValues[axis]}
                displayClear={axis === "yy_axis"}
              >
                {axis.replace("_", " ")}
              </InputHeader>
            </div>
            <SingleSelect
              id={`${axis}_select`}
              value={selectedAxisValues[axis] ?? ""}
              onChange={(e) => handleAxisChange(axis, e.value)}
              options={axisOptions[axis].map((v) => ({
                label: v,
                value: v,
              }))}
            />
          </Label>
        ))}
      </div>

      <div className="col-span-full flex justify-center mt-4">
        {isLoading ? (
          <div className="h-[50vh] w-full">
            <Loading />
          </div>
        ) : (
          data && (
            <div className="h-[75vh] w-full">
              <LineChart
                data={data.data}
                xzData={data.xz_data}
                settings={{
                  line: {
                    colourArray: ["#444444"],
                    opacity: opacity,
                    hoverLine: "#ea6686",
                    zColourArray: ["#00aec7"],
                  },
                  limits: customLimits(),
                }}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
}
