import { LoginTemplate } from "@domin-frontend/domin-ui";
import { loginRequest } from "auth/authConfig";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { withTransaction } from "@elastic/apm-rum-react";

function VTPLoginPage() {
  const navigate = useNavigate();

  const { accounts, instance, inProgress } = useMsal();
  useEffect(() => {
    if (accounts.length > 0) {
      navigate("/pas/dashboard/");
    }
  }, [accounts, navigate]);

  useEffect(() => {
    if (inProgress === "none" && !accounts.length) {
      instance.loginRedirect(loginRequest);
    }
  }, [inProgress, instance, accounts.length]);

  return (
    <>
      <UnauthenticatedTemplate>
        <LoginTemplate
          loginRequest={loginRequest}
          logMessage
          to="/pas/dashboard/"
          title="Valve Testing Portal"
        />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Navigate to="/pas/dashboard/" />
      </AuthenticatedTemplate>
    </>
  );
}

export default withTransaction("VTPLoginPage", "page")(VTPLoginPage);
