import { mapAcronymRoutines, mapRoutineNames } from "@domin-frontend/domin-ui";
import { RoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types";

export function formatRoutineTitle(title: string, acronym?: boolean) {
  if (title === "sku") {
    return "SKU";
  }
  if (title === "serial_number") {
    return "Valve SN";
  }
  if (title === "latest_rig") {
    return "Latest Rig";
  }
  if (title === "status") { //TODO: Rename all status refs to stage
    return "Stage"
  }
  if (title === "result_status") {
    return "Status"
  }
  
  // if title is in RoutineNamesType, return the acronym
  if (title in mapAcronymRoutines) {
    if (acronym) {
      return mapAcronymRoutines[title as RoutineNamesType];
    } else {
      return mapRoutineNames[title as RoutineNamesType];
    }
  }
  return title.charAt(0).toUpperCase() + title.slice(1);
}
