import {
  CardLayout,
  ErrorMessage,
  Loading,
  Pagination,
} from "@domin-frontend/domin-ui";
import EMTable from "components/EdgeMatching/EMTable";
import stringToNumber from "helpers/functions/shared/stringToNumber";
import useSetDocumentName from "helpers/hooks/shared/useSetDocumentName";
import { useEdgeMatchingList } from "queries/EMQueries";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { withTransaction } from "@elastic/apm-rum-react";

function EdgeMatchingDashboard() {
  const { page } = useParams();
  const [lastPage, setLastPage] = useState<number>();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>();
  const { data, isLoading, isError } = useEdgeMatchingList({
    page: stringToNumber(page ?? 1),
    search: searchQuery,
  });

  // update document name
  useSetDocumentName(`Edge Matching Dashboard`);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchQuery(searchValue);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [searchValue, setSearchQuery]);

  useEffect(() => {
    if (data?.total_pages) {
      setLastPage(data.total_pages);
    }
  }, [data?.total_pages]);

  function setPage(e: number) {
    navigate(
      `/edge-matching/dashboard/${e}${
        searchQuery ? `?search=${searchQuery}` : ""
      }`,
    );
  }

  return (
    <>
      <CardLayout
        title="Edge Matching Routines"
        className="relative"
        search={{
          name: "Search valve",
          onChange: (e) => setSearchValue(e.target.value),
          value: searchValue ?? "",
        }}
      >
        {isLoading || !data ? (
          <Loading />
        ) : isError ? (
          <ErrorMessage>Error</ErrorMessage>
        ) : (
          <EMTable data={data.data} isLoading={isLoading} />
        )}
        <div className="w-full py-4 overflow-hidden">
          <Pagination
            page={stringToNumber(page ?? 1)}
            totalPages={lastPage}
            setPage={setPage}
            pagesRange={1}
          />
        </div>
      </CardLayout>
    </>
  );
}

export default withTransaction(
  "EdgeMatchingDashboard",
  "page",
)(EdgeMatchingDashboard);
