import { LineChart } from "@domin-frontend/domin-ui";
import { MultilineType } from "@domin-frontend/domin-ui/dist/esm/types";
import { memo } from "react";
import { xLabelMap, yLabelMap } from "./labelFormatMap";
import dominColours from "helpers/DominColours";

const MemoLineChart = memo(LineChart);

interface Props {
  data: MultilineType;
  xzData?: MultilineType;
  guides?: MultilineType;
}

export default function BiasPositionChart({ data, xzData, guides }: Props) {
  // Legend Settings
  const legend = {
    show: false,
    lineNames: ["Drive On", "Drive Off"],
  };

  // Line Settings
  const line = {
    colourArray: [dominColours.primary["domin-blue"], dominColours.danger[600]],
    zColourArray: [dominColours.neutral[300]],
    lineNames: {
      xyLines: ["Drive On", "Drive Off"],
    },
    opacity: 100,
    hoverLine: true,
  };

  // Visual Settings
  const margins = {
    right: 30,
    left: 30,
  };
  const labels = {
    x: xLabelMap["bias_position"],
    y: yLabelMap["bias_position"],
  };
  // Data
  return (
    <MemoLineChart
      data={data}
      xzData={xzData}
      settings={{
        labels: labels,
        guides: guides,
        gridline: true,
        legend: legend,
        line: line,
        tooltip: {
          displayLineName: true,
        },
        margins: margins,
      }}
    />
  );
}
