import { Dispatch } from "react";
import {
  CompareDropdownItemsType,
  handleSelectRoutineProps,
} from "types/valveIntelligenceTypes";

interface Props extends handleSelectRoutineProps {
  setSelectedRoutines: Dispatch<
    React.SetStateAction<CompareDropdownItemsType[][]>
  >;
}

/**
 * This function updates the routines in ValveCompare state based on the column and row passed in
 * @param routine - the routine to update
 * @param column - the column to update
 * @param row - the row to update
 * @param duplicate - boolean to duplicate the routine
 * @param remove - boolean to remove the routine
 * @param setSelectedRoutines - the function to set the selected routines
 */
export function updateSelectRoutine({
  routine,
  column,
  row,
  duplicate,
  remove,
  setSelectedRoutines,
}: Props) {
  setSelectedRoutines((prev) => {
    const newSelectedRoutines = [...prev];
    if (remove) {
      // Remove full column if the routine is the only one in the column
      if (newSelectedRoutines[column].length === 1) {
        // if there is only one column left, replace it with an empty array
        if (newSelectedRoutines.length === 1) {
          return [[]];
        }
        return newSelectedRoutines.filter((_, i) => i !== column);
      }
      // Remove routine from column
      if (row !== undefined) {
        const newColumn = newSelectedRoutines[column].filter(
          (r, i) => i !== row,
        );
        if (newColumn.length) {
          newSelectedRoutines[column] = newColumn;
        } else {
          return newSelectedRoutines.filter((_, i) => i !== column);
        }
      }
    } else if (duplicate) {
      // Add routine as a new column, if the column already exists move it to the end
      if (newSelectedRoutines.length > column) {
        newSelectedRoutines[column].push(routine);
      } else {
        newSelectedRoutines[column] = [routine];
      }
    } else if (
      newSelectedRoutines.length > column ||
      !newSelectedRoutines[column]
    ) {
      if (newSelectedRoutines[column]) {
        // Add routine to an existing column
        if (row !== undefined) {
          newSelectedRoutines[column][row] = routine;
        } else {
          // Add routine as a new row at the bottom
          newSelectedRoutines[column].push(routine);
        }
      } else {
        // Add routine as a new column with a new row
        newSelectedRoutines[column] = [routine];
      }
    } else if (newSelectedRoutines[column]) {
      newSelectedRoutines[column] = newSelectedRoutines[column].filter(
        (r, i) =>
          r.routine_id !== routine.routine_id && row !== undefined
            ? i !== row
            : false,
      );
    }

    return newSelectedRoutines;
  });
}
