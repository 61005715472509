import { Button, EMRoutineNames, Loading } from "@domin-frontend/domin-ui";
import { useEdgeMatchingInfo } from "queries/EMQueries";
import { useNavigate, useParams } from "react-router-dom";
import { EMRoutineItemsProps } from "types/EMTypes";
import { FIRSTLINEARDATADATE } from "./EMPlot";
import { Fragment, useEffect, useState } from "react";
import RoutineItem from "components/PAS/PASValveView/RoutineItem";
import { formatRoutineTitle } from "helpers/functions/shared/formatRoutineName";
import { EMRoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types";
import EMPlotAndInfo from "./EMPlotsAndInfo";

function formatInitialRoutineProps(
  routines: EMRoutineNamesType[]
): EMRoutineItemsProps {
  const tempRoutines: EMRoutineItemsProps = {} as EMRoutineItemsProps;
  routines.forEach((routine) => {
    tempRoutines[routine] = {
      name: routine,
      numberOfRoutines: 0,
      selected: false,
      setSelected: () => {},
    };
  });

  return tempRoutines;
}

export function checkRoutineName(name?: string): EMRoutineNamesType | false {
  if (
    name === "front_edge" ||
    name === "back_edge" ||
    name === "flow_angle" ||
    name === "leakage"
  ) {
    return name as EMRoutineNamesType;
  } else {
    return false;
  }
}

export default function EMRoutinesArea() {
  const [selectedRoutine, setSelectedRoutine] = useState<
    EMRoutineNamesType | false
  >(false);
  const [routines, setRoutines] = useState<EMRoutineItemsProps>(
    formatInitialRoutineProps(EMRoutineNames)
  );
  const [maxDisplayedRoutines, setMaxDisplayedRout] = useState<{
      [key in EMRoutineNamesType]: number;
  }>({
    front_edge: 3,
    back_edge: 3,
    flow_angle: 3,
    leakage: 3,
  });
  const { manifold_spool, routine } = useParams();
  const urlManifold = manifold_spool?.split("-")[0];
  const urlSpool = manifold_spool?.split("-")[1];

  const navigate = useNavigate();
  const {
    data: manifoldData,
    isLoading,
    isError,
  } = useEdgeMatchingInfo(urlManifold, urlSpool);

  // Set selected routine from url param
  useEffect(() => {
    if (routine) {
      const validRoutine = checkRoutineName(routine);
      if (validRoutine) {
        setSelectedRoutine(validRoutine);
      }
    } else {
      const isValidRoutine = Object.keys(routines).find((key) => {
        const validKey: EMRoutineNamesType | false = checkRoutineName(key);
        if (!validKey) {
          return false;
        }
        return true;
      });
      if (isValidRoutine) {
        setSelectedRoutine(isValidRoutine as EMRoutineNamesType);
        navigate(`/edge-matching/${urlManifold}-${urlSpool}/${isValidRoutine}`);
      }
    }
  }, [routine, routines, navigate, urlManifold, urlSpool]);

  // Set selected routine and navigate to routine view
  function handleSelectRoutine(routine: EMRoutineNamesType) {
    setSelectedRoutine(routine);
    navigate(
      `/edge-matching/${urlManifold}-${urlSpool}/${routine.toLowerCase()}`
    );
  }
  const validRoutine = checkRoutineName(routine);
  const routinesLength = validRoutine
    ? manifoldData?.[validRoutine]?.length ?? 0
    : 0;

  // Update routines button card info when manifoldData is loaded
  useEffect(() => {
    if (manifoldData) {
      const tempRoutines: EMRoutineItemsProps = {} as EMRoutineItemsProps;
      EMRoutineNames.filter((r) =>
        manifoldData?.variant !== "S4 Pro" ? r !== "back_edge" : r
      ).forEach((routine) => {
        tempRoutines[routine] = {
          name: routine,
          numberOfRoutines: manifoldData[routine]?.length ?? 0,
          selected: false,
          setSelected: () => {},
          routineInfo: {
            routine_type: routine,
            last_modified: manifoldData[routine]?.[0]?.last_modified ?? "",
            routine_id: manifoldData[routine]?.[0]?.id ?? "",
            test_id: "",
            status: manifoldData[routine] ? "completed" : "no completed",
          },
        };
      });
      setRoutines(tempRoutines);
    }
  }, [manifoldData, setRoutines]);

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <p>Error</p>
  ) : !validRoutine ? (
    <p>No Valid Routine</p>
  ) : (
    <div className="flex flex-col h-auto bg-neutral-0 col-start-2 gap-2 sticky top-0">
      <>
        <div className="flex justify-center gap-8 p-4">
          {EMRoutineNames.filter((r) =>
            manifoldData?.variant?.toLowerCase() !== "s4 pro"
              ? r !== "back_edge"
              : r
          ).map((validRoutine, i) =>
            isError ? (
              <div
                key={`${routines}-${i}`}
                className={`relative transition-colors rounded-sm flex flex-col justify-center items-center
                md:w-20 xl:w-24 2xl:w-28 h-28 bg-red-300 text-red-800 `}
              >
                Error loading <br />
                {formatRoutineTitle(validRoutine)}
              </div>
            ) : (
              <RoutineItem<EMRoutineNamesType>
                key={`${routines}-${i}`}
                setSelected={() =>
                  handleSelectRoutine(routines[validRoutine].name)
                }
                name={routines[validRoutine].name}
                selected={selectedRoutine === routines[validRoutine].name}
                numberOfRoutines={routines[validRoutine].numberOfRoutines ?? 0}
                displayedRoutines={maxDisplayedRoutines[validRoutine]}
                routineInfo={routines[validRoutine].routineInfo}
                isLoading={isLoading}
              />
            )
          )}
        </div>
        {manifoldData?.[validRoutine]
          ?.slice(0, maxDisplayedRoutines[validRoutine])
          .map((item, i) => {
            const isLinear =
              manifoldData.variant !== "S4 Pro" &&
              new Date(item.last_modified) > FIRSTLINEARDATADATE;
            return (
              <Fragment key={`${item.id}-${i}`}>
                {isLinear && (
                  <EMPlotAndInfo
                    item={item}
                    manifoldData={manifoldData}
                    routine={validRoutine}
                    linear={true}
                  />
                )}
                <EMPlotAndInfo
                  item={item}
                  manifoldData={manifoldData}
                  routine={validRoutine}
                  linear={false}
                />
              </Fragment>
            );
          })}
        <div className="flex justify-center">
          <>
            {routinesLength > maxDisplayedRoutines[validRoutine] && (
              <>
                <Button
                  onClick={() =>
                    setMaxDisplayedRout((prev) => {
                      return {
                        ...prev,
                        [validRoutine]: prev[validRoutine] + 3,
                      };
                    })
                  } // if adding 3 is more than the remaining routines, add the remaining routines
                  variant="ghost"
                >
                  Show more
                </Button>
                <Button
                  onClick={() =>
                    setMaxDisplayedRout((prev) => {
                      return { ...prev, [validRoutine]: routinesLength };
                    })
                  }
                  variant="ghost"
                >
                  Show All
                </Button>
              </>
            )}
            {maxDisplayedRoutines[validRoutine] > 3 && (
              <div className="flex justify-center">
                <Button
                  onClick={() =>
                    setMaxDisplayedRout((prev) => {
                      return { ...prev, [validRoutine]: 3 };
                    })
                  }
                  variant="ghost"
                >
                  Show less
                </Button>
              </div>
            )}
          </>
        </div>
      </>
    </div>
  );
}
