export function formatDate(dateString?: string) {
  if (!dateString) {
    return "";
  }
  const date = new Date(dateString);

  if (isToday(date)) {
    return `${date.toLocaleTimeString(undefined, {
      minute: "numeric",
      hour: "numeric",
    })}, Today`;
  } else if (isYesterday(date)) {
    return `${date.toLocaleTimeString(undefined, {
      minute: "numeric",
      hour: "numeric",
    })}, Yesterday`;
  } else {
    return date.toLocaleDateString(undefined, {
      hour: "numeric",
      minute: "2-digit",
      month: "numeric",
      day: "numeric",
      year: "2-digit",
    });
  }
}

function isToday(date: Date) {
  const now = new Date();

  return (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
}

function isYesterday(date: Date) {
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);
  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  );
}

export function formatUnixTime(unixTime?: number) {
  if (!unixTime) {
    return "";
  }
  const date = new Date(unixTime * 1000);
  return date.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
}

export function getToday() {
  return new Date().toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
}
