import { CardItem, CardLayout } from "@domin-frontend/domin-ui";
import CCQueryForm from "components/valveIntelligence/CustomChart/CCQueryForm";
import ShareModal from "components/valveIntelligence/valveComparison/Components/ShareModal";
import { updateFilterQueries } from "helpers/functions/compareValveArea/updateFilterQueries";
import useSetDocumentName from "helpers/hooks/shared/useSetDocumentName";
import useShareOnTeams from "helpers/hooks/shared/useShareOnTeams";
import { useRef, useState } from "react";
import {
  FilterFieldsPASObjectType,
  HandleFilterPropsType,
} from "types/valveIntelligenceTypes";
import { initialValues } from "./ValveCompare";
import { SelectOptionType } from "@domin-frontend/domin-ui/dist/esm/components/forms/MultiSelect";
import CChart from "components/valveIntelligence/CustomChart/CCchart";
import { PASRoutineNamesType } from "@domin-frontend/domin-ui/dist/esm/types/dominProductionTypes";

export default function CustomCharts() {
  useSetDocumentName(`Valve Intelligence`);
  const [showPopupModal, setShowPopupModal] = useShareOnTeams();
  const ref = useRef<HTMLDivElement>(null);
  const [filterQueries, setFilterQueries] = useState<FilterFieldsPASObjectType>(
    { c1: {} }
  );

  const [routinesMultiSelect, setRoutinesMultiSelect] = useState<
    SelectOptionType[]
  >([]);

  // This function wraps the updateFilterQueries function and passes the setFilterQueries function
  function handleFilterQueries({
    column,
    filter,
    remove,
  }: HandleFilterPropsType) {
    updateFilterQueries({
      column,
      totalColumns: 1,
      filter,
      remove,
      setFilterQueries,
    });
  }

  const column = 1;
  const filterQuery =
    filterQueries[`c${column}` as keyof typeof filterQueries] || initialValues;

  return (
    <CardLayout
      title={"Custom Charts"}
      tag={{
        status: "success",
        dominColours: true,
        children: "Beta",
      }}
      buttons={[
        {
          children: "Share",
          onClick: () => {
            setShowPopupModal(true);
          },
          variant: "secondary",
        },
      ]}
    >
      <CardItem className="!pt-0">
        {/* Options Item */}
        <div className="flex sticky top-0 bg-neutral-0 z-10">
          <div
            ref={ref}
            className="grid grid-cols-[26rem,_1fr] w-full h-full pr-3 pt-3 gap-8"
          >
            <CCQueryForm
              column={column}
              filterQuery={filterQuery}
              handleFilterQueries={handleFilterQueries}
              routinesMultiSelect={routinesMultiSelect}
              setRoutinesMultiSelect={setRoutinesMultiSelect}
            />

            {filterQuery.routine_type && (
              <CChart
                routinesMultiSelect={routinesMultiSelect}
                filterQueryRoutineType={
                  filterQuery.routine_type as PASRoutineNamesType
                }
              />
            )}
          </div>
        </div>
      </CardItem>
      {showPopupModal && (
        <ShareModal
          setShowPopupModal={setShowPopupModal}
          message="Copy the link below and to share this routine data comparison with others"
        />
      )}
    </CardLayout>
  );
}
