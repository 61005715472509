import { Configuration } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_ID || "",
    authority: process.env.REACT_APP_AUTH_API || "",
    redirectUri:
      process.env.NODE_ENV === "development" ||
      window.location.protocol === "http:"
        ? `http://${window.location.host}/pas/dashboard/`
        : `https://${window.location.host}/pas/dashboard/` || "",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [process.env.REACT_APP_API_SCOPE ?? ""],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
